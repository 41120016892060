import React from 'react'
import { Table, TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { Nullable } from '../../../types/lang.types'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { genClientsInfoTableColumns } from './contracts-clients-table.utils'
import { ContractsClientTableRow } from './contracts-clients-table.types'

interface ContractsClientsTableProps {
  clients: Nullable<ContractsClientTableRow[]>
  isLoading: boolean
  pagination?: TablePaginationConfig
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
  onInfo: (id: string) => void
  onRemove: (id: string) => void
}

export const ContractsClientsTable = ({
  clients,
  onInfo,
  pagination,
  onChangePageSize,
  onChangePage,
  isLoading,
  onRemove,
}: ContractsClientsTableProps) => {
  const columns: ColumnsType<ContractsClientTableRow> = React.useMemo(
    () => genClientsInfoTableColumns({ onInfo, onRemove }),
    [onInfo, onRemove]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Table loading={isLoading} dataSource={clients || []} rowKey="id" columns={columns} pagination={paginationConfig} />
  )
}
