import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { mapContractsToSelect } from '../../../../mapping/transactions.mapping'

export const getClientsActiveContracts = (state: AppState) =>
  state.transactionsCreatePage.transactionsCreatePageContracts.activeContracts

export const getClientsActiveContractsSelect = createSelector(getClientsActiveContracts, contracts =>
  mapContractsToSelect(contracts)
)

export const getClientsActiveContractsSelectWithNoContract = createSelector(getClientsActiveContracts, contracts =>
  mapContractsToSelect(contracts, true)
)
