import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ContractsApi } from '../../../../api/types/contracts-api.types'
import { Nullable } from '../../../../types/lang.types'

export interface TransactionsCreatePageContractsState {
  activeContracts: ContractsApi.Contracts[]
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: TransactionsCreatePageContractsState = {
  activeContracts: [],
  isLoading: false,
  error: null,
}

export const { actions: transactionsCreatePageContractsActions, reducer: transactionsCreatePageContractsReducer } =
  createSlice({
    name: '@@transactions-create-page-contracts',
    initialState,
    reducers: {
      fetchClientsContracts: (state: Draft<TransactionsCreatePageContractsState>, _: PayloadAction<{ id: string }>) => {
        state.isLoading = true
      },
      fetchClientsContractsSuccess: (
        state: Draft<TransactionsCreatePageContractsState>,
        action: PayloadAction<ContractsApi.Contracts[]>
      ) => {
        state.activeContracts = action.payload
        state.isLoading = false
      },
      fetchClientsContractsError: (
        state: Draft<TransactionsCreatePageContractsState>,
        action: PayloadAction<Error>
      ) => {
        state.error = action.payload
        state.isLoading = false
      },

      reset: () => initialState,
    },
  })
