import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ScheduleModalConflictsExerciseValues } from '../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts-exercise/schedule-modal-conflicts-exercise.types'
import { ScheduleModalConflictsAction } from '../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.types'
import { formatPluralized } from '../../../format/text.format'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { getWebsocketTimetableConflicts } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.selectors'
import { genScheduleManagementPageTableStudiosRoomsOptions } from '../../../store/pages/schedule-management-page/schedule-management-page-table/schedule-management-page-table.selectors'
import {
  getScheduleManagementPageModalConflictsIsLoading,
  getScheduleManagementPageModalConflictsTrainersOptions,
} from '../../../store/pages/schedule-management-page/schedule-management-page-modal-conflicts/schedule-management-page-modal-conflicts.selectors'
import { scheduleManagementPageModalConflictsActions } from '../../../store/pages/schedule-management-page/schedule-management-page-modal-conflicts/schedule-management-page-modal-conflicts.slice'
import { AppModal } from '../../../types/modal.types'
import { ScheduleManagementPageModalConflictsCreatedExercises } from './schedule-management-page-modal-conflicts.types'
import { WebsocketTimetableMessageExerciseAction } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.types'
import { websocketTimetableActions } from '../../../store/common/websocket/websocket-timetable/websocket-timetable.slice'

export interface UseScheduleManagementPageModalConflictsProps {
  scheduleId: string
  action: ScheduleModalConflictsAction
  studioId: string
  createdExercises?: ScheduleManagementPageModalConflictsCreatedExercises
  studioOffset?: number
}

export function useScheduleManagementPageModalConflicts(props: UseScheduleManagementPageModalConflictsProps) {
  const { scheduleId, action, studioId, createdExercises, studioOffset } = props

  const dispatch = useDispatch()

  const isLoading = useSelector(getScheduleManagementPageModalConflictsIsLoading)
  const conflicts = useSelector(getWebsocketTimetableConflicts)
  const studiosRoomsOptions = useSelector(genScheduleManagementPageTableStudiosRoomsOptions)
  const trainersOptions = useSelector(getScheduleManagementPageModalConflictsTrainersOptions)

  const onCreateHandler = useCallback(
    (exerciseId: string, timeFrom: string, timeTo: string, values?: ScheduleModalConflictsExerciseValues) => {
      if (createdExercises) {
        dispatch(
          scheduleManagementPageModalConflictsActions.createExercise({
            scheduleId,
            exerciseId,
            values,
            createdExercises,
            timeFrom,
            timeTo,
            studioOffset,
          })
        )
      }
    },
    [createdExercises, dispatch, scheduleId, studioOffset]
  )

  const onEditHandler = useCallback(
    (exerciseId: string, values?: ScheduleModalConflictsExerciseValues) => {
      dispatch(
        scheduleManagementPageModalConflictsActions.editExercise({
          studioId,
          exerciseId,
          values,
        })
      )
    },
    [dispatch, studioId]
  )

  const onSaveHandler = useCallback(
    (exerciseId: string, timeFrom: string, timeTo: string, formValues?: ScheduleModalConflictsExerciseValues) => {
      if (action === 'EDIT') {
        onEditHandler(exerciseId, formValues)
      } else {
        onCreateHandler(exerciseId, timeFrom, timeTo, formValues)
      }
    },
    [action, onCreateHandler, onEditHandler]
  )

  const onSkipHandler = useCallback(
    (exerciseId: string, action: WebsocketTimetableMessageExerciseAction): void => {
      dispatch(
        websocketTimetableActions.updateExerciseStatus({
          exerciseId,
          action,
        })
      )
    },
    [dispatch]
  )

  const onCancelHandler = useCallback((): void => {
    const onSubmit = () => {
      dispatch(modalActions.close(AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFLICTS))
    }

    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM,
        props: {
          title: 'Вы уверены что хотите завершить?',
          text: `${formatPluralized(conflicts?.length, ['запись', 'записи', 'записей'])} не ${
            conflicts?.length === 1
              ? `будет ${action === 'EDIT' ? 'изменена' : 'создана'}`
              : `будут ${action === 'EDIT' ? 'изменены' : 'созданы'}`
          } из-за конфликтов.`,
          onSubmit,
        },
      })
    )
  }, [action, conflicts?.length, dispatch])

  useEffect(() => {
    if (conflicts?.length === 0) {
      dispatch(modalActions.closeLast())
    }
  }, [conflicts, dispatch])

  useEffect(() => {
    dispatch(scheduleManagementPageModalConflictsActions.fetchModalData())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(scheduleManagementPageModalConflictsActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    studioId,
    conflicts,
    studiosRoomsOptions,
    trainersOptions,
    onSaveHandler,
    onCancelHandler,
    onSkipHandler,
  }
}
