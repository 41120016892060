import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { StudiosApi } from '../../../../api/types/studios-api.types'
import { TrainersApi } from '../../../../api/types/trainers-api.types'
import { ScheduleExercisesFilter } from '../../../../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { BookingStatus } from '../../../../types/bookings.types'
import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleLongtermPagePeekAboutFetchExercisesPayload,
  ScheduleLongtermPagePeekAboutFetchPeekDataSuccessPayload,
  ScheduleLongtermPagePeekAboutRemoveExercisePayload,
  ScheduleLongtermPagePeekAboutUpdateCommentPayload,
} from './schedule-longterm-page-peek-about.types'

export interface ScheduleLongtermPagePeekAbout {
  timetable: Nullable<ExercisesTimetableApi.ExercisesTimetable>
  exercises: Nullable<Pagination<ExercisesTimetableApi.ExercisesTimetableExercise>>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  exercisesFilter: ScheduleExercisesFilter
  isTimetableLoading: boolean
  isExercisesLoading: boolean
  isCommentLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleLongtermPagePeekAbout = {
  timetable: null,
  exercises: null,
  studios: null,
  trainers: null,
  exercisesFilter: {
    bookingStatuses: [BookingStatus.VISIT_CONFIRMED, BookingStatus.NOT_VISITED_CONFIRMED, BookingStatus.BOOKED],
  },
  isTimetableLoading: false,
  isExercisesLoading: false,
  isCommentLoading: false,
  error: null,
}

export const { actions: scheduleLongtermPagePeekAboutActions, reducer: scheduleLongtermPagePeekAboutReducer } =
  createSlice({
    name: '@@schedule-longterm-page-peek-about',
    initialState,
    reducers: {
      fetchPeekData: (state: Draft<ScheduleLongtermPagePeekAbout>, _: PayloadAction<string>): void => {
        state.isTimetableLoading = true
      },
      fetchPeekDataSuccess: (
        state: Draft<ScheduleLongtermPagePeekAbout>,
        action: PayloadAction<ScheduleLongtermPagePeekAboutFetchPeekDataSuccessPayload>
      ): void => {
        state.timetable = action.payload.timetable
        state.studios = action.payload.studios
        state.trainers = action.payload.trainers
        state.isTimetableLoading = false
      },
      fetchPeekDataError: (state: Draft<ScheduleLongtermPagePeekAbout>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isTimetableLoading = false
      },
      fetchTimetable: (state: Draft<ScheduleLongtermPagePeekAbout>, _: PayloadAction<string>): void => {
        state.isTimetableLoading = true
      },
      fetchTimetableSuccess: (
        state: Draft<ScheduleLongtermPagePeekAbout>,
        action: PayloadAction<Nullable<ExercisesTimetableApi.ExercisesTimetable>>
      ): void => {
        state.timetable = action.payload
        state.isTimetableLoading = false
      },
      fetchTimetableError: (state: Draft<ScheduleLongtermPagePeekAbout>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isTimetableLoading = false
      },
      fetchExercises: (
        state: Draft<ScheduleLongtermPagePeekAbout>,
        _: PayloadAction<ScheduleLongtermPagePeekAboutFetchExercisesPayload>
      ): void => {
        state.isExercisesLoading = true
      },
      fetchExercisesSuccess: (
        state: Draft<ScheduleLongtermPagePeekAbout>,
        action: PayloadAction<Nullable<Pagination<ExercisesTimetableApi.ExercisesTimetableExercise>>>
      ): void => {
        state.exercises = action.payload
        state.isExercisesLoading = false
      },
      fetchExercisesError: (state: Draft<ScheduleLongtermPagePeekAbout>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isExercisesLoading = false
      },
      setExercisesFilter: (
        state: Draft<ScheduleLongtermPagePeekAbout>,
        action: PayloadAction<{ name: string; value?: string | string[] }>
      ): void => {
        state.exercisesFilter = {
          ...state.exercisesFilter,
          [action.payload.name]: action.payload.value,
        }
      },
      resetExercisesFilter: (state: Draft<ScheduleLongtermPagePeekAbout>): void => {
        state.exercisesFilter = {
          timeFrom: undefined,
          timeTo: undefined,
          dayOfWeek: undefined,
          roomId: undefined,
          trainerIds: undefined,
          timeScope: undefined,
          bookingPaymentStatus: undefined,
        }
      },
      setDefaultFilter: (state: Draft<ScheduleLongtermPagePeekAbout>): void => {
        state.exercisesFilter = {
          bookingStatuses: [BookingStatus.VISIT_CONFIRMED, BookingStatus.NOT_VISITED_CONFIRMED, BookingStatus.BOOKED],
        }
      },
      updateComment: (
        state: Draft<ScheduleLongtermPagePeekAbout>,
        _: PayloadAction<ScheduleLongtermPagePeekAboutUpdateCommentPayload>
      ): void => {
        state.isCommentLoading = true
      },
      updateCommentSuccess: (state: Draft<ScheduleLongtermPagePeekAbout>): void => {
        state.isCommentLoading = false
      },
      updateCommentError: (state: Draft<ScheduleLongtermPagePeekAbout>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isCommentLoading = false
      },
      removeExercise: (
        state: Draft<ScheduleLongtermPagePeekAbout>,
        _: PayloadAction<ScheduleLongtermPagePeekAboutRemoveExercisePayload>
      ) => {
        state.isExercisesLoading = true
      },
      removeExerciseSuccess: (state: Draft<ScheduleLongtermPagePeekAbout>) => {
        state.isExercisesLoading = false
      },
      removeExerciseError: (state: Draft<ScheduleLongtermPagePeekAbout>, action: PayloadAction<Error>) => {
        state.isExercisesLoading = false
        state.error = action.payload
      },
      reset: () => initialState,
    },
  })
