import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { genContractsPagePath } from '../../../../../format/path.format'
import { modalActions } from '../../../../../store/common/modal/modal.slice'
import { contractsInfoPageDescriptionActions } from '../../../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.slice'
import { isDef } from '../../../../../types/lang.types'
import { getCounterpartyFormValue } from '../../../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.selectors'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { AppModal } from '../../../../../types/modal.types'

export const useContractsInfoMainTabs = (id: string) => {
  const [form] = Form.useForm<ContractsApi.CounterpartiesDTO>()
  const counterpartyFormValues = useSelector(getCounterpartyFormValue)
  const { push } = useHistory()
  const dispatch = useDispatch()

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onNavigateBack = React.useCallback(() => {
    push(genContractsPagePath())
  }, [push])

  const onDeleteContract = React.useCallback(() => {
    dispatch(contractsInfoPageDescriptionActions.deleteCounterpartiesById({ id, successCallback: onNavigateBack }))
  }, [dispatch, id, onNavigateBack])

  const onDeleteContractHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.CONTRACTS_INFO_PAGE_DELETE_MODAL,
        props: {
          handleOk: onDeleteContract,
          handleCancel: onCloseHandler,
        },
      })
    )
  }, [dispatch, onCloseHandler, onDeleteContract])

  React.useEffect((): void => {
    if (isDef(counterpartyFormValues)) {
      form.setFieldsValue(counterpartyFormValues)
    }
  }, [form, counterpartyFormValues])

  return {
    form,
    counterpartyFormValues,
    onDeleteContractHandler,
  }
}
