import { ContractsApi } from '../../../api/types/contracts-api.types'
import { formatRubleCurrency } from '../../../format/number.format'

export const useCalculateContractBalance = (
  contractAmount: number,
  contractType: ContractsApi.ContractsType,
  contractTurnover: number
) => {
  const adjustedBalance = contractType === 'POSTPAY' ? -contractTurnover : contractAmount - contractTurnover
  const formattedBalance = formatRubleCurrency(adjustedBalance)

  return {
    balance: formattedBalance,
  }
}
