import React from 'react'
import { Button, Flex } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'

import { ContractsPageInfoParams } from '../../../contracts-info-page.types'
import { useContractsInfoContract } from './contracts-info-contract-header.hook'

export const ContractsInfoContractHeader = () => {
  const { id } = useParams<ContractsPageInfoParams>()
  const { onCreateContractHandler } = useContractsInfoContract(id)

  return (
    <div>
      <Flex justify={'flex-end'}>
        <Button icon={<PlusOutlined color={'white'} />} type={'primary'} onClick={onCreateContractHandler}>
          Добавить договор
        </Button>
      </Flex>
    </div>
  )
}
