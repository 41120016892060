import { Rule } from 'antd/lib/form'

import { Days } from '../../../../types/days.types'

export function genScheduleSlotsItemRoomValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите пространство' }]
}

export function genScheduleSlotsItemMaxClientCountValidationRules(day: Days, index: number): Rule[] {
  return [
    ({ getFieldValue }) => ({
      validator(rule, value: number) {
        const time = getFieldValue(['slots', day, index, 'time'])
        const phones = getFieldValue('phones') || []

        if (!time || (Array.isArray(time) && time.every(t => t === null))) {
          return Promise.resolve()
        }
        if (value < 0) {
          return Promise.reject(new Error('Число мест не может быть меньше 0'))
        }
        if (value >= phones.length) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(`Число мест не может быть меньше количества клиентов (${phones.length})`))
      },
    }),
  ]
}
