import * as React from 'react'
import { Switch } from 'react-router-dom'
import { EmployeesScheduleCreatePage } from '@pages/employees/employees-schedule-create/employees-schedule-create.component'
import { MasterServicesWidgetCreatePage } from '@pages/master-services-widget-create-page/master-services-widget-create-page.component'
import { GoodsPage } from '@pages/goods-page/goods-page.component'
import { ConsignmentCreatePage } from '@pages/consignment-page/consignment-create.component'
import { ConsignmentEditPage } from '@pages/consignment-page/consignment-edit.component'
import { EmployeesScheduleEditPage } from '@pages/employees/employees-schedule-edit/employees-schedule-edit.component'
import { ContractsPage } from '@pages/contracts-page/contracts-page.components'
import { ContractsPageInfo } from '@pages/contracts-info-page/contracts-info-page.component'
import { useSelector } from 'react-redux'

import { PrivateRoute } from '../components/private-route/private-route.component'
import { AppPath } from '../types/path.types'
import { HomePage } from '../pages/home-page/home-page.component'
import { NotFoundPage } from '../pages/not-found-page/not-found-page.component'
import { FranchisesPage } from '../pages/franchises-page/franchises-page.component'
import { FranchisesCreatePage } from '../pages/franchises-create-page/franchises-create-page.component'
import { FranchisesEditPage } from '../pages/franchises-edit-page/franchises-edit-page.component'
import { DirectionsPage } from '../pages/directions-page/directions-page.component'
import { DirectionsCreatePage } from '../pages/directions-create-page/directions-create-page.component'
import { DirectionsEditPage } from '../pages/directions-edit-page/directions-edit-page.component'
import { EmployeesPage } from '../pages/employees-page/employees-page.component'
import { EmployeesCreatePage } from '../pages/employees-create-page/employees-create-page.component'
import { EmployeesEditPage } from '../pages/employees-edit-page/employees-edit-page.component'
import { ProductsPage } from '../pages/products-page/products-page.component'
import { ProductsSubscriptionCreatePage } from '../pages/products-subscriptions-сreate-page/products-subscriptions-сreate-page.component'
import { ProductsSubscriptionsEditPage } from '../pages/products-subscriptions-edit-page/products-subscriptions-edit-page.component'
import { ProductsServiceCreatePage } from '../pages/products-services-create-page/products-services-create-page.component'
import { ServiceEditPage } from '../pages/products-services-edit-page/products-services-edit-page.component'
import { SchedulePage } from '../pages/schedule-page/schedule-page.component'
import { ScheduleManagementPage } from '../pages/schedule-management-page/schedule-management-page.component'
import { ScheduleGroupPage } from '../pages/schedule-group-page/schedule-group-page.component'
import { DiscountsPage } from '../pages/discounts-page/discounts-page.component'
import { EmployeesSchedulePage } from '../pages/employees/employees-schedule/employees-schedule.component'
import { TransactionsPage } from '../pages/transactions-page/transactions-page.component'
import { TransactionsCreatePage } from '../pages/transactions-create-page/transactions-create-page.component'
import { SettingsPage } from '../pages/settings-page/settings-page.component'
import { StudiosPageComponent } from '../pages/studios-page/studios-page.component'
import { StudiosCreatePageComponent } from '../pages/studios-create-page/studios-create-page.component'
import { StudiosEditPageComponent } from '../pages/studios-edit-page/studios-edit-page.component'
import { ClientsPage } from '../pages/clients-page/clients-page.component'
import { ClientsCreatePage } from '../pages/clients-create-page/clients-create-page.component'
import { ClientsEditPage } from '../pages/clients-edit-page/clients-edit-page.component'
import { ExercisesTypesPage } from '../pages/exercises-types-page/exercises-types-page.component'
import { ExercisesTypesEditPage } from '../pages/exercises-types-edit-page/exercises-types-edit-page.component'
import { ExercisesTypesCreatePage } from '../pages/exercises-types-create-page/exercises-types-create-page.component'
import { SalariesPage } from '../pages/salaries-page/salaries-page.component'
import { MasterServicesPage } from '../pages/master-services-page/master-services-page.component'
import { MasterServicesCreatePage } from '../pages/master-services-create-page/master-services-create-page.component'
import { MasterServicesEditPage } from '../pages/master-services-edit-page/master-services-edit-page.component'
import { PositionsPage } from '../pages/positions-page/positions-page.component'
import { PositionsEditPage } from '../pages/positions-edit-page/positions-edit-page.component'
import { PositionsCreatePage } from '../pages/positions-create-page/positions-create-page.component'
import { CustomFieldsPage } from '../pages/custom-fields-page/custom-fields-page.component'
import { AnalyticsPage } from '../pages/analytics-page/analytics-page.components'
import { ReportsPage } from '../pages/reports-page/reports-page.component'
import { ScheduleLongtermPage } from '../pages/schedule-longterm-page/schedule-longterm-page.component'
import {
  getClientBookingReadPermission,
  getClientsViewPermission,
  getManagementPermission,
  getTimetableReadPermission,
  getTransactionAccessPermission,
} from '../store/common/permissions/permissions.selectors'

const AppRoutingInternal: React.FC = () => {
  const managementPermission = useSelector(getManagementPermission)
  const clientDataPermission = useSelector(getClientsViewPermission)
  const transactionPermission = useSelector(getTransactionAccessPermission)
  const clientBookingReadPermission = useSelector(getClientBookingReadPermission)
  const timetableReadPermission = useSelector(getTimetableReadPermission)

  return (
    <Switch>
      <PrivateRoute path={AppPath.HOME} component={HomePage} exact={true} />

      <PrivateRoute
        path={AppPath.FRANCHISES}
        component={FranchisesPage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.FRANCHISES_CREATE}
        component={FranchisesCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.FRANCHISES_EDIT}
        component={FranchisesEditPage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute
        path={AppPath.DIRECTIONS}
        component={DirectionsPage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.DIRECTIONS_CREATE}
        component={DirectionsCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.DIRECTIONS_EDIT}
        component={DirectionsEditPage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute
        path={AppPath.EXERCISES_TYPES}
        component={ExercisesTypesPage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.EXERCISES_TYPES_CREATE}
        component={ExercisesTypesCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.EXERCISES_TYPES_EDIT}
        component={ExercisesTypesEditPage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.EMPLOYEES_SCHEDULE}
        component={EmployeesSchedulePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.EMPLOYEES_SCHEDULE_CREATE}
        component={EmployeesScheduleCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.EMPLOYEES_SCHEDULE_EDIT}
        component={EmployeesScheduleEditPage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute path={AppPath.EMPLOYEES} component={EmployeesPage} exact={true} />
      <PrivateRoute path={AppPath.EMPLOYEES_CREATE} component={EmployeesCreatePage} exact={true} />
      <PrivateRoute path={AppPath.EMPLOYEES_EDIT} component={EmployeesEditPage} exact={true} />

      <PrivateRoute
        path={AppPath.PRODUCTS}
        component={ProductsPage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.PRODUCTS_CREATE_SUBSCRIPTION}
        component={ProductsSubscriptionCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.PRODUCTS_EDIT_SUBSCRIPTION}
        component={ProductsSubscriptionsEditPage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.PRODUCTS_CREATE_SERVICE}
        component={ProductsServiceCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.PRODUCTS_EDIT_SERVICE}
        component={ServiceEditPage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute path={AppPath.SCHEDULE} component={SchedulePage} exact={true} />

      <PrivateRoute
        path={AppPath.SCHEDULE_MANAGEMENT}
        component={ScheduleManagementPage}
        exact={true}
        hasPermission={timetableReadPermission}
      />
      <PrivateRoute
        path={AppPath.SCHEDULE_MANAGEMENT_EDIT}
        component={ScheduleManagementPage}
        exact={true}
        hasPermission={timetableReadPermission}
      />

      <PrivateRoute
        path={AppPath.SCHEDULE_LONGTERM}
        component={ScheduleLongtermPage}
        exact={true}
        hasPermission={timetableReadPermission}
      />
      <PrivateRoute
        path={AppPath.SCHEDULE_LONGTERM_EDIT}
        component={ScheduleLongtermPage}
        exact={true}
        hasPermission={timetableReadPermission}
      />

      <PrivateRoute
        path={AppPath.SCHEDULE_GROUP}
        component={ScheduleGroupPage}
        exact={true}
        hasPermission={clientBookingReadPermission}
      />

      <PrivateRoute path={AppPath.DISCOUNTS} component={DiscountsPage} exact={true} />

      <PrivateRoute
        path={AppPath.TRANSACTIONS}
        component={TransactionsPage}
        exact={true}
        hasPermission={transactionPermission}
      />
      <PrivateRoute
        path={AppPath.TRANSACTIONS_CREATE}
        component={TransactionsCreatePage}
        exact={true}
        hasPermission={transactionPermission}
      />

      <PrivateRoute path={AppPath.SETTINGS} component={SettingsPage} exact={true} />

      <PrivateRoute
        path={AppPath.STUDIOS}
        component={StudiosPageComponent}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.STUDIOS_CREATE}
        component={StudiosCreatePageComponent}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.STUDIOS_EDIT}
        component={StudiosEditPageComponent}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute
        path={AppPath.SALARIES}
        component={SalariesPage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute path={AppPath.CLIENTS} component={ClientsPage} exact={true} />
      <PrivateRoute path={AppPath.CLIENTS_CREATE} component={ClientsCreatePage} exact={true} />
      <PrivateRoute
        path={AppPath.CLIENTS_EDIT}
        component={ClientsEditPage}
        exact={true}
        hasPermission={clientDataPermission}
      />

      <PrivateRoute
        path={AppPath.MASTER_SERVICES}
        component={MasterServicesPage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.MASTER_SERVICES_CREATE}
        component={MasterServicesCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.MASTER_SERVICES_EDIT}
        component={MasterServicesEditPage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute
        path={AppPath.MASTER_SERVICES_WIDGET_CREATE}
        component={MasterServicesWidgetCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute
        path={AppPath.POSITIONS}
        component={PositionsPage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.POSITIONS_CREATE}
        component={PositionsCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.POSITIONS_EDIT}
        component={PositionsEditPage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute path={AppPath.GOODS} component={GoodsPage} exact={true} hasPermission={managementPermission} />
      <PrivateRoute
        path={AppPath.CONSIGNMENT_CREATE}
        component={ConsignmentCreatePage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.CONSIGNMENT_EDIT}
        component={ConsignmentEditPage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute
        path={AppPath.CUSTOM_FIELDS}
        component={CustomFieldsPage}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute path={AppPath.ANALYTICS} component={AnalyticsPage} exact={true} />

      <PrivateRoute path={AppPath.REPORTS} component={ReportsPage} exact={true} hasPermission={managementPermission} />

      <PrivateRoute
        path={AppPath.CONTRACTS}
        component={ContractsPage}
        exact={true}
        hasPermission={managementPermission}
      />
      <PrivateRoute
        path={AppPath.CONTRACTS_INFO}
        component={ContractsPageInfo}
        exact={true}
        hasPermission={managementPermission}
      />

      <PrivateRoute path={AppPath.NOT_FOUND} component={NotFoundPage} />
    </Switch>
  )
}

export const AppRouting = React.memo(AppRoutingInternal)
