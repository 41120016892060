import { put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { callApi } from '../../../../utils/sagas.utils'
import { transactionsCreatePageContractsActions } from './transactions-create-page-contracts.slice'

export function* fetchClientsContracts(
  action: ReturnType<typeof transactionsCreatePageContractsActions.fetchClientsContracts>
) {
  try {
    const { id } = action.payload

    const response: Awaited<ReturnType<typeof api.contractsService.fetchAllClientsContracts>> = yield callApi(
      api.contractsService.fetchAllClientsContracts,
      id
    )

    yield put(transactionsCreatePageContractsActions.fetchClientsContractsSuccess(response?.data))
  } catch (e) {
    console.error(e)
    yield put(transactionsCreatePageContractsActions.fetchClientsContractsError(new Error()))
  }
}

export function* transactionsCreatePageContractsSagas() {
  yield takeLatest(transactionsCreatePageContractsActions.fetchClientsContracts, fetchClientsContracts)
}
