import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ContractsApi } from '../../../../api/types/contracts-api.types'
import { Nullable } from '../../../../types/lang.types'
import { CounterpartiesFetchByIdPayload, CounterpartyUpdateByIdPayload } from './contracts-info-page-description.types'

export interface ContractsInfoPageDescriptionState {
  counterparty: Nullable<ContractsApi.Counterparties>
  isLoading: boolean
  isLoaded: boolean
  isUpdating: boolean
  isUpdated: boolean
  isDeleting: boolean
  isDelete: boolean
  error: Nullable<Error>
}

const initialState: ContractsInfoPageDescriptionState = {
  counterparty: null,
  isLoading: false,
  isLoaded: false,
  isUpdating: false,
  isUpdated: false,
  isDeleting: false,
  isDelete: false,
  error: null,
}

export const { actions: contractsInfoPageDescriptionActions, reducer: contractsInfoPageDescriptionReducer } =
  createSlice({
    name: '@@contracts-info-page-description',
    initialState,
    reducers: {
      fetchCounterpartiesById: (
        state: Draft<ContractsInfoPageDescriptionState>,
        _: PayloadAction<CounterpartiesFetchByIdPayload>
      ) => {
        state.isLoading = true
      },
      fetchCounterpartiesByIdSuccess: (
        state: Draft<ContractsInfoPageDescriptionState>,
        action: PayloadAction<Nullable<ContractsApi.Counterparties>>
      ) => {
        state.counterparty = action.payload
        state.isLoading = false
        state.isLoaded = true
      },
      fetchCounterpartiesByIdError: (state: Draft<ContractsInfoPageDescriptionState>, action: PayloadAction<Error>) => {
        state.isLoading = false
        state.isLoaded = true
        state.error = action.payload
      },
      updateCounterpartiesById: (
        state: Draft<ContractsInfoPageDescriptionState>,
        action: PayloadAction<CounterpartyUpdateByIdPayload>
      ) => {
        state.isUpdating = true
      },
      updateCounterpartiesByIdSuccess: (
        state: Draft<ContractsInfoPageDescriptionState>,
        action: PayloadAction<any>
      ) => {
        state.counterparty = action.payload
        state.isUpdating = false
      },
      updateCounterpartiesByIdError: (
        state: Draft<ContractsInfoPageDescriptionState>,
        action: PayloadAction<Error>
      ) => {
        state.error = action.payload
      },
      deleteCounterpartiesById: (
        state: Draft<ContractsInfoPageDescriptionState>,
        _: PayloadAction<{ id: string; successCallback?: () => void }>
      ) => {
        state.isDeleting = true
      },
      deleteCounterpartiesByIdSuccess: (state: Draft<ContractsInfoPageDescriptionState>) => {
        state.isDelete = true
        state.isDeleting = false
      },
      deleteCounterpartiesByIdError: (
        state: Draft<ContractsInfoPageDescriptionState>,
        action: PayloadAction<Error>
      ) => {
        state.isDeleting = false
        state.error = action.payload
      },
      reset: () => initialState,
    },
  })
