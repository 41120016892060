import { formatPluralized } from '@format/text.format'
import { ColumnsType } from 'antd/lib/table'

import { ProductsServicesTableColumnActions } from './products-services-table-column-actions/products-services-table-column-actions.component'
import { ProductsServicesTableActions, ProductsServicesTableRow } from './products-services-table.types'

export function genProductsServicesTableColumns(
  params: ProductsServicesTableActions
): ColumnsType<ProductsServicesTableRow> {
  const { onEdit, onRemove, onHistory } = params

  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: name => name || '',
    },
    {
      title: 'Локация',
      dataIndex: 'availableStudios',
      key: 'availableStudios',
      render: studios => (studios === 0 ? 'Все локации' : formatPluralized(studios, ['локация', 'локации', 'локаций'])),
    },
    {
      title: 'Цена',
      dataIndex: 'cost',
      key: 'cost',
      render: text => `${text} ₽` || '-',
    },
    {
      title: 'Цена пробного',
      dataIndex: 'trialCost',
      key: 'trialCost',
      render: text => `${text} ₽` || '-',
    },
    {
      title: 'Действия',
      key: 'action',
      align: 'center',
      render: (_, { id, name }) => (
        <ProductsServicesTableColumnActions
          id={id}
          name={name}
          onEdit={onEdit}
          onRemove={onRemove}
          onHistory={onHistory}
        />
      ),
    },
  ]
}
