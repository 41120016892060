import { useAnalytics } from './analytics-page.hook'
import { ProgressAnalytic } from './progress-analytic/progress-analytic.component'

export const StudioIncomeAnalytics = () => {
  const { studioIncome, isLoading } = useAnalytics()
  if (!studioIncome && !isLoading) {
    return null
  }
  return (
    <ProgressAnalytic
      title="Доход на одну локацию"
      value={studioIncome?.average || 0}
      isLoading={isLoading}
      max={studioIncome?.max || 0}
      last={studioIncome?.last || 0}
      yesterday={studioIncome?.yesterday || 0}
    />
  )
}
