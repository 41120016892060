import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback } from 'react'

import { contractsInfoPageDescriptionActions } from '../../../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.slice'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { getCounterpartyByIdContractsInternal } from '../../../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.selectors'
import { getManagersCardsList } from '../../../../../store/pages/contracts-page/contracts-page.selectors'
import { contractsPageActions } from '../../../../../store/pages/contracts-page/contracts-page.slice'

export const useContractsInfoContractList = (id: string) => {
  const dispatch = useDispatch()
  const contractsList = useSelector(getCounterpartyByIdContractsInternal)
  const activeContractsList = contractsList?.filter(contract => contract.status !== 'EXPIRED')
  const expiredContractsList = contractsList?.filter(contract => contract.status === 'EXPIRED')
  const managers = useSelector(getManagersCardsList)

  const onChangeManagerHandler = useCallback(
    (managerId: ContractsApi.ManagerId, contractId: string) => {
      const successCallback = () => dispatch(contractsInfoPageDescriptionActions.fetchCounterpartiesById({ id }))
      if (contractsList) {
        dispatch(
          contractsPageActions.updateManager({
            contractId,
            managerId,
            successCallback,
          })
        )
      }
    },
    [contractsList, dispatch]
  )

  React.useEffect(() => {
    dispatch(contractsPageActions.fetchAllManagers())
  }, [dispatch])

  return {
    activeContractsList,
    expiredContractsList,
    managers,
    onChangeManagerHandler,
  }
}
