import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useScheduleLongtermPageParams } from './schedule-longterm-page-hooks/schedule-longterm-page-params.hook'
import { scheduleLongtermPageListActions } from '../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.slice'
import {
  genScheduleLongtermPageListIsLoaded,
  genScheduleLongtermPageListIsLoading,
} from '../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.selectors'
import { peekActions } from '../../store/common/peek/peek.slice'
import { AppPeek } from '../../types/peek.types'
import { getStudiosWithViewPermissions } from '../../store/common/permissions/permissions.selectors'
import { useStudio } from '../../hooks/use-studios.hook'

export function useScheduleLongtermPage() {
  const params = useScheduleLongtermPageParams()
  const { studioId: selectedStudioId } = useStudio()
  const { scheduleId, studioId } = params

  const dispatch = useDispatch()

  const isLoading = useSelector(genScheduleLongtermPageListIsLoading)
  const isLoaded = useSelector(genScheduleLongtermPageListIsLoaded)

  const allowedStudios = useSelector(getStudiosWithViewPermissions)

  useEffect(() => {
    if (scheduleId) {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_LONGTERM_PAGE_PEEK_ABOUT,
          props: { scheduleId, studioId },
        })
      )
    }
  }, [dispatch, scheduleId, studioId])

  useEffect(() => {
    const isStudioAllowed = allowedStudios?.includes(studioId)
    if (isStudioAllowed) {
      dispatch(scheduleLongtermPageListActions.fetchPageData(params))
    }
  }, [dispatch, params, allowedStudios])

  useEffect(() => {
    return () => {
      dispatch(scheduleLongtermPageListActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    isLoaded,
    studioId,
    selectedStudioId,
    allowedStudios,
  }
}
