import { put, takeLatest } from 'redux-saga/effects'

import { fileActions } from './files.slice'
import { callApi } from '../../../utils/sagas.utils'
import { api } from '../../../api/api'

export function* uploadFile(action: ReturnType<typeof fileActions.uploadFile>) {
  try {
    const pdf = action.payload

    const response: Awaited<ReturnType<typeof api.fileService.uploadPdf>> = yield callApi(
      api.fileService.uploadPdf,
      pdf
    )
    yield put(fileActions.uploadFileSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(fileActions.uploadFileError(new Error()))
  }
}

export function* uploadFileSagas() {
  yield takeLatest(fileActions.uploadFile, uploadFile)
}
