import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { ScheduleList } from '../../../components/schedule/schedule-list/schedule-list.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { useScheduleLongtermPageTable } from './schedule-longterm-page-table.hook'

export const ScheduleLongtermPageTable = () => {
  const {
    isLoading,
    schedule,
    timetableDeletePermission,
    pagination,
    onCreateTransactionHandler,
    onSelectPaymentTypeHandler,
    onEditHandler,
    onRemoveHandler,
    onHistoryHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  } = useScheduleLongtermPageTable()

  if (isLoading) {
    return <PageLoader />
  }
  if (isDefAndNotEmpty(schedule)) {
    return (
      <ScheduleList
        schedule={schedule}
        timetableDeletePermission={timetableDeletePermission}
        pagination={pagination}
        onCreateTransaction={onCreateTransactionHandler}
        onSelectPaymentType={onSelectPaymentTypeHandler}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        onHistory={onHistoryHandler}
        onChangePage={onChangePageHandler}
        onChangePageSize={onChangePageSizeHandler}
      />
    )
  }
  return <PageEmpty description="Нет расписания" />
}
