import { List } from 'antd'
import { PaginationConfig } from 'antd/es/pagination'
import { useMemo } from 'react'

import { ScheduleListCard } from './schedule-list-card/schedule-list-card.component'
import { ScheduleListProps } from './schedule-list.types'

export const ScheduleList: React.FC<ScheduleListProps> = props => {
  const { schedule, pagination, onChangePage, onChangePageSize, ...rest } = props

  const paginationConfig = useMemo(
    (): PaginationConfig => ({
      ...pagination,
      align: 'center',
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  return (
    <List
      itemLayout="vertical"
      dataSource={schedule}
      pagination={paginationConfig}
      renderItem={item => <ScheduleListCard scheduleItem={item} {...rest} />}
    />
  )
}
