import { put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../api/api'
import { permissionsActions } from './permissions.slice'
import { callApi } from '../../../utils/sagas.utils'

export function* fetchPermissions(action: ReturnType<typeof permissionsActions.fetchPermissions>) {
  try {
    const permissions: Awaited<ReturnType<typeof api.permissionsService.fetchPermissionsByEmployeeId>> = yield callApi(
      api.permissionsService.fetchPermissionsByEmployeeId,
      action.payload
    )

    yield put(permissionsActions.fetchPermissionsSuccess(permissions.data))
  } catch (e) {
    yield put(permissionsActions.fetchPermissionsError(new Error()))
  }
}

export function* permissionsSagas() {
  yield takeLatest(permissionsActions.fetchPermissions, fetchPermissions)
}
