import { DictionaryItem } from '@api/types/api.types'
import { Typography } from 'antd'
import { Fragment } from 'react'

import { Nullable } from '../../../../types/lang.types'

interface Props {
  studios: Nullable<DictionaryItem[]>
}

export const ProductsSubscriptionsTableColumnStudios = ({ studios }: Props) => {
  return (
    <Fragment>
      {studios?.length === 0
        ? 'Все локации'
        : studios?.map((item, index) => (
            <Fragment key={item.id}>
              <Typography.Text>{item.name}</Typography.Text>
              {index !== studios.length - 1 && ', '}
            </Fragment>
          ))}
    </Fragment>
  )
}
