import React from 'react'
import { Button, Divider, Flex, Form, Input, Modal, Select, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Dragger from 'antd/es/upload/Dragger'
import { useTheme } from '@hooks/use-theme.hook'
import { clsx } from 'clsx'

import { InputPhoneNumber } from '../../../components/controls/input-phone-number/input-phone-number.component'
import { ContractsApi } from '../../../api/types/contracts-api.types'
import { genClientsFormPhoneValidationRules } from '../../../components/clients/clients-form/clients-form.utils'
import { DEFAULT_EMPTY_SYMBOL } from '../../../format/text.format'
import style from './contracts-modal-create.module.scss'
import { ContractsModalCreateProps, snoTypeOptionsObj } from './contracts-modal-create.types'

const snoTypeOptions: snoTypeOptionsObj[] = [
  {
    label: 'Общая система налогообложения (ОСНО)',
    value: 'osn',
  },
  {
    label: 'Патентная система налогообложения (ПСН)',
    value: 'patent',
  },
  {
    label: 'Упрощенная система налогообложения "Доходы" (УСН)',
    value: 'usn_income',
  },
  {
    label: 'Упрощенная система налогообложения "Доходы минус расходы" (УСН)',
    value: 'usn_income_outcome',
  },
  {
    label: 'Единый сельскохозяйственный налог (ЕСХН)',
    value: 'esn',
  },
  {
    label: 'Единый налог на вменённый доход (ЕНВД)',
    value: 'envd',
  },
]

export const ContractsModalCreate = ({ handleOk, handleCancel, isLoading }: ContractsModalCreateProps) => {
  const [form] = Form.useForm<ContractsApi.CounterpartiesDTO>()
  const { theme } = useTheme()

  const onChangePhoneHandler = (value: string) => {
    form.setFieldValue('phoneNumber', value)
  }

  const validateNumericInput = (event: React.KeyboardEvent<HTMLInputElement>) =>
    !/[0-9]/.test(event.key) && event.preventDefault()

  return (
    <Modal
      title="Создание контрагента"
      open={true}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" htmlType="submit" disabled={isLoading}>
          Создать контрагента
        </Button>,
      ]}
      modalRender={dom => (
        <Form
          autoComplete="off"
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: 'public',
          }}
          onFinish={handleOk}
        >
          {dom}
        </Form>
      )}
    >
      <Divider />
      {/*<Typography.Title level={5}>Логотип</Typography.Title>*/}
      {/*<Dragger className-={style.dragger}>*/}
      {/*  <p>*/}
      {/*    <PlusOutlined color={'rgba(0, 0, 0, 0.45)'} />*/}
      {/*  </p>*/}
      {/*  <p>Перетащите сюда файл или нажмите для выбора</p>*/}
      {/*</Dragger>*/}
      <Typography.Title level={5}>Карточка компании</Typography.Title>
      <Flex
        vertical
        className={clsx({ [style.formContainerLight]: theme === 'light', [style.formContainerDark]: theme === 'dark' })}
        gap={'small'}
      >
        <Form.Item
          name="directorName"
          label="Генеральный директор / ответственный"
          tooltip="Генеральный директор необходим для создания контрагента."
          rules={[
            {
              required: true,
              message: 'Введите Генерального директора компании!',
            },
          ]}
        >
          <Input type="textarea" />
        </Form.Item>
        <Form.Item
          name="companyName"
          label="Наименование компании"
          tooltip="Наименование компании необходимо для создания контрагента"
          rules={[
            {
              required: true,
              message: 'Введите Наименование компании!',
            },
          ]}
        >
          <Input type="textarea" />
        </Form.Item>
        <Form.Item
          name="inn"
          label="ИНН"
          tooltip="ИНН необходим для создания контрагента"
          rules={[
            {
              required: true,
              message: 'Введите ИНН!',
            },
            {
              min: 10,
              message: 'Длина ИНН - 10 или 12 символов (для физ. лиц)!',
            },
            {
              max: 12,
              message: 'Длина ИНН - 10 или 12 символов (для физ. лиц)!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} maxLength={12} />
        </Form.Item>
        <Form.Item
          name="kpp"
          label="КПП"
          tooltip="КПП необходим для создания контрагента"
          rules={[
            {
              required: true,
              message: 'Введите КПП!',
            },
            {
              min: 9,
              message: 'Длина КПП - 9 символов!',
            },
            {
              max: 9,
              message: 'Длина КПП - 9 символов!',
            },
          ]}
        >
          <Input type="textarea" maxLength={9} onKeyPress={validateNumericInput} />
        </Form.Item>
        <Form.Item
          name="ogrn"
          label="ОГРН"
          tooltip="ОГРН необходим для создания контрагента"
          rules={[
            {
              required: true,
              message: 'Введите ОГРН!',
            },
            {
              min: 13,
              message: 'Длина ОГРН - 13 или 15 символов (ИП)!',
            },
            {
              max: 15,
              message: 'Длина ОГРН - 13 или 15 символов (ИП)!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} maxLength={15} />
        </Form.Item>
        <Form.Item
          name="bankAccount"
          label="Расчётный счёт"
          tooltip="Расчётный счёт необходим для создания контрагента"
          rules={[
            {
              required: true,
              message: 'Введите Расчётный счёт!',
            },
            {
              min: 20,
              message: 'Длина Расчётного счёта - 20 символов!',
            },
            {
              max: 20,
              message: 'Длина Расчётного счёта - 20 символов!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} maxLength={20} />
        </Form.Item>
        <Form.Item
          name="bic"
          label="БИК"
          rules={[
            {
              required: true,
              message: 'Введите БИК!',
            },
            {
              min: 9,
              message: 'Длина БИК - 9 символов!',
            },
            {
              max: 9,
              message: 'Длина БИК - 9 символов!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} maxLength={9} />
        </Form.Item>
        <Form.Item
          name="bankName"
          label="Наименование банка"
          rules={[
            {
              required: true,
              message: 'Введите Наименование банка!',
            },
          ]}
        >
          <Input type="textarea" />
        </Form.Item>
        <Form.Item
          name="snoType"
          label="Система налогообложения"
          rules={[
            {
              required: true,
              message: 'Введите Систему налогообложения!',
            },
          ]}
        >
          <Select defaultValue={DEFAULT_EMPTY_SYMBOL} options={snoTypeOptions} />
        </Form.Item>
        <Form.Item
          name="correspondentAccount"
          label="Корреспондентский счёт"
          rules={[
            {
              required: true,
              message: 'Введите Корреспондентский счёт!',
            },
            {
              min: 20,
              message: 'Длина Корреспондентского счёта - 20 символов!',
            },
            {
              max: 20,
              message: 'Длина Корреспондентского счёта - 20 символов!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} maxLength={20} />
        </Form.Item>
        <Form.Item
          name="address"
          label="Юридический адрес"
          rules={[
            {
              required: true,
              message: 'Введите Юридический адрес!',
            },
          ]}
        >
          <Input type="textarea" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Контактный Email"
          rules={[
            {
              required: true,
              message: 'Введите Контактный Email!',
            },
            {
              type: 'email',
              message: 'Невалидное значение',
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Контактный телефон" rules={genClientsFormPhoneValidationRules()}>
          <InputPhoneNumber
            value={form.getFieldValue('phoneNumber')}
            onKeyPress={validateNumericInput}
            onChange={onChangePhoneHandler}
            placeholder={''}
            onBlur={() => form.validateFields(['phoneNumber'])}
            onFocus={() =>
              form.setFields([
                {
                  name: 'phoneNumber',
                  errors: [],
                },
              ])
            }
          />
        </Form.Item>
      </Flex>
    </Modal>
  )
}
