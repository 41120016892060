import { UPDATE_CLIENT_BOOKINGS_BROADCAST } from '@constants/broadcast'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { useStudio } from '../../hooks/use-studios.hook'
import {
  genScheduleGroupPageListIsLoaded,
  genScheduleGroupPageListIsLoading,
} from '../../store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.selectors'
import { scheduleGroupPageListActions } from '../../store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.slice'
import { useScheduleGroupPageParams } from './schedule-group-page-hooks/schedule-group-page-params.hook'
import { getStudiosWithViewPermissions } from '../../store/common/permissions/permissions.selectors'

export function useScheduleGroupPage() {
  const { scheduleId, studioId, section, page, size, productCategoryId } = useScheduleGroupPageParams()
  const { studioId: selectedStudioId } = useStudio()

  const dispatch = useDispatch()

  const isLoading = useSelector(genScheduleGroupPageListIsLoading)
  const isLoaded = useSelector(genScheduleGroupPageListIsLoaded)

  const allowedStudios = useSelector(getStudiosWithViewPermissions)

  useEffect(() => {
    const isStudioAllowed = allowedStudios?.includes(studioId)
    if (isStudioAllowed) {
      const updateClientBookings = () =>
        dispatch(
          scheduleGroupPageListActions.fetchPageData({
            id: scheduleId,
            params: {
              page,
              size,
              productCategoryId,
            },
          })
        )

      updateClientBookings()

      const bc = new BroadcastChannel(UPDATE_CLIENT_BOOKINGS_BROADCAST)
      bc.onmessage = event => {
        const response = JSON.parse(event.data)
        if (response.command === 'update') {
          updateClientBookings()
        }
      }

      return () => {
        bc.close()
      }
    }
  }, [dispatch, page, productCategoryId, scheduleId, size, allowedStudios])

  useEffect(() => {
    return () => {
      dispatch(scheduleGroupPageListActions.reset())
    }
  }, [dispatch])

  return {
    scheduleId,
    studioId,
    section,
    selectedStudioId,
    allowedStudios,
    isLoading,
    isLoaded,
  }
}
