import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { validateStrEnumValue } from '../../../utils/enum.utils'
import { genClientsEditPagePath } from '../../../format/path.format'
import { isDef } from '../../../types/lang.types'
import { useClientsEditPageParams } from '../clients-edit-page-hooks/clients-edit-page-params.hook'
import { ClientsEditPageSection, ClientsEditPageUrlParams } from '../clients-edit-page.types'
import { genClientsEditPageMenuTabs } from './clients-edit-page-menu.utils'
import { getClientBookingReadPermission } from '../../../store/common/permissions/permissions.selectors'

export function useClientsEditPageMenu() {
  const { push } = useHistory()
  const history = useHistory<{ pathname: string }>()
  const state = history.location.state
  const { id, section } = useClientsEditPageParams()

  const clientBookingReadPermission = useSelector(getClientBookingReadPermission)

  const menuItems = React.useMemo(
    () => genClientsEditPageMenuTabs(clientBookingReadPermission),
    [clientBookingReadPermission]
  )

  const onChangeHandler = React.useCallback(
    (activeKey: string): void => {
      const section = validateStrEnumValue<ClientsEditPageSection>(ClientsEditPageSection, activeKey)
      const params: ClientsEditPageUrlParams =
        isDef(section) && section !== ClientsEditPageSection.OVERVIEW ? { id, section } : { id }

      state ? push(genClientsEditPagePath(params), state) : push(genClientsEditPagePath(params))
    },
    [id, push, state]
  )

  return {
    section,
    menuItems,

    onChangeHandler,
  }
}
