import React from 'react'
import style from '@pages/contracts-info-page/contracts-info-page-tabs/contracts-info-page-contracts/contracts-info-contract-card/contracts-info-contract-card.module.scss'
import { Typography } from 'antd'
import { useParams } from 'react-router-dom'

import { ContractsPageInfoParams } from '../../../contracts-info-page.types'
import { ContractsInfoContractCardArchive } from '../contracts-info-contract-card/contracts-info-contract-card-archive.component'
import { PageEmpty } from '../../../../../components/page/page-empty/page-empty.component'
import { useContractsInfoContractList } from './contracts-info-contract-list.hook'
import { ContractsInfoContractCard } from '../contracts-info-contract-card/contracts-info-contract-card.component'

export const ContractsInfoContractList = () => {
  const { id } = useParams<ContractsPageInfoParams>()
  const { activeContractsList, expiredContractsList, managers, onChangeManagerHandler } =
    useContractsInfoContractList(id)

  if (activeContractsList?.length === 0) {
    return <PageEmpty description={'Нет активных договоров'} />
  }

  return (
    <>
      <div className={style.containerCards}>
        {activeContractsList?.map(contract => (
          <ContractsInfoContractCard
            key={contract.id}
            contract={contract}
            managers={managers}
            onChangeManagers={onChangeManagerHandler}
          />
        ))}
      </div>
      {expiredContractsList && expiredContractsList?.length > 0 && (
        <>
          <Typography.Title level={2}>Архив договоров</Typography.Title>
          {expiredContractsList?.map(contract => (
            <ContractsInfoContractCardArchive key={contract.id} contract={contract} />
          ))}
        </>
      )}
    </>
  )
}
