import * as React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { NString } from '../../../../types/lang.types'
import { ContractsClientsCellActions } from './contracts-clients-cell-action.component'

const { confirm } = Modal

interface Props {
  id: string
  name: NString
  onInfo: (id: string) => void
  onRemove: (id: string) => void
}

export const ContractsClientsTableActions: React.FC<Props> = props => {
  const { id, name } = props
  const { onInfo, onRemove } = props

  const onEditHandler = React.useCallback(() => onInfo(id), [id, onInfo])

  const onRemoveHandler = React.useCallback(() => {
    confirm({
      title: `Подтвердите удаление клиента ${name}`,
      icon: <ExclamationCircleOutlined />,
      content: `Вы уверены, что хотите отвязать сотрудника ${name}`,
      onOk: () => onRemove(id),
    })
  }, [id, name, onRemove])

  return <ContractsClientsCellActions onInfo={onEditHandler} onRemove={onRemoveHandler} />
}
