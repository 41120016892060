import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  genScheduleGroupPagePath,
  genScheduleManagementEditPagePath,
  genScheduleManagementPagePath,
} from '../../../format/path.format'
import { useStudio } from '../../../hooks/use-studios.hook'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { peekActions } from '../../../store/common/peek/peek.slice'
import {
  getClientBookingDeletePermission,
  getTimetableUpdatePermission,
} from '../../../store/common/permissions/permissions.selectors'
import {
  genScheduleManagementPagePeekAboutSchedule,
  genScheduleManagementPagePeekAboutExercises,
  genScheduleManagementPagePeekAboutExercisesFilter,
  genScheduleManagementPagePeekAboutExercisesTotalElement,
  genScheduleManagementPagePeekAboutStudiosOptions,
  genScheduleManagementPagePeekAboutIsTimetableLoading,
  genScheduleManagementPagePeekAboutIsExercisesLoading,
  genScheduleManagementPagePeekAboutFilterRoomsOptions,
  genScheduleManagementPagePeekAboutFilterTrainersOptions,
  genScheduleManagementPagePeekAboutIsCommentLoading,
} from '../../../store/pages/schedule-management-page/schedule-management-page-peek-about/schedule-management-page-peek-about.selectors'
import { scheduleManagementPagePeekAboutActions } from '../../../store/pages/schedule-management-page/schedule-management-page-peek-about/schedule-management-page-peek-about.slice'
import { AppModal } from '../../../types/modal.types'
import { genListPaginationConfig } from '../../../utils/pagination.utils'
import { ScheduleGroupPageUrlParams } from '../../schedule-group-page/schedule-group-page.types'
import { useScheduleManagementPageParams } from '../schedule-management-page-hooks/schedule-management-page-params.hook'

export function useScheduleManagementPagePeekAbout(scheduleId: string, studioId: string) {
  const { studioOffset } = useStudio()
  const dispatch = useDispatch()

  const params = useScheduleManagementPageParams()

  const { push } = useHistory()

  const schedule = useSelector(genScheduleManagementPagePeekAboutSchedule)
  const exercises = useSelector(genScheduleManagementPagePeekAboutExercises)
  const exercisesFilter = useSelector(genScheduleManagementPagePeekAboutExercisesFilter)
  const totalElements = useSelector(genScheduleManagementPagePeekAboutExercisesTotalElement)
  const trainersOptions = useSelector(genScheduleManagementPagePeekAboutFilterTrainersOptions)
  const studiosOptions = useSelector(genScheduleManagementPagePeekAboutStudiosOptions)
  const roomsOptions = useSelector(genScheduleManagementPagePeekAboutFilterRoomsOptions)
  const isTimetableLoading = useSelector(genScheduleManagementPagePeekAboutIsTimetableLoading)
  const isExercisesLoading = useSelector(genScheduleManagementPagePeekAboutIsExercisesLoading)
  const isCommentLoading = useSelector(genScheduleManagementPagePeekAboutIsCommentLoading)

  const timetableUpdatePermission = useSelector(getTimetableUpdatePermission)
  const clientBookingDeletePermission = useSelector(getClientBookingDeletePermission)

  const [page, setPage] = useState(1)
  const [size, setSize] = useState(25)

  const pagination = useMemo(() => genListPaginationConfig(page, size, totalElements), [page, size, totalElements])
  const schedulePath = useMemo(() => {
    return window.location.host + genScheduleManagementEditPagePath(studioId, scheduleId)
  }, [studioId, scheduleId])

  const onEditCommentHandler = useCallback(
    (comment: string) => {
      dispatch(scheduleManagementPagePeekAboutActions.updateComment({ scheduleId, comment }))
    },
    [dispatch, scheduleId]
  )

  const onClientHandler = (clientId: string) => {
    dispatch(peekActions.closeLast())
    push({
      pathname: `/clients/${clientId}`,
    })
  }

  const onChangePageHandler = useCallback(
    (page: number, pageSize: number): void => {
      setPage(page)
      setSize(pageSize)
      dispatch(
        scheduleManagementPagePeekAboutActions.fetchExercises({
          timetableId: scheduleId,
          params: {
            page: page - 1,
            size: pageSize,
            sort: 'timeFrom,asc',
          },
        })
      )
    },
    [dispatch, scheduleId]
  )

  const onExerciseHandler = useCallback(
    (studioId: string, scheduleId: string): void => {
      const params: ScheduleGroupPageUrlParams = { studioId, scheduleId }
      dispatch(peekActions.closeLast())
      push(genScheduleGroupPagePath(params))
    },
    [dispatch, push]
  )

  const onEditExercisesHandler = useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_EDIT,
        props: { studioId, scheduleId },
      })
    )
  }, [dispatch, scheduleId, studioId])

  const onAddExercisesHandler = useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_ADD,
        props: { scheduleId, studioOffset, studioId },
      })
    )
  }, [dispatch, scheduleId, studioId, studioOffset])

  const onRemoveExerciseHandler = useCallback(
    (exerciseId: string): void => {
      const onSubmit = () => {
        dispatch(scheduleManagementPagePeekAboutActions.removeExercise({ exerciseId, scheduleId }))
      }

      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM,
          props: {
            title: 'Отмена занятия',
            text: 'Вы уверены, что хотите отменить занятие?',
            onSubmit,
          },
        })
      )
    },
    [dispatch, scheduleId]
  )

  const onCancelPeekHandler = useCallback((): void => {
    const { page, size } = params
    push(genScheduleManagementPagePath(studioId, { page, size }))
  }, [push, studioId, params])

  const onSelectFilterHandler = useCallback(
    (name: string, value?: string | string[]) => {
      dispatch(scheduleManagementPagePeekAboutActions.setExercisesFilter({ name, value }))
    },
    [dispatch]
  )

  const onResetFilterHandler = useCallback(() => {
    dispatch(scheduleManagementPagePeekAboutActions.resetExercisesFilter())
  }, [dispatch])

  useEffect(() => {
    dispatch(scheduleManagementPagePeekAboutActions.fetchPeekData(scheduleId))
  }, [dispatch, scheduleId])

  useEffect(() => {
    dispatch(
      scheduleManagementPagePeekAboutActions.fetchExercises({
        timetableId: scheduleId,
        params: {
          page: page - 1,
          size,
          sort: 'timeFrom,asc',
        },
      })
    )
  }, [dispatch, page, scheduleId, size, exercisesFilter])

  useEffect(() => {
    return () => {
      dispatch(scheduleManagementPagePeekAboutActions.reset())
    }
  }, [dispatch])

  return {
    isTimetableLoading,
    isExercisesLoading,
    isCommentLoading,
    schedule,
    exercises,
    exercisesFilter,
    totalElements,
    pagination,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    schedulePath,
    timetableUpdatePermission,
    clientBookingDeletePermission,
    onChangePageHandler,
    onEditCommentHandler,
    onClientHandler,
    onExerciseHandler,
    onEditExercisesHandler,
    onAddExercisesHandler,
    onRemoveExerciseHandler,
    onCancelPeekHandler,
    onSelectFilterHandler,
    onResetFilterHandler,
  }
}
