import { PlusOutlined } from '@ant-design/icons'
import { api } from '@api/api'
import { formatPenniesToRubles, formatRublesToPennies } from '@format/number.format'
import { AddCategory } from '@pages/goods-page/modals/add-category'
import { AddProvider } from '@pages/goods-page/modals/add-provider'
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
} from 'antd'
import { useEffect, useMemo, useState } from 'react'

export const ConsignmentForm = (props: any) => {
  const {
    form,
    providers,
    organizations,
    studios,
    onChangeOrganization,
    categories,
    goods,
    setGoods,
    allGoods,
    getAllCategories,
    getAllProviders,
    isEdit,
    onSave,
    title,
  } = props

  const [hasDates, setHasDates] = useState(false)
  const [hasImage, setHasImage] = useState(false)
  const [isEditGood, setIsEditGood] = useState(true)
  const [imageUrl, setImageUrl] = useState('')

  const [price, setPrice] = useState(null as any)

  const [newGood] = Form.useForm()
  useEffect(() => {
    newGood.setFieldsValue({})
  }, [])

  const measuresOptions = [
    {
      label: 'Шт.',
      value: 'Шт.',
    },
  ]

  const goodsOptions = useMemo(() => {
    return (allGoods.content || []).map((good: any) => ({
      label: good.name,
      value: good.id,
    }))
  }, [allGoods])

  const categoriesOptions = useMemo(() => {
    return (categories.content || []).map((category: any) => ({
      label: category.name,
      value: category.id,
    }))
  }, [categories])

  const providersOptions = useMemo(() => {
    return (providers.content || []).map((provider: any) => ({
      label: provider.name,
      value: provider.id,
    }))
  }, [providers])

  const organizationsOptions = useMemo(() => {
    return (organizations.content || []).map((organization: any) => ({
      label: organization.name,
      value: organization.id,
    }))
  }, [organizations])

  const studiosOptions = useMemo(() => {
    return (studios.content || []).map((studio: any) => ({
      label: studio.name,
      value: studio.id,
    }))
  }, [studios])

  const onSaveGood = async () => {
    let goodsId = newGood.getFieldValue('goodsId')

    let hasErrors = false
    await newGood.validateFields().catch(err => {
      hasErrors = true
    })
    if (hasErrors) return false

    const createdGood = {
      ...newGood.getFieldsValue(),
      cost: formatRublesToPennies(newGood.getFieldValue('cost')),
    }
    const res = !isEditGood
      ? await api.productsGoodsService.create(createdGood)
      : await api.productsGoodsService.update(goodsId, {
          ...(allGoods.content.find((goodItem: any) => goodItem.id === goodsId) || {}),
          ...createdGood,
        })
    newGood.setFieldValue('goodsId', res.data.id)
    goodsId = res.data.id

    setGoods([
      ...goods,
      {
        ...newGood.getFieldsValue(),
        ...(await api.productsGoodsService.fetchById(newGood.getFieldValue('goodsId'))).data,
        purchaseProductCost: newGood.getFieldValue('purchaseProductCost'),
        cost: newGood.getFieldValue('cost'),
        goodsId,
      },
    ])
    setIsEditGood(true)
    setHasDates(false)
    setHasImage(false)
    newGood.resetFields()

    return true
  }

  const onChangeGood = (e: any) => {
    const foundGood = allGoods.content.find((good: any) => e === good.id)
    newGood.setFieldsValue({
      ...foundGood,
      categoryId: foundGood.category.id,
      cost: formatPenniesToRubles(foundGood.cost),
    })
  }

  const [isCategoryModalOpened, setIsCategoryModalOpened] = useState(false)
  const createCategory = async (event: any) => {
    await api.goodsCategoriesService.create(event)
    await getAllCategories()
    setIsCategoryModalOpened(false)
  }

  const [isProviderModalOpened, setIsProviderModalOpened] = useState(false)
  const createProvider = async (event: any) => {
    await api.goodsProvidersService.create(event)
    await getAllProviders()
    setIsProviderModalOpened(false)
  }

  const updatePrice = (_: any, e: any) => {
    if (e.purchaseProductCost && e.count) {
      setPrice(e.purchaseProductCost * e.count)
    } else {
      setPrice(null)
    }
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </button>
  )

  const getBase64 = (img: any, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const handleChangeImage = (info: any) => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as any, url => {
        setImageUrl(url)
      })
    }
  }

  const onSaveHandler = async () => {
    const hasFilledFields = (obj: any) => {
      return Object.values(obj).some(value => value !== undefined && value !== null)
    }

    let hasErrorWithGoods = false
    hasFilledFields(newGood.getFieldsValue()) && (hasErrorWithGoods = !(await onSaveGood()))
    !hasErrorWithGoods && (await onSave())
  }

  return (
    <>
      <Row justify="space-between">
        <Typography.Title>{title}</Typography.Title>
        <Button type="primary" onClick={() => onSaveHandler()}>
          Сохранить
        </Button>
      </Row>

      <AddCategory
        onFinish={createCategory}
        isOpen={isCategoryModalOpened}
        onCancel={() => setIsCategoryModalOpened(false)}
      />
      <AddProvider
        onFinish={createProvider}
        isOpen={isProviderModalOpened}
        onCancel={() => setIsProviderModalOpened(false)}
      />
      <Row gutter={12}>
        <Col span={12}>
          <Card>
            <Typography.Title level={3}>Основная информация</Typography.Title>
            <Form form={form} autoComplete="off" layout="vertical">
              <Form.Item
                rules={[{ required: true, message: 'Введите номер накладной' }]}
                label="Номер накладной"
                name="consignmentNoteNumber"
                style={{ paddingTop: '8px' }}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Выберите поставщика' }]}
                label="Поставщик"
                name="providerId"
                style={{ paddingTop: '8px' }}
              >
                <Select
                  options={providersOptions}
                  placeholder=""
                  dropdownRender={menu => (
                    <>
                      <Button onClick={() => setIsProviderModalOpened(true)} block style={{ marginBottom: '4px' }}>
                        Добавить нового
                      </Button>
                      {menu}
                    </>
                  )}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Выберите закупщика' }]}
                label="Закупщик"
                name="organizationId"
                style={{ paddingTop: '8px' }}
              >
                <Select options={organizationsOptions} placeholder="" onChange={onChangeOrganization} />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Выберите локацию' }]}
                label="Локация"
                name="studioId"
                style={{ paddingTop: '8px' }}
              >
                <Select options={studiosOptions} placeholder=""></Select>
              </Form.Item>
              <Form.Item
                label="Дата накладной"
                name="consignmentNoteDate"
                style={{ paddingTop: '8px' }}
                rules={[{ required: true, message: 'Введите дату накладной' }]}
              >
                <DatePicker format={{ format: 'DD.MM.YYYY', type: 'mask' }} allowClear={false}></DatePicker>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <Col span={12}>
          <Card>
            <Typography.Title level={3}>Товары в накладной</Typography.Title>
            {goods.map((good: any, idx: number) => {
              const handleChanges = (e: any) => {
                const newGoods = goods.map((goodItem: any) => {
                  if (good.id === goodItem.id) {
                    return {
                      ...goodItem,
                      ...e,
                    }
                  }
                  return goodItem
                })
                setGoods(newGoods)
              }
              return (
                <Collapse style={{ marginBottom: '24px' }}>
                  <Collapse.Panel
                    key={`good${good.id}`}
                    header={
                      <Typography.Title level={4} style={{ marginTop: '12px' }}>
                        Товар №{idx + 1}, {good.name}, {good.count}, {good.count * good.purchaseProductCost} ₽
                      </Typography.Title>
                    }
                    showArrow={false}
                  >
                    <Form initialValues={good} autoComplete="off" layout="vertical" onValuesChange={handleChanges}>
                      <Form.Item
                        label="Категория"
                        name="categoryId"
                        rules={[{ required: true, message: 'Выберите категорию' }]}
                      >
                        <Select
                          placeholder=""
                          options={categoriesOptions}
                          dropdownRender={menu => (
                            <>
                              <Button
                                onClick={() => setIsCategoryModalOpened(true)}
                                block
                                style={{ marginBottom: '4px' }}
                              >
                                Создать новую
                              </Button>
                              {menu}
                            </>
                          )}
                        ></Select>
                      </Form.Item>

                      <Row gutter={8} style={{ marginTop: '8px' }}>
                        <Col span={14}>
                          <Form.Item label="Товар" name="name" rules={[{ required: true, message: 'Выберите товар' }]}>
                            <Input></Input>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            label="Измерение"
                            name="measureUnit"
                            rules={[{ required: true, message: 'Выберите измерение' }]}
                          >
                            <Select options={measuresOptions}></Select>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            label="Кол-во"
                            name="count"
                            rules={[{ required: true, message: 'Выберите кол-во' }]}
                          >
                            <InputNumber min={0} precision={2} controls={false}></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={8} style={{ marginTop: '8px' }}>
                        <Col span={12}>
                          <Form.Item label="Закупочная цена" name="purchaseProductCost">
                            <InputNumber min={0} precision={2} controls={false} suffix="₽"></InputNumber>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Цена продажи" name="cost">
                            <InputNumber min={0} precision={2} controls={false} suffix="₽"></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
                        <Col flex="none">
                          <Form.Item name="hasDates" valuePropName="checked">
                            <Switch onChange={e => setHasDates(e)}></Switch>
                          </Form.Item>
                        </Col>
                        <Col flex="auto">
                          <Typography.Text>Указать срок годности и дату производства</Typography.Text>
                        </Col>
                      </Row>

                      {good.hasDates && (
                        <Row gutter={8} style={{ marginTop: '8px' }}>
                          <Col span={12}>
                            <Form.Item label="Дата производства" name="productionDate">
                              <DatePicker
                                format={{ format: 'DD.MM.YYYY', type: 'mask' }}
                                style={{ width: '100%' }}
                              ></DatePicker>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Дата истечения срока годности" name="expirationDate">
                              <DatePicker
                                format={{ format: 'DD.MM.YYYY', type: 'mask' }}
                                style={{ width: '100%' }}
                              ></DatePicker>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}

                      <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
                        <Col flex="none">
                          <Form.Item name="hasImage" valuePropName="checked">
                            <Switch onChange={e => setHasImage(e)}></Switch>
                          </Form.Item>
                        </Col>
                        <Col flex="auto">
                          <Typography.Text>Загрузить картинку товара</Typography.Text>
                        </Col>
                      </Row>

                      {good.hasImage && (
                        <Row>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            onChange={handleChangeImage}
                            maxCount={1}
                          >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                          </Upload>
                        </Row>
                      )}

                      <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
                        <Col flex="none">
                          <Form.Item name="hasMarkCode" valuePropName="checked">
                            <Switch></Switch>
                          </Form.Item>
                        </Col>
                        <Col flex="auto">
                          <Typography.Text>Маркировочный товар</Typography.Text>
                        </Col>
                      </Row>
                    </Form>
                  </Collapse.Panel>
                </Collapse>
              )
            })}

            <Collapse defaultActiveKey={isEdit ? undefined : 'newGood'}>
              <Collapse.Panel
                key="newGood"
                header={
                  <Typography.Title level={4} style={{ marginTop: '12px' }}>
                    Добавление товара №{goods?.length + 1}
                  </Typography.Title>
                }
                showArrow={false}
              >
                <Form form={newGood} autoComplete="off" layout="vertical" onValuesChange={updatePrice}>
                  <Form.Item
                    label="Категория"
                    name="categoryId"
                    rules={[{ required: true, message: 'Выберите категорию' }]}
                  >
                    <Select
                      placeholder=""
                      options={categoriesOptions}
                      dropdownRender={menu => (
                        <>
                          <Button onClick={() => setIsCategoryModalOpened(true)} block style={{ marginBottom: '4px' }}>
                            Создать новую
                          </Button>
                          {menu}
                        </>
                      )}
                    ></Select>
                  </Form.Item>

                  <Row gutter={8} style={{ marginTop: '8px' }}>
                    <Col span={14}>
                      {isEditGood && (
                        <Form.Item label="Товар" rules={[{ required: true, message: 'Выберите товар' }]} name="goodsId">
                          <Select
                            options={goodsOptions}
                            onChange={onChangeGood}
                            dropdownRender={menu => (
                              <>
                                <Button onClick={() => setIsEditGood(false)} block style={{ marginBottom: '4px' }}>
                                  Добавить новый
                                </Button>
                                {menu}
                              </>
                            )}
                          ></Select>
                        </Form.Item>
                      )}
                      {!isEditGood && (
                        <Form.Item rules={[{ required: true, message: 'Выберите товар' }]} label="Товар" name="name">
                          <Input></Input>
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label="Измерение"
                        name="measureUnit"
                        rules={[{ required: true, message: 'Выберите измерение' }]}
                      >
                        <Select options={measuresOptions}></Select>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item label="Кол-во" name="count" rules={[{ required: true, message: 'Выберите кол-во' }]}>
                        <InputNumber min={0} precision={2} controls={false}></InputNumber>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={8} style={{ marginTop: '8px' }}>
                    <Col span={12}>
                      <Form.Item label="Закупочная цена" name="purchaseProductCost">
                        <InputNumber min={0} precision={2} controls={false} suffix="₽"></InputNumber>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Цена продажи" name="cost">
                        <InputNumber min={0} precision={2} suffix="₽" controls={false}></InputNumber>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
                    <Col flex="none">
                      <Form.Item name="hasDates" valuePropName="checked">
                        <Switch onChange={e => setHasDates(e)}></Switch>
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Typography.Text>Указать срок годности и дату производства</Typography.Text>
                    </Col>
                  </Row>

                  {hasDates && (
                    <Row gutter={8} style={{ marginTop: '8px' }}>
                      <Col span={12}>
                        <Form.Item label="Дата производства" name="productionDate">
                          <DatePicker
                            format={{ format: 'DD.MM.YYYY', type: 'mask' }}
                            style={{ width: '100%' }}
                          ></DatePicker>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Дата истечения срока годности" name="expirationDate">
                          <DatePicker
                            format={{ format: 'DD.MM.YYYY', type: 'mask' }}
                            style={{ width: '100%' }}
                          ></DatePicker>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
                    <Col flex="none">
                      <Form.Item name="hasImage" valuePropName="checked">
                        <Switch onChange={e => setHasImage(e)}></Switch>
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Typography.Text>Загрузить картинку товара</Typography.Text>
                    </Col>
                  </Row>

                  {hasImage && (
                    <Row>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={handleChangeImage}
                        maxCount={1}
                      >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                      </Upload>
                    </Row>
                  )}

                  <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
                    <Col flex="none">
                      <Form.Item name="hasMarkCode" valuePropName="checked">
                        <Switch></Switch>
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Typography.Text>Маркировочный товар</Typography.Text>
                    </Col>
                  </Row>

                  {price && (
                    <Row justify="end">
                      <Typography.Title level={5}>{price} ₽</Typography.Title>
                    </Row>
                  )}
                </Form>
              </Collapse.Panel>
            </Collapse>

            <Button onClick={() => onSaveGood()} style={{ marginTop: '12px' }}>
              Добавить ещё товар
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  )
}
