import { FC, useEffect, useState } from 'react'
import { TransactionsForm } from '@components/transactions/transactions-form/transactions-form.component'
import { Redirect, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { notification } from 'antd'

import { ClientsBookingsApi } from '../../api/types/clients-bookings-api.types'
import { apiV1_1 } from '../../api/api-v1.1'
import { transactionsCreatePageContractsActions } from '../../store/pages/transactions-create-page/transactions-create-page-contracts/transactions-create-page-contracts.slice'
import { TransactionsCreatePageHeader } from './transactions-create-page-header/transactions-create-page-header.component'
import { useTransactionsCreatePage } from './transactions-create-page.hook'
import { ITransactionCreateDefaultValues } from './transactions-create-page.types'
import { getTransactionsCreatePageTableProducts } from '../../store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.selectors'
import { transactionsCreatePageTableActions } from '../../store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.slice'
import { modalActions } from '../../store/common/modal/modal.slice'
import { genTransactionsCreatePageStatusesData } from '../../store/pages/transactions-create-page/transactions-create-page-statuses/transactions-create-page-statuses.selectors'
import { AppModal } from '../../types/modal.types'
import { isDef } from '../../types/lang.types'
import { genHomePagePath } from '../../format/path.format'

export const TransactionsCreatePage: FC = () => {
  const { form, studioId, allowedStudios } = useTransactionsCreatePage()

  const [clientId, setIsClientId] = useState<string | null>(null)

  const location = useLocation<ITransactionCreateDefaultValues>()
  const dispatch = useDispatch()

  const queryParams = new URLSearchParams(location.search)
  const view = queryParams.get('view')
  const transactionId = queryParams.get('transactionId')

  const statuses = useSelector(genTransactionsCreatePageStatusesData)

  useEffect(() => {
    if (view && transactionId) {
      dispatch(
        modalActions.show({
          modal: AppModal.TRANSACTIONS_CREATE_PAGE_MODAL_WAITING,
          props: {
            transactionId: transactionId,
            studioId: '',
          },
        })
      )
    }
  }, [view])

  useEffect(() => {
    if (location.state?.phone) {
      form.setFieldValue('phone', location.state?.phone)
    }
    if (location.state?.clientId) {
      setIsClientId(location.state?.clientId)
    }
  }, [])

  // SECTION: Store
  const products = useSelector(getTransactionsCreatePageTableProducts)

  const [records, setRecords] = useState<ClientsBookingsApi.UnpaidRecord[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [selectedUnpaidRecords, setSelectedUnpaidRecords] = useState<ClientsBookingsApi.UnpaidRecord[]>([])

  // SECTION: Lifecycle

  useEffect(() => {
    if (!clientId) return

    const getActiveRecordsForProduct = async () => {
      setIsLoading(true)
      const studioId = localStorage.getItem('APP_SELECTED_STUDIO_ID') as string
      const response = await apiV1_1.clientsBookingsService.fetchAllUnpaid(clientId, { studioId })

      setRecords(
        (response?.data ?? []).sort((a, b) => {
          const dateA = dayjs(a.exerciseDate)
          const dateB = dayjs(b.exerciseDate)

          const isLongA = a.bookingIds.length > 1
          const isLongB = b.bookingIds.length > 1

          if (isLongA && !isLongB) return -1
          else if (!isLongA && isLongB) return 1
          else return dateA.isBefore(dateB) ? -1 : 1
        })
      )
      setTimeout(() => setIsLoading(false), 1000)
      return []
    }

    getActiveRecordsForProduct()
  }, [clientId])

  useEffect(() => {
    if (!clientId) return

    dispatch(transactionsCreatePageContractsActions.fetchClientsContracts({ id: clientId }))

    return () => {
      dispatch(transactionsCreatePageContractsActions.reset())
    }
  }, [clientId, dispatch])

  // SECTION: Actions
  const handleAddUnpaidRecord = (unpaidRecord: ClientsBookingsApi.UnpaidRecord, isForceAdd: boolean = false) => {
    if (!isForceAdd && selectedUnpaidRecords.find(record => record.responseId === unpaidRecord.responseId)) return

    const recordFromLongRecord = selectedUnpaidRecords.find(
      record =>
        record.bookingIds.length === 1 &&
        unpaidRecord.bookingIds.length > 1 &&
        unpaidRecord.bookingIds.find(id => id === record.bookingIds[0])
    )
    if (recordFromLongRecord) {
      setSelectedUnpaidRecords(prev => [
        ...prev.filter(record => record.responseId !== recordFromLongRecord.responseId),
        unpaidRecord,
      ])

      const removalProduct = products?.find(
        product => JSON.stringify(product.entity.bookingIds) === JSON.stringify(recordFromLongRecord.bookingIds)
      )
      removalProduct && dispatch(transactionsCreatePageTableActions.removeProduct(removalProduct.uuid))
    } else {
      setSelectedUnpaidRecords(prev => [...prev, unpaidRecord])
    }
  }

  const handleRemoveUnpaidRecord = (responseId: string) => {
    setSelectedUnpaidRecords(prev => prev.filter(record => record.responseId !== responseId))
  }

  if (!allowedStudios?.includes(studioId)) {
    notification.error({
      message: 'У вас нет разрешения на это действие',
    })
    return <Redirect to={genHomePagePath()} />
  }

  return (
    <>
      <TransactionsCreatePageHeader form={form} setClientId={setIsClientId} />
      <TransactionsForm
        form={form}
        clientId={clientId}
        exerciseId={location.state?.exerciseId}
        totalUnpaidRecords={records}
        isLoadingUnpaidRecords={isLoading}
        selectedUnpaidRecords={selectedUnpaidRecords}
        handleAddUnpaidRecord={handleAddUnpaidRecord}
        handleRemoveUnpaidRecord={handleRemoveUnpaidRecord}
      />
    </>
  )
}
