import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { genScheduleLongtermPagePeekAboutExercisesFilter } from '../schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'
import { genScheduleLongtermPagePeekAboutStudios } from '../schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { genScheduleEditExercisesFormValues } from '../../../../mapping/exercises-timetable.mapping'
import { mapExercisesTimetablePaymentTypesToOptions } from '../../../../mapping/payment-types.mapping'

export const getScheduleLongtermPageModalEditPaymentTypes = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPageModalEdit.paymentTypes

export const getScheduleLongtermPageModalEditPaymentTypesOptions = createSelector(
  getScheduleLongtermPageModalEditPaymentTypes,
  mapExercisesTimetablePaymentTypesToOptions
)

export const getScheduleLongtermPageModalEditIsLoading = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPageModalEdit.isLoading

export const getScheduleLongtermPageModalEditFormValues = createSelector(
  genScheduleLongtermPagePeekAboutExercisesFilter,
  genScheduleLongtermPagePeekAboutStudios,
  getCurrentStudioOffset,
  (exercisesFilter, studios, offset) => genScheduleEditExercisesFormValues(exercisesFilter, studios?.content, offset)
)
