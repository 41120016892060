import { Button, Modal } from 'antd'
import { useSelector } from 'react-redux'

import { AppModalBaseProps } from '../../../../types/modal.types'
import { getCounterpartyIsDeleting } from '../../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.selectors'

export interface CounterpartiesModalDeleteProps extends AppModalBaseProps {
  handleOk: () => void
  handleCancel: () => void
}

export const CounterpartiesModalDelete = ({ handleOk, handleCancel }: CounterpartiesModalDeleteProps) => {
  const isDeleting = useSelector(getCounterpartyIsDeleting)

  return (
    <Modal
      title="Удалить контрагента"
      visible={true}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} loading={isDeleting}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" danger onClick={handleOk} loading={isDeleting}>
          Удалить
        </Button>,
      ]}
    >
      <p>Вы уверены что хотите удалить контрагента?</p>
    </Modal>
  )
}
