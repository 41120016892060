import { Flex } from 'antd'
import { FC } from 'react'

import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { SidePeek } from '../../side-peek/side-peek.component'
import { ScheduleExercisesFilters } from '../schedule-exercises-filters/schedule-exercises-filters.component'
import { ScheduleExercisesTable } from '../schedule-exercises-table/schedule-exercises-table.component'
import { ScheduleOverview } from '../schedule-overview/schedule-overview.component'
import { ScheduleAboutPeekProps } from './schedule-about-peek.types'

export const ScheduleAboutPeek: FC<ScheduleAboutPeekProps> = props => {
  const {
    isTimetableLoading,
    isExercisesLoading,
    isCommentLoading,
    studioId,
    schedule,
    exercises,
    exercisesFilter,
    totalExercises,
    trainersOptions,
    studiosOptions,
    roomsOptions,
    schedulePath,
    longterm,
    timetableUpdatePermission,
    onEditComment,
    onClient,
    onEditExercises,
    onAddExercises,
    onCancelPeek,
    onSelectFilter,
    onResetFilter,
    ...rest
  } = props

  return (
    <SidePeek link={schedulePath} onCancel={onCancelPeek}>
      {isTimetableLoading ? (
        <PageLoader />
      ) : schedule ? (
        <Flex vertical gap={16}>
          <ScheduleOverview
            isCommentLoading={isCommentLoading}
            schedule={schedule}
            onEditComment={onEditComment}
            onClient={onClient}
          />
          <ScheduleExercisesFilters
            isExercisesLoading={isExercisesLoading}
            studioId={studioId}
            exercisesFilter={exercisesFilter}
            totalExercises={totalExercises}
            trainersOptions={trainersOptions}
            studiosOptions={studiosOptions}
            roomsOptions={roomsOptions}
            dateFrom={schedule.dateFrom}
            dateTo={schedule.dateTo}
            longterm={longterm}
            timetableUpdatePermission={timetableUpdatePermission}
            onEditExercises={onEditExercises}
            onAddExercises={onAddExercises}
            onSelectFilter={onSelectFilter}
            onResetFilter={onResetFilter}
          />
          <ScheduleExercisesTable
            isExercisesLoading={isExercisesLoading}
            exercises={exercises}
            longterm={longterm}
            {...rest}
          />
        </Flex>
      ) : (
        <PageEmpty description="Занятий не найдено" />
      )}
    </SidePeek>
  )
}
