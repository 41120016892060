import React from 'react'
import { Tabs, Typography } from 'antd'
import { TabsProps } from 'antd/es/tabs'

import { ContractsTabsContainer } from '../../../../components/contracts/contracts-tabs-container/contracts-tabs-container.component'
import { BankTabs } from './contracts-info-bank-tabs/contracts-info-tabs-bank.component'
import style from './contracts-info-tabs-description.module.scss'
import { MainTabs } from './contracts-info-main-tabs/contracts-info-tabs-main.component'
import { useDescriptionTabs } from './contracts-info-tabs.hook'

export const DescriptionTabs = () => {
  const { onEditCounterpartyHandler } = useDescriptionTabs()

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Основная информация',
      children: <MainTabs onHandleEdit={onEditCounterpartyHandler} />,
    },
    {
      key: '2',
      label: 'Банковские реквизиты',
      children: <BankTabs onHandleEdit={onEditCounterpartyHandler} />,
    },
  ]

  return (
    <ContractsTabsContainer>
      <Typography.Title level={3} className={style.title}>
        Основное
      </Typography.Title>
      <Tabs defaultActiveKey="1" type={'line'} items={items} />
    </ContractsTabsContainer>
  )
}
