import { DownOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons'
import axios from 'axios'
import { Button, Col, DatePicker, Dropdown, Form, Select } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import { genTokenHeader } from '../../utils/http.utils'
import { api } from '../../api/api'
import styles from './report-item.module.scss'

interface Props {
  title: string
  url: string
  generalDates: [Dayjs | null, Dayjs | null]
}

export const ReportItem = ({ title, url, generalDates }: Props) => {
  const [innerDates, setInnerDates] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [isOpen, setIsOpen] = useState(false)
  const [isDatesOpen, setIsDatesOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const token = api.getAccessToken()

  const isButtonDisabled = !generalDates.every(Boolean) && !innerDates.every(Boolean)

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {title} <InfoCircleOutlined className={styles.icon} />
      </div>
      <div>
        {/* <div
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          className={styles.settingsButton}
        >
          Тонкие настройки <DownOutlined />
        </div> */}
        {isOpen && (
          <>
            {/* <div className={styles.settingsContainer}>
              <Form>
                <div>
                  <div>
                    <Col>
                      <Form.Item className={styles.field} required label="Компания">
                        <Select
                          style={{
                            width: '100%',
                          }}
                          className={styles.select}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                </div>
                <div>
                  <div>Локация</div>
                </div>
              </Form>
            </div> */}
          </>
        )}
        <div className={styles.rangePickerContainer}>
          {isDatesOpen ? (
            <DatePicker.RangePicker
              value={innerDates}
              onChange={setInnerDates}
              className={styles.rangePicker}
              placeholder={['Дата начала', 'Дата окончания']}
            />
          ) : (
            <Button onClick={() => setIsDatesOpen(true)} className={styles.datesButton}>
              Выберите даты
            </Button>
          )}
        </div>
        {!isOpen && (
          <div>
            <Button
              disabled={isButtonDisabled}
              loading={isLoading}
              onClick={async () => {
                let start: string = ''
                let end: string = ''

                if (innerDates.every(Boolean)) {
                  start = dayjs(innerDates[0]).format('YYYY-MM-DD')
                  end = dayjs(innerDates[1]).format('YYYY-MM-DD')
                } else {
                  start = dayjs(generalDates[0]).format('YYYY-MM-DD')
                  end = dayjs(generalDates[1]).format('YYYY-MM-DD')
                }

                setIsLoading(true)

                await axios({
                  url: `https://ai.vivacrm.ru/excel/${url}?dateFrom=${start}&dateTo=${end}`, //your url
                  method: 'GET',
                  responseType: 'blob', // important
                  headers: {
                    Authorization: genTokenHeader(token),
                  },
                }).then(response => {
                  console.log('response = ', response.data)
                  // create file link in browser's memory
                  const href = URL.createObjectURL(response.data)

                  // create "a" HTML element with href to file & click
                  const link = document.createElement('a')
                  link.href = href
                  link.setAttribute('download', 'file.xlsx') //or any other extension
                  document.body.appendChild(link)
                  link.click()

                  // clean up "a" element & remove ObjectURL
                  document.body.removeChild(link)
                  URL.revokeObjectURL(href)
                })

                setIsLoading(false)
              }}
              type="primary"
            >
              Скачать в Excel
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
