import * as React from 'react'

import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { ScheduleTable } from '../../../components/schedule/schedule-table/schedule-table.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { useScheduleManagementPageTable } from './schedule-management-page-table.hook'

export const ScheduleManagementPageTable: React.FC = () => {
  const {
    schedule,
    isLoading,
    timetableDeletePermission,
    pagination,
    onEditHandler,
    onRemoveHandler,
    onHistoryHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  } = useScheduleManagementPageTable()

  if (isLoading) {
    return <PageLoader />
  }
  if (isDefAndNotEmpty(schedule)) {
    return (
      <ScheduleTable
        data={schedule}
        loading={isLoading}
        timetableDeletePermission={timetableDeletePermission}
        pagination={pagination}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        onHistory={onHistoryHandler}
        onChangePage={onChangePageHandler}
        onChangePageSize={onChangePageSizeHandler}
      />
    )
  }

  return <PageEmpty description="На эту дату нет расписания" />
}
