import { notification } from 'antd'
import { put, select, take, takeLatest } from 'redux-saga/effects'

import { apiV2 } from '../../../../api/api-v2'
import { PaymentType } from '../../../../api/types/api.types'
import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { ScheduleExercisesFilter } from '../../../../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { ScheduleModalConflictsAction } from '../../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.types'
import { ScheduleOverview } from '../../../../components/schedule/schedule-overview/schedule-overview.types'
import {
  genScheduleExercisesEditCommentDTO,
  genScheduleExercisesEditDTO,
  genScheduleExercisesPayDTO,
} from '../../../../mapping/exercises-timetable.mapping'
import { isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'
import { AppModal } from '../../../../types/modal.types'
import { callApi } from '../../../../utils/sagas.utils'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'
import { modalActions } from '../../../common/modal/modal.slice'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import {
  genScheduleLongtermPagePeekAboutExercisesFilter,
  genScheduleLongtermPagePeekAboutExercisesTotalElement,
  genScheduleLongtermPagePeekAboutSchedule,
} from '../schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { getScheduleLongtermPageModalEditPaymentTypes } from './schedule-longterm-page-modal-edit.selectors'
import { scheduleLongtermPageModalEditActions } from './schedule-longterm-page-modal-edit.slice'

function* fetchPaymentTypes(action: ReturnType<typeof scheduleLongtermPageModalEditActions.fetchPaymentTypes>) {
  try {
    const { phone, scheduleId } = action.payload
    const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleLongtermPagePeekAboutExercisesFilter)

    if (phone) {
      const response: Awaited<ReturnType<typeof apiV2.exercisesTimetableService.fetchPaymentTypesByTimetableId>> =
        yield callApi(apiV2.exercisesTimetableService.fetchPaymentTypesByTimetableId, scheduleId, {
          ...exercisesFilter,
          phone,
        })

      yield put(scheduleLongtermPageModalEditActions.fetchPaymentTypesSuccess(response.data))
    } else {
      yield put(scheduleLongtermPageModalEditActions.fetchPaymentTypesError(new Error()))
    }
  } catch (e) {
    console.error(e)
    yield put(scheduleLongtermPageModalEditActions.fetchPaymentTypesError(new Error()))
  }
}

function* updateExercises(action: ReturnType<typeof scheduleLongtermPageModalEditActions.updateExercises>) {
  const { studioId, scheduleId, scheduleFormValues } = action.payload

  const studioOffset: number | undefined = yield select(getCurrentStudioOffset)
  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleLongtermPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleLongtermPagePeekAboutExercisesTotalElement)

  const exercisesDTO = genScheduleExercisesEditDTO(scheduleFormValues, exercisesFilter, studioOffset)

  if (Object.keys(exercisesDTO.update).length !== 0) {
    yield put(websocketTimetableActions.connect(scheduleId))

    try {
      yield take(websocketTimetableActions.connectionSuccess.type)

      yield callApi(apiV2.exercisesTimetableService.updateExercisesByTimetableId, scheduleId, exercisesDTO)
      yield put(scheduleLongtermPageModalEditActions.updateExercisesSuccess())
      yield put(modalActions.closeLast())
      yield put(
        modalActions.show({
          modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
          props: {
            studioId,
            scheduleId,
            action: ScheduleModalConflictsAction.EDIT,
            totalExercises,
          },
        })
      )
    } catch (e) {
      console.error(e)
      yield put(scheduleLongtermPageModalEditActions.updateExercisesError(new Error()))
    }
  } else {
    yield put(scheduleLongtermPageModalEditActions.updateExercisesSuccess())
    yield put(modalActions.closeLast())
  }
}

function* updateExercisesComment(
  action: ReturnType<typeof scheduleLongtermPageModalEditActions.updateExercisesComment>
) {
  const { studioId, scheduleId, comment } = action.payload

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleLongtermPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleLongtermPagePeekAboutExercisesTotalElement)

  const exercisesDTO = genScheduleExercisesEditCommentDTO(exercisesFilter, comment)

  if (Object.keys(exercisesDTO.update).length !== 0) {
    yield put(websocketTimetableActions.connect(scheduleId))

    try {
      yield take(websocketTimetableActions.connectionSuccess.type)

      yield callApi(apiV2.exercisesTimetableService.updateExercisesByTimetableId, scheduleId, exercisesDTO)
      yield put(scheduleLongtermPageModalEditActions.updateExercisesCommentSuccess())
      yield put(modalActions.closeLast())
      yield put(
        modalActions.show({
          modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
          props: {
            studioId,
            scheduleId,
            action: ScheduleModalConflictsAction.EDIT,
            totalExercises,
          },
        })
      )
    } catch (e) {
      console.error(e)
      yield put(scheduleLongtermPageModalEditActions.updateExercisesCommentError(new Error()))
    }
  } else {
    yield put(scheduleLongtermPageModalEditActions.updateExercisesCommentSuccess())
    yield put(modalActions.closeLast())
  }
}

function* payExercises(action: ReturnType<typeof scheduleLongtermPageModalEditActions.payExercises>) {
  const { studioId, scheduleId, paymentType } = action.payload

  const schedule: Nullable<ScheduleOverview> = yield select(genScheduleLongtermPagePeekAboutSchedule)
  const phone = schedule?.client?.phone

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleLongtermPagePeekAboutExercisesFilter)
  const paymentTypes: Nullable<ExercisesTimetableApi.ExercisesTimetablePaymentType[]> = yield select(
    getScheduleLongtermPageModalEditPaymentTypes
  )

  const selectedPaymentType = paymentTypes?.find(type =>
    paymentType === PaymentType.ONE_TIME
      ? type.paymentType === PaymentType.ONE_TIME
      : type.clientSubscriptionId === paymentType
  )

  if (selectedPaymentType && phone) {
    const scheduleExercisesPayDTO = genScheduleExercisesPayDTO(phone, exercisesFilter, selectedPaymentType)

    yield put(websocketTimetableActions.connect(scheduleId))

    try {
      yield take(websocketTimetableActions.connectionSuccess.type)

      const response: Awaited<ReturnType<typeof apiV2.exercisesTimetableService.payExercisesByTimetableId>> =
        yield callApi(apiV2.exercisesTimetableService.payExercisesByTimetableId, scheduleId, scheduleExercisesPayDTO)

      if (response && isDefAndNotEmpty(response.data.affordableExercises)) {
        yield put(scheduleLongtermPageModalEditActions.payExercisesSuccess())
        yield put(modalActions.closeLast())
        yield put(
          modalActions.show({
            modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
            props: {
              studioId,
              scheduleId,
              action: ScheduleModalConflictsAction.PAY_BOOKING,
              totalExercises: response.data.affordableExercises.length,
            },
          })
        )
      } else {
        notification.error({
          message: 'Ошибка оплаты',
        })
        yield put(websocketTimetableActions.disconnect())
        yield put(scheduleLongtermPageModalEditActions.payExercisesError(new Error()))
      }
    } catch (e) {
      console.error(e)
      yield put(scheduleLongtermPageModalEditActions.payExercisesError(new Error()))
    }
  } else {
    yield put(scheduleLongtermPageModalEditActions.payExercisesError(new Error()))
  }
}

function* removeExercises(action: ReturnType<typeof scheduleLongtermPageModalEditActions.removeExercises>) {
  const { studioId, scheduleId } = action.payload

  const exercisesFilter: ScheduleExercisesFilter = yield select(genScheduleLongtermPagePeekAboutExercisesFilter)
  const totalExercises: number | undefined = yield select(genScheduleLongtermPagePeekAboutExercisesTotalElement)

  yield put(websocketTimetableActions.connect(scheduleId))

  try {
    yield take(websocketTimetableActions.connectionSuccess.type)

    yield callApi(apiV2.exercisesTimetableService.removeExercisesByTimetableId, scheduleId, exercisesFilter)
    yield put(scheduleLongtermPageModalEditActions.removeExercisesSuccess())
    yield put(modalActions.closeLast())
    yield put(
      modalActions.show({
        modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
        props: {
          studioId,
          scheduleId,
          action: ScheduleModalConflictsAction.EDIT,
          totalExercises,
        },
      })
    )
  } catch (e) {
    console.error(e)
    yield put(scheduleLongtermPageModalEditActions.removeExercisesError(new Error()))
  }
}

export function* scheduleLongtermPageModalEditSagas() {
  yield takeLatest(scheduleLongtermPageModalEditActions.fetchPaymentTypes, fetchPaymentTypes)
  yield takeLatest(scheduleLongtermPageModalEditActions.updateExercises, updateExercises)
  yield takeLatest(scheduleLongtermPageModalEditActions.updateExercisesComment, updateExercisesComment)
  yield takeLatest(scheduleLongtermPageModalEditActions.payExercises, payExercises)
  yield takeLatest(scheduleLongtermPageModalEditActions.removeExercises, removeExercises)
}
