import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import { getCounterpartyByIdIsLoading } from '../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.selectors'
import { contractsInfoPageDescriptionActions } from '../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.slice'

export function useContractsInfoPage(id: string) {
  const dispatch = useDispatch()
  const isLoading = useSelector(getCounterpartyByIdIsLoading)

  React.useEffect(() => {
    dispatch(contractsInfoPageDescriptionActions.fetchCounterpartiesById({ id }))
  }, [dispatch, id])

  React.useEffect(() => {
    return () => {
      dispatch(contractsInfoPageDescriptionActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
  }
}
