import { put, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { modalActions } from '../../../common/modal/modal.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { api } from '../../../../api/api'
import { contractsInfoPageDescriptionActions } from './contracts-info-page-description.slice'

export function* fetchCounterpartiesById(
  action: ReturnType<typeof contractsInfoPageDescriptionActions.fetchCounterpartiesById>
) {
  try {
    const { id } = action.payload

    const response: Awaited<ReturnType<typeof api.contractsService.fetchCounterpartiesById>> = yield callApi(
      api.contractsService.fetchCounterpartiesById,
      id
    )

    yield put(contractsInfoPageDescriptionActions.fetchCounterpartiesByIdSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageDescriptionActions.fetchCounterpartiesByIdError(new Error()))
  }
}

export function* updateCounterpartiesById(
  action: ReturnType<typeof contractsInfoPageDescriptionActions.updateCounterpartiesById>
) {
  try {
    const { id, data } = action.payload

    const response: Awaited<ReturnType<typeof api.contractsService.updateCounterpartiesById>> = yield callApi(
      api.contractsService.updateCounterpartiesById,
      id,
      data
    )
    yield put(contractsInfoPageDescriptionActions.updateCounterpartiesByIdSuccess(response.data))

    yield notification.success({
      message: 'Данные контрагента обновлены',
    })
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageDescriptionActions.updateCounterpartiesByIdError(new Error()))
  }
}

export function* deleteCounterparties(
  action: ReturnType<typeof contractsInfoPageDescriptionActions.deleteCounterpartiesById>
) {
  try {
    const { id, successCallback } = action.payload

    yield callApi(api.contractsService.deleteCounterpartiesById, id)
    yield put(contractsInfoPageDescriptionActions.deleteCounterpartiesByIdSuccess())
    yield put(modalActions.closeLast())
    yield notification.success({
      message: 'Контрагент успешно удален',
    })
    if (successCallback) successCallback()
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageDescriptionActions.deleteCounterpartiesByIdError(new Error()))
  }
}

export function* contractsInfoPageDescriptionSagas() {
  yield takeLatest(contractsInfoPageDescriptionActions.fetchCounterpartiesById, fetchCounterpartiesById)
  yield takeLatest(contractsInfoPageDescriptionActions.updateCounterpartiesById, updateCounterpartiesById)
  yield takeLatest(contractsInfoPageDescriptionActions.deleteCounterpartiesById, deleteCounterparties)
}
