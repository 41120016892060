import React from 'react'
import { Tabs } from 'antd'
import { useHistory, useParams } from 'react-router-dom'

import { validateStrEnumValue } from '../../../utils/enum.utils'
import { formatContractsInfoPageSectionName } from '../../../format/text.format'
import { genContractsInfoPagePath } from '../../../format/path.format'
import { useContractsInfoPageParams } from '../contracts-info-page-hooks/contracts-info-page.hook'
import { ContractsInfoPageSection, ContractsPageInfoParams } from '../contracts-info-page.types'
import { isDef } from '../../../types/lang.types'
import { ContractsTabs } from '../contracts-info-page-tabs/contracts-info-page-contracts/contracts-info-page-contracts.component'
import { BookingTabs } from '../contracts-info-page-tabs/contracts-info-page-booking/contracts-info-page-booking.component'
import { ClientsTabs } from './contracts-info-page-clients/contracts-info-page-clients.component'
import { DescriptionTabs } from '../contracts-info-page-tabs/contracts-info-page-description/contracts-info-tabs-description.component'

export const ContractsInfoTabs = () => {
  const { push } = useHistory()
  const { id } = useParams<ContractsPageInfoParams>()
  const { section } = useContractsInfoPageParams()

  const onChangeHandler = React.useCallback(
    (value: string) => {
      const section = validateStrEnumValue<ContractsInfoPageSection>(ContractsInfoPageSection, value)

      if (isDef(section)) {
        push(genContractsInfoPagePath({ id, section }))
      }
    },
    [id, push]
  )

  return (
    <>
      <Tabs type="card" activeKey={section} onChange={onChangeHandler} style={{ marginTop: '24px' }}>
        <Tabs.TabPane
          tab={formatContractsInfoPageSectionName(ContractsInfoPageSection.DESCRIPTION)}
          key={ContractsInfoPageSection.DESCRIPTION}
        />
        <Tabs.TabPane
          tab={formatContractsInfoPageSectionName(ContractsInfoPageSection.CLIENTS)}
          key={ContractsInfoPageSection.CLIENTS}
        />
        {/*<Tabs.TabPane*/}
        {/*  tab={formatContractsInfoPageSectionName(ContractsInfoPageSection.BOOKING_AND_PRODUCTS)}*/}
        {/*  key={ContractsInfoPageSection.BOOKING_AND_PRODUCTS}*/}
        {/*/>*/}
        <Tabs.TabPane
          tab={formatContractsInfoPageSectionName(ContractsInfoPageSection.CONTRACTS)}
          key={ContractsInfoPageSection.CONTRACTS}
        />
      </Tabs>
      {section === ContractsInfoPageSection.DESCRIPTION && <DescriptionTabs />}
      {section === ContractsInfoPageSection.CLIENTS && <ClientsTabs />}
      {/*{section === ContractsInfoPageSection.BOOKING_AND_PRODUCTS && <BookingTabs />}*/}
      {section === ContractsInfoPageSection.CONTRACTS && <ContractsTabs />}
    </>
  )
}
