import { notification } from 'antd'
import { FC } from 'react'
import { Redirect } from 'react-router-dom'

import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { genHomePagePath, genTransactionsPagePath } from '../../format/path.format'
import { isDef } from '../../types/lang.types'
import { TransactionsPageFilter } from './transactions-page-filter/transactions-page-filter.component'
import { TransactionsPageTable } from './transactions-page-table/transactions-page-table.component'
import { useTransactionsPage } from './transactions-page.hook'
import { TransactionsShift } from './transactions-shift/transactions-shift.component'

export const TransactionsPage: FC = () => {
  const { isLoaded, isLoading, isShowShiftTransactions, studioId, selectedStudioId, allowedStudios } =
    useTransactionsPage()

  if (!allowedStudios?.includes(studioId)) {
    notification.error({
      message: 'У вас нет разрешения на это действие',
    })

    if (isDef(selectedStudioId)) {
      return <Redirect to={genTransactionsPagePath(selectedStudioId)} />
    }
    return <Redirect to={genHomePagePath()} />
  }

  return (
    <>
      {isShowShiftTransactions && <TransactionsShift />}

      {!isLoaded && isLoading ? (
        <PageLoader />
      ) : (
        <>
          <TransactionsPageFilter />
          <TransactionsPageTable />
        </>
      )}
    </>
  )
}
