import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ChangeClientPayload, FetchClientPayload } from './contracts-info-page-clients.types'
import { Pagination } from '../../../../api/types/api.types'
import { ContractsApi } from '../../../../api/types/contracts-api.types'
import { Nullable } from '../../../../types/lang.types'

export interface ContractsInfoPageClientsState {
  clients: Nullable<Pagination<ContractsApi.CounterpartiesClient>>
  isLoading: boolean
  isLoaded: boolean
  isAdding: boolean
  isAdded: boolean
  error: Nullable<Error>
}

const initialState: ContractsInfoPageClientsState = {
  clients: null,
  isLoading: false,
  isLoaded: false,
  isAdding: false,
  isAdded: false,
  error: null,
}

export const { actions: contractsInfoPageClientsActions, reducer: contractsInfoPageClientsReducer } = createSlice({
  name: '@@contracts-info-page-clients',
  initialState,
  reducers: {
    fetchAllClients: (state: Draft<ContractsInfoPageClientsState>, _: PayloadAction<FetchClientPayload>) => {
      state.isLoading = true
    },
    fetchAllClientsSuccess: (
      state: Draft<ContractsInfoPageClientsState>,
      action: PayloadAction<Nullable<Pagination<ContractsApi.CounterpartiesClient>>>
    ) => {
      state.clients = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllClientsError: (state: Draft<ContractsInfoPageClientsState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    removeClient: (state: Draft<ContractsInfoPageClientsState>, _: PayloadAction<ChangeClientPayload>) => {
      state.isLoading = true
    },
    removeClientSuccess: (state: Draft<ContractsInfoPageClientsState>, _: PayloadAction<void>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    removeClientError: (state: Draft<ContractsInfoPageClientsState>, _: PayloadAction<Error>) => {
      state.isLoading = true
    },
    createClientCounterparty: (
      state: Draft<ContractsInfoPageClientsState>,
      _: PayloadAction<{ counterpartyId: string; clientPhone: { clientPhone: string } }>
    ) => {
      state.isAdding = true
    },
    createClientCounterpartySuccess: (state: Draft<ContractsInfoPageClientsState>) => {
      state.isAdding = false
      state.isAdded = true
    },
    createClientCounterpartyError: (state: Draft<ContractsInfoPageClientsState>, action: PayloadAction<Error>) => {
      state.isAdding = false
      state.isAdded = true
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
