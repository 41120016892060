import React from 'react'
import { Button, Flex } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useContractsClientsHeader } from './contracts-info-clients-header.hook'

interface ContractsClientsHeaderProps {
  id: string
}

export const ContractsClientsHeader = ({ id }: ContractsClientsHeaderProps) => {
  const { onCreateClientHandler } = useContractsClientsHeader(id)

  return (
    <>
      <Flex justify={'flex-end'}>
        <Button icon={<PlusOutlined color={'white'} />} type={'primary'} onClick={onCreateClientHandler}>
          Добавить сотрудника
        </Button>
      </Flex>
    </>
  )
}
