import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'

export class FileService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public uploadPdf = (pdf: any): Promise<AxiosResponse<string>> => {
    return this.httpConnector.post<any>('/uploads/pdf', pdf, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
