import { put, race, select, take, takeLatest, call, all } from 'redux-saga/effects'
import { createMatchSelector, getSearch } from 'connected-react-router'
import { match } from 'react-router-dom'
import * as H from 'history'

import { api } from '../../../../api/api'
import { AppPath } from '../../../../types/path.types'
import { AppState } from '../../../app.store'
import { modalActions } from '../../../common/modal/modal.slice'
import { scheduleLongtermPageListActions } from './schedule-longterm-page-list.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { ScheduleLongtermPageUrlParams } from '../../../../pages/schedule-longterm-page/schedule-longterm-page.types'
import { genScheduleLongtermPageParams } from '../../../../pages/schedule-longterm-page/schedule-longterm-page.utils'
import { apiV2 } from '../../../../api/api-v2'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'

function* fetchPageData(action: ReturnType<typeof scheduleLongtermPageListActions.fetchPageData>) {
  try {
    const { studioId, roomId, longtermClientPhone, page, size } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const [exercisesTimetableResponse, studiosRoomsResponse]: [
      Awaited<ReturnType<typeof apiV2.exercisesTimetableService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosRoomsService.fetchAll>>
    ] = yield all([
      callApi(apiV2.exercisesTimetableService.fetchAll, {
        studioId,
        roomId,
        longtermClientPhone,
        longterm: true,
        ...params,
      }),
      callApi(api.studiosRoomsService.fetchAll, studioId),
    ])

    yield put(
      scheduleLongtermPageListActions.fetchPageDataSuccess({
        exercisesTimetable: exercisesTimetableResponse.data,
        studiosRooms: studiosRoomsResponse.data.content,
      })
    )
  } catch (e) {
    yield put(scheduleLongtermPageListActions.fetchPageDataError())
  }
}

export function* reFetchPageData() {
  const search: H.Search = yield select(getSearch)
  const { params }: match<ScheduleLongtermPageUrlParams> = yield select(
    createMatchSelector<AppState, ScheduleLongtermPageUrlParams>(AppPath.SCHEDULE_LONGTERM)
  )
  const pageParams = genScheduleLongtermPageParams(params, search)

  yield put(scheduleLongtermPageListActions.fetchPageData(pageParams))

  yield race([
    take(scheduleLongtermPageListActions.fetchPageDataSuccess.type),
    take(scheduleLongtermPageListActions.fetchPageDataError.type),
  ])
}

function* fetchExercisesTimetable(_action: ReturnType<typeof scheduleLongtermPageListActions.fetchExercisesTimetable>) {
  try {
    const search: H.Search = yield select(getSearch)
    const { params }: match<ScheduleLongtermPageUrlParams> = yield select(
      createMatchSelector<AppState, ScheduleLongtermPageUrlParams>(AppPath.SCHEDULE_LONGTERM)
    )
    const pageParams = genScheduleLongtermPageParams(params, search)
    const { studioId, roomId, longtermClientPhone, page, size } = pageParams
    const paginationParams = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof apiV2.exercisesTimetableService.fetchAll>> = yield callApi(
      apiV2.exercisesTimetableService.fetchAll,
      {
        studioId,
        roomId,
        longtermClientPhone,
        longterm: true,
        ...paginationParams,
      }
    )
    yield put(scheduleLongtermPageListActions.fetchExercisesTimetableSuccess(response?.data))
  } catch (e) {
    yield put(scheduleLongtermPageListActions.fetchExercisesTimetableError())
  }
}

function* cancelSchedule(action: ReturnType<typeof scheduleLongtermPageListActions.cancelSchedule>) {
  try {
    yield callApi(api.exercisesTimetableService.cancel, action.payload)
    yield put(scheduleLongtermPageListActions.cancelScheduleSuccess())
    yield put(modalActions.closeLast())

    yield call(reFetchPageData)
  } catch (e) {
    yield put(scheduleLongtermPageListActions.cancelScheduleError())
  }
}

export function* scheduleLongtermPageListSagas() {
  yield takeLatest(scheduleLongtermPageListActions.fetchPageData.type, fetchPageData)
  yield takeLatest(scheduleLongtermPageListActions.fetchExercisesTimetable.type, fetchExercisesTimetable)
  yield takeLatest(scheduleLongtermPageListActions.cancelSchedule.type, cancelSchedule)
}
