import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { Flex, notification } from 'antd'

import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { genHomePagePath, genScheduleManagementPagePath } from '../../format/path.format'
import { isDef } from '../../types/lang.types'
import { ScheduleManagementPageTitle } from './schedule-management-page-title/schedule-management-page-title.component'
import { ScheduleManagementPageAdd } from './schedule-management-page-add/schedule-management-page-add.component'
import { ScheduleManagementPageFilter } from './schedule-management-page-filter/schedule-management-page-filter.component'
import { ScheduleManagementPageTable } from './schedule-management-page-table/schedule-management-page-table.component'
import { useScheduleManagementPage } from './schedule-management-page.hook'

export const ScheduleManagementPage: React.FC = () => {
  const { studioId, selectedStudioId, allowedStudios, isLoaded, isLoading } = useScheduleManagementPage()

  if (!allowedStudios?.includes(studioId)) {
    notification.error({
      message: 'У вас нет разрешения на это действие',
    })

    if (isDef(selectedStudioId)) {
      return <Redirect to={genScheduleManagementPagePath(selectedStudioId)} />
    }
    return <Redirect to={genHomePagePath()} />
  }

  if (!isLoaded || isLoading) {
    return <PageLoader />
  }

  return (
    <Flex vertical gap={24}>
      <Flex align="center" justify="space-between">
        <ScheduleManagementPageTitle />
        <ScheduleManagementPageAdd />
      </Flex>
      <ScheduleManagementPageFilter />
      <ScheduleManagementPageTable />
    </Flex>
  )
}
