import { notification } from 'antd'
import { Redirect } from 'react-router-dom'

import { CustomCard } from '../../components/custom-card/custom-card.component'
import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { genScheduleLongtermPagePath, genHomePagePath } from '../../format/path.format'
import { isDef } from '../../types/lang.types'
import { ScheduleLongtermPageFilter } from './schedule-longterm-page-filter/schedule-longterm-page-filter.component'
import { ScheduleLongtermPageTable } from './schedule-longterm-page-table/schedule-longterm-page-table.component'
import { ScheduleLongtermPageTitle } from './schedule-longterm-page-title/schedule-longterm-page-title.component'
import { useScheduleLongtermPage } from './schedule-longterm-page.hook'

export const ScheduleLongtermPage = () => {
  const { isLoaded, isLoading, studioId, selectedStudioId, allowedStudios } = useScheduleLongtermPage()

  if (!allowedStudios?.includes(studioId)) {
    notification.error({
      message: 'У вас нет разрешения на это действие',
    })

    if (isDef(selectedStudioId)) {
      return <Redirect to={genScheduleLongtermPagePath(selectedStudioId)} />
    }
    return <Redirect to={genHomePagePath()} />
  }

  if (!isLoaded || isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <ScheduleLongtermPageTitle />
      <CustomCard>
        <ScheduleLongtermPageFilter />
        <ScheduleLongtermPageTable />
      </CustomCard>
    </>
  )
}
