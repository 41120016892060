import React from 'react'

import { ContractsInfoContractHeader } from './contracts-info-contract-header/contracts-info-contract-header.component'
import { ContractsInfoContractList } from './contracts-info-contract-list/contracts-info-contract-list.component'
import { ContractsTabsContainer } from '../../../../components/contracts/contracts-tabs-container/contracts-tabs-container.component'

export const ContractsTabs = () => {
  return (
    <ContractsTabsContainer>
      <ContractsInfoContractHeader />
      <ContractsInfoContractList />
    </ContractsTabsContainer>
  )
}
