import { CopyOutlined } from '@ant-design/icons'
import { Alert, Button, Space, Tooltip, Typography } from 'antd'
import { clsx } from 'clsx'
import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'

import { formatDate } from '../../../format/date.format'
import { EntityItem, isDef, isDefAndNotEmpty, NString } from '../../../types/lang.types'
import './exercises-group-header.styles.less'

interface Props {
  className?: string
  timeFrom: string
  comment: NString
  timetableComment: NString
  direction: EntityItem<number>
  type: {
    id: number
    name: string
    color: string
    format: 'PERSONAL' | 'GROUP'
  }
  studiosRoom: EntityItem<string>
  trainers: EntityItem<string>[]
  scheduleId: string
}

export const ExercisesGroupHeader: React.FC<Props> = props => {
  const { className } = props
  const { timeFrom, comment, timetableComment, direction, type, studiosRoom, trainers, scheduleId } = props

  const title = `${type.name}, ${formatDate(timeFrom, 'DD MMMM HH:mm')}, ${direction.title}, Пространство ${
    studiosRoom.title
  }`

  return (
    <div className={clsx('exercises-group-header', className)}>
      <Space size="middle">
        <Typography.Title className="exercises-group-header__title" level={2}>
          {title}
        </Typography.Title>
        <Tooltip title="Скопировать ссылку на группу">
          <CopyToClipboard text={`https://smstretching.ru/smartlink/${scheduleId}`}>
            <Button icon={<CopyOutlined />} size="middle" />
          </CopyToClipboard>
        </Tooltip>
      </Space>

      {isDefAndNotEmpty(trainers) && (
        <Typography.Text className="exercises-group-header__trainers">
          Исполнители:{' '}
          {trainers.map((trainer, index) => (
            <span key={trainer.id}>
              <Link to={`/employees/${trainer.id}`}>{trainer.title}</Link>
              {index < trainers.length - 1 && ', '}
            </span>
          ))}
        </Typography.Text>
      )}

      <div className="exercises-group-header__comments">
        {isDef(timetableComment) && timetableComment !== '' && (
          <Alert className="exercises-group-header__comment" message={timetableComment} type="info" showIcon closable />
        )}
        {isDef(comment) && comment !== '' && (
          <Alert className="exercises-group-header__comment" message={comment} type="info" showIcon closable />
        )}
      </div>
    </div>
  )
}
