import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'

export interface ClientsCreatePageState {
  file: string
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ClientsCreatePageState = {
  file: '',
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: fileActions, reducer: fileReducer } = createSlice({
  name: '@@files',
  initialState,
  reducers: {
    uploadFile: (state: Draft<ClientsCreatePageState>, _: PayloadAction<FormData>) => {
      state.isLoading = true
    },
    uploadFileSuccess: (state: Draft<ClientsCreatePageState>, action: PayloadAction<string>) => {
      state.file = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    uploadFileError: (state: Draft<ClientsCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },

    reset: () => initialState,
  },
})
