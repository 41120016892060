import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import { SpaceSize } from 'antd/es/space'
import * as React from 'react'

interface ClientsCellActionsProps {
  forbidRemove?: boolean
  space?: SpaceSize | [SpaceSize, SpaceSize]
  onInfo: () => void
  onRemove: () => void
}

export const ContractsClientsCellActions: React.FC<ClientsCellActionsProps> = props => {
  const { forbidRemove, space = 'middle', onInfo, onRemove } = props

  return (
    <Space size={space}>
      <Tooltip title="Подробнее">
        <Button icon={<InfoCircleOutlined />} size="middle" onClick={onInfo} />
      </Tooltip>
      <Tooltip title="Удалить">
        <Button icon={<CloseOutlined />} size="middle" danger onClick={onRemove} disabled={forbidRemove} />
      </Tooltip>
    </Space>
  )
}
