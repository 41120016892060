import { InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Card, Col, Flex, Row, Tag, Tooltip, Typography } from 'antd'
import { FC } from 'react'
import clsx from 'clsx'

import { DEFAULT_EMPTY_SYMBOL, formatPaymentType } from '../../../../format/text.format'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { ScheduleExercisesTableItemProps } from './schedule-exercises-table-item.types'
import { useTheme } from '../../../../hooks/use-theme.hook'
import './schedule-exercises-table-item.styles.less'

const { Text } = Typography

export const ScheduleExercisesTableItem: FC<ScheduleExercisesTableItemProps> = props => {
  const { exercise, longterm, clientBookingDeletePermission, onExercise, onRemoveExercise } = props

  const { theme } = useTheme()

  let paymentTypeTagColor = ''
  switch (exercise.paymentType) {
    case 'SUBSCRIPTION':
      paymentTypeTagColor = 'magenta'
      break
    case 'ONE_TIME':
      paymentTypeTagColor = 'geekblue'
      break
    case 'ON_PLACE':
      paymentTypeTagColor = 'orange'
      break
  }

  const onExerciseHandler = () => {
    const { id, studio } = exercise
    onExercise(studio.id, id)
  }

  const onRemoveHandler = () => {
    onRemoveExercise(exercise.id)
  }

  return (
    <Card
      className={clsx('schedule-exercises-table-item', {
        'schedule-exercises-table-item--dark': theme === 'dark',
      })}
    >
      <Row gutter={16} align="middle" justify="space-between" wrap={false}>
        <Col flex="76px">
          <Flex vertical>
            <Text>{exercise.date}</Text>
            <Text className="schedule-exercises-table-item__text">{exercise.timeFrom}</Text>
            <Text className="schedule-exercises-table-item__text">{exercise.duration}</Text>
          </Flex>
        </Col>

        <Col>
          <Flex vertical align="start" gap="4px">
            <Text strong>{exercise.studio.name}</Text>
            <Text className="schedule-exercises-table-item__text">Пространство {exercise.room}</Text>
          </Flex>
        </Col>

        <Col>{isDefAndNotEmpty(exercise.trainers) ? <div>{exercise.trainers.join(', ')}</div> : 'Без исполнителя'}</Col>

        {longterm && (
          <Col>
            <Flex gap={10} vertical align="center">
              {exercise.paymentType ? (
                <Tag color={paymentTypeTagColor}>{formatPaymentType(exercise.paymentType)}</Tag>
              ) : (
                DEFAULT_EMPTY_SYMBOL
              )}
              {exercise.canceled && <div className="schedule-exercises-table-item__tag">ОТМЕНЕНА</div>}
            </Flex>
          </Col>
        )}

        {!longterm && exercise.canceled && (
          <Col>
            <div className="schedule-exercises-table-item__tag">ОТМЕНЕНА</div>
          </Col>
        )}

        <Col flex="104px">
          <Flex justify="end" gap="8px">
            <Button icon={<InfoCircleOutlined />} onClick={onExerciseHandler} />
            {!exercise.canceled && (
              <Tooltip title={!clientBookingDeletePermission ? 'У вас нет разрешения на это действие' : ''}>
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  disabled={!clientBookingDeletePermission}
                  onClick={onRemoveHandler}
                />
              </Tooltip>
            )}
          </Flex>
        </Col>
      </Row>
    </Card>
  )
}
