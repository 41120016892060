import * as React from 'react'
import { Button, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useScheduleManagementPageAdd } from './schedule-management-page-add.hook'

export const ScheduleManagementPageAdd: React.FC = () => {
  const { timetableCreatePermission, onAddHandler } = useScheduleManagementPageAdd()

  return (
    <div className="schedule-management-page-add">
      <Tooltip title={!timetableCreatePermission ? 'У вас нет разрешения на это действие' : ''}>
        <Button type="primary" icon={<PlusOutlined />} disabled={!timetableCreatePermission} onClick={onAddHandler}>
          Добавить расписание
        </Button>
      </Tooltip>
    </div>
  )
}
