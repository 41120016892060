import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { PermissionsApi } from '../../../api/types/permissions.types'

export interface PermissionsState {
  permissions: Nullable<PermissionsApi.Permissions[]>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

export const initialState: PermissionsState = {
  permissions: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: permissionsActions, reducer: permissionsReducer } = createSlice({
  name: '@@permissions',
  initialState,
  reducers: {
    fetchPermissions: (state: Draft<PermissionsState>, _action: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchPermissionsSuccess: (
      state: Draft<PermissionsState>,
      action: PayloadAction<Nullable<PermissionsApi.Permissions[]>>
    ) => {
      state.permissions = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPermissionsError: (state: Draft<PermissionsState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    reset: () => initialState,
  },
})
