import { AuthClientTokens } from '@react-keycloak/core/lib/types'

import { API_V2_URL } from '../app/app.config'
import { setAccessToken, setRefreshToken } from '../utils/local-storage.utils'
import { KeycloakProvider } from './providers/keycloak.provider'
import { HttpConnector } from './connectors/http.connector'
import { keycloakProvider } from './keycloak'
import { ExercisesTimetableService } from './services/exercises-timetable.service'
import { TransactionsService } from './services/transactions.service'

export class ApiV2 {
  // providers
  private readonly keycloakProvider: KeycloakProvider

  // connectors
  private readonly httpConnectorInternal: HttpConnector

  // services
  public readonly exercisesTimetableService: ExercisesTimetableService
  public readonly transactionsService: TransactionsService

  constructor() {
    // provider
    this.keycloakProvider = keycloakProvider

    // connectors
    this.httpConnectorInternal = new HttpConnector(this.keycloakProvider, API_V2_URL)

    // services
    this.exercisesTimetableService = new ExercisesTimetableService(this.httpConnectorInternal)
    this.transactionsService = new TransactionsService(this.httpConnectorInternal)
  }

  public setTokens = (tokens: AuthClientTokens): void => {
    const { token, refreshToken } = tokens

    setAccessToken(token)
    setRefreshToken(refreshToken)
  }
}

export const apiV2 = new ApiV2()
