import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { peekActions } from '../../../store/common/peek/peek.slice'
import {
  genScheduleLongtermPageListIsLoading,
  genScheduleLongtermPageListTableDataItems,
  genScheduleLongtermPageListTableTotalElements,
} from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.selectors'
import { AppPeek } from '../../../types/peek.types'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { useScheduleLongtermPageParams } from '../schedule-longterm-page-hooks/schedule-longterm-page-params.hook'
import { scheduleLongtermPageListActions } from '../../../store/pages/schedule-longterm-page/schedule-longterm-page-list/schedule-longterm-page-list.slice'
import { ExerciseTypeFormat } from '../../../api/types/api.types'
import { getTimetableDeletePermission } from '../../../store/common/permissions/permissions.selectors'
import { genListPaginationConfig } from '../../../utils/pagination.utils'
import { genScheduleLongtermPagePath } from '../../../format/path.format'

export function useScheduleLongtermPageTable() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { push } = useHistory()

  const params = useScheduleLongtermPageParams()
  const { studioId, page, size } = params

  const schedule = useSelector(genScheduleLongtermPageListTableDataItems)
  const isLoading = useSelector(genScheduleLongtermPageListIsLoading)
  const totalElements = useSelector(genScheduleLongtermPageListTableTotalElements)

  const pagination = useMemo(() => genListPaginationConfig(page, size, totalElements), [page, size, totalElements])

  const timetableDeletePermission = useSelector(getTimetableDeletePermission)

  const onCreateTransactionHandler = useCallback(
    (phone: string, clientId: string) => {
      history.push({
        pathname: `/transactions/${studioId}/create`,
        state: { phone, clientId },
      })
    },
    [history, studioId]
  )

  const onSelectPaymentTypeHandler = useCallback(
    (scheduleId: string, phone?: string, paymentType?: string) => {
      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_EDIT,
          props: {
            studioId,
            scheduleId,
            phone,
            paymentType,
          },
        })
      )
    },
    [dispatch, studioId]
  )

  const onEditHandler = useCallback(
    (id: string): void => {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_LONGTERM_PAGE_PEEK_ABOUT,
          props: { scheduleId: id, studioId },
        })
      )
    },
    [dispatch, studioId]
  )

  const onRemoveHandler = useCallback(
    (id: string): void => {
      const onSubmit = () => {
        dispatch(scheduleLongtermPageListActions.cancelSchedule(id))
      }

      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM,
          props: {
            title: 'Отмена длинной записи',
            text: 'Вы уверены, что хотите отменить длинную запись',
            onSubmit,
          },
        })
      )
    },
    [dispatch]
  )

  const onHistoryHandler = useCallback(
    (id: string): void => {
      dispatch(
        peekActions.show({
          peek: AppPeek.SCHEDULE_LONGTERM_PAGE_PEEK_HISTORY,
          props: { id, format: ExerciseTypeFormat.PERSONAL },
        })
      )
    },
    [dispatch]
  )

  const onChangePageHandler = useCallback(
    (page: number, pageSize: number): void => {
      push(genScheduleLongtermPagePath(studioId, { ...params, page, size: pageSize }))
    },
    [params, push, studioId]
  )

  const onChangePageSizeHandler = useCallback(
    (pageSize: number): void => {
      push(genScheduleLongtermPagePath(studioId, { ...params, page, size: pageSize }))
    },
    [page, params, push, studioId]
  )

  return {
    isLoading,
    schedule,
    timetableDeletePermission,
    pagination,
    onCreateTransactionHandler,
    onSelectPaymentTypeHandler,
    onEditHandler,
    onRemoveHandler,
    onHistoryHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}
