import { TeamOutlined, UserOutlined } from '@ant-design/icons'
import { QuestionTooltip } from '@components/ui/question-tooltip/question-tooltip.component'
import { useTheme } from '@hooks/use-theme.hook'
import {
  Button,
  Col,
  Flex,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { RadioChangeEvent } from 'antd/lib'
import { DefaultOptionType } from 'antd/lib/select'
import { useEffect, useState } from 'react'

import { TimeLimitation } from '../../../types/time-limitation.types'
import { useProductsSubcriptionsForm } from './products-subscriptions-form.hook'
import './products-subscriptions-form.styles.less'
import { ProductsSubscriptionsFormValue } from './products-subscriptions-form.types'
import {
  genProductsSubcriptionsQuestions,
  genServicesInitialValues,
  validateProductsSubcriptionsNoSpacesOnly,
  validateProductsSubcriptionsNumberInput,
  validateProductsSubcriptionsNumberIntegerInput,
} from './products-subscriptions-form.utils'
interface Props {
  form: FormInstance<ProductsSubscriptionsFormValue>
  isLoading: boolean
  onFinish: (values: ProductsSubscriptionsFormValue) => void
  submitText: string
  studiosOptions?: DefaultOptionType[]
  exercisesOptions?: DefaultOptionType[]
  activationDaysValue?: number | null
  freezingValue?: number | null
  disabledVariants: boolean
}

export const ProductSubscriptionForm = ({
  form,
  isLoading,
  onFinish,
  submitText,
  studiosOptions,
  exercisesOptions,
  activationDaysValue,
  freezingValue,
  disabledVariants,
}: Props) => {
  const { theme } = useTheme()

  const { filteredOptions, filteredDirectionsOptions, durationOptions } =
    // @ts-ignore
    useProductsSubcriptionsForm(exercisesOptions, form)

  // freeze input
  const [isFreeze, setIsFreeze] = useState(true)
  const onChangeFreeze = () => {
    setIsFreeze(prev => !prev)
    form.setFieldValue('freezingDays', '')
  }

  // auto activate input
  const [isAutoActivate, setIsAutoActivate] = useState(true)

  const onChangeAutoActivate = () => {
    setIsAutoActivate(prev => !prev)
    form.setFieldValue('activationDays', '')
  }

  // Time limit input
  const [timeLimitationSwitch, setTimeLimitationSwitch] = useState<TimeLimitation>(TimeLimitation.NONE)
  const typeLimitationInput = form.getFieldValue('timeLimitation')
  const onChangeTimeLimitation = () => {
    if (timeLimitationSwitch === TimeLimitation.NONE) {
      setTimeLimitationSwitch(TimeLimitation.UNTIL_1645)
      form.setFieldValue('timeLimitation', TimeLimitation.UNTIL_1645)
    } else {
      setTimeLimitationSwitch(TimeLimitation.NONE)
      form.setFieldValue('timeLimitation', TimeLimitation.NONE)
    }
  }
  // useEffect(() => {
  //   if (freezingValue) {
  //     setIsFreeze(prev => !prev)
  //   }
  // }, [freezingValue])

  // useEffect(() => {
  //   if (activationDaysValue) {
  //     setIsAutoActivate(prev => !prev)
  //   }
  // }, [activationDaysValue])

  useEffect(() => {
    setTimeLimitationSwitch(
      typeLimitationInput === TimeLimitation.UNTIL_1645 ? TimeLimitation.UNTIL_1645 : TimeLimitation.NONE
    )
  }, [typeLimitationInput])

  const [hideFromUserSwitch, setHideFromUserSwitch] = useState<boolean>(false)
  const hideFromUserInput = form.getFieldValue('hideFromUser')
  const onChangeHideFromUser = () => {
    setHideFromUserSwitch(prev => !prev)
    form.setFieldValue('hideFromUser', !hideFromUserInput)
  }
  const questionsValue = genProductsSubcriptionsQuestions

  const valueFormat = useWatch('variant', form)
  const [valueRadio, setValueRadio] = useState(valueFormat)
  const onChangeRadio = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value)
  }
  return (
    <Form
      form={form}
      name="serviceCreate"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      initialValues={genServicesInitialValues()}
      disabled={false}
    >
      <div className={`products-subcriptions-form__wrapper ${theme === 'light' ? 'light' : 'dark'}`}>
        <Col className="products-subcriptions-form__wrapper-basic">
          <Typography.Title level={4}>Основные настройки</Typography.Title>
          <Form.Item
            className="products-subcriptions-form__wrapper__paddingTop"
            label="Название абонемента"
            name="name"
            rules={[{ required: true, validator: validateProductsSubcriptionsNoSpacesOnly }]}
          >
            <Input size="large" placeholder="" />
          </Form.Item>
          <Form.Item
            className="products-subcriptions-form__wrapper__paddingTop"
            label="Стоимость"
            name="cost"
            rules={[
              { required: true, message: 'Введите стоимость', validator: validateProductsSubcriptionsNumberInput },
            ]}
          >
            <Input type="number" size="large" defaultValue={0} suffix="₽" />
          </Form.Item>
        </Col>
        <Col className="products-subcriptions-form__wrapper__paddingTop">
          <Typography.Title level={5}>Формат</Typography.Title>
          <Form.Item
            name="variant"
            className="products-subcriptions-form__wrapper__paddingTop"
            rules={[{ required: true, message: 'Выберите формат услуги' }]}
          >
            <Radio.Group
              disabled={disabledVariants}
              style={{ width: '100%' }}
              value={valueRadio}
              onChange={onChangeRadio}
            >
              <Space direction="vertical" style={{ width: '335px' }}>
                <Radio value="BY_VISITS">
                  <Flex gap={8}>
                    По количеству записей
                    <QuestionTooltip title={questionsValue[1]} />
                  </Flex>
                </Radio>
                {valueFormat === 'BY_VISITS' && (
                  <Col className="products-subcriptions-form__wrapper-card">
                    <Form.Item
                      name="visits"
                      label="Количество записей"
                      rules={[
                        {
                          required: true,
                          validator: validateProductsSubcriptionsNumberIntegerInput,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} size="large" defaultValue={0} />
                    </Form.Item>
                    <Form.Item
                      name="validityDays"
                      label="Срок действия"
                      style={{ paddingTop: 15 }}
                      rules={[
                        {
                          required: true,
                          validator: validateProductsSubcriptionsNumberIntegerInput,
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Введите дни"
                        style={{ width: '100%' }}
                        size="large"
                        defaultValue={0}
                        suffix="дн."
                      />
                    </Form.Item>
                  </Col>
                )}
                <Radio value="BY_TIME">
                  <Flex gap={8}>
                    По количеству часов
                    <QuestionTooltip title={questionsValue[2]} />
                  </Flex>
                </Radio>
                {valueFormat === 'BY_TIME' && (
                  <Col className="products-subcriptions-form__wrapper-card">
                    <Form.Item
                      name="minutes"
                      label="Укажите количество часов"
                      rules={[
                        {
                          required: true,
                          validator: validateProductsSubcriptionsNumberIntegerInput,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} size="large" defaultValue={0} />
                    </Form.Item>
                    <Form.Item
                      name="validityDays"
                      label="Срок действия"
                      style={{ paddingTop: 15 }}
                      rules={[
                        {
                          required: true,
                          validator: validateProductsSubcriptionsNumberIntegerInput,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        size="large"
                        defaultValue={0}
                        placeholder="Введите дни"
                        suffix="дн."
                      />
                    </Form.Item>
                  </Col>
                )}

                <Radio value="BY_DURATION">
                  <Flex gap={8}>
                    По длительности
                    <QuestionTooltip title={questionsValue[3]} />
                  </Flex>
                </Radio>
                {valueFormat === 'BY_DURATION' && (
                  <Col className="products-subcriptions-form__wrapper-card">
                    <Form.Item
                      name="validityDays"
                      label="Выберите срок действия"
                      rules={[
                        {
                          required: true,
                          message: 'Укажите срок действия',
                        },
                      ]}
                    >
                      <Select size="large" options={durationOptions} />
                    </Form.Item>
                  </Col>
                )}
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col className="products-subcriptions-form__wrapper__paddingTop">
          <Typography.Title level={4}>Условия использования</Typography.Title>
          <Form.Item style={{ paddingTop: 15 }} name="availableStudios" label="Локации">
            <Select size="large" placeholder="Все" options={studiosOptions} mode="multiple" labelInValue />
          </Form.Item>
          <Form.Item
            className="products-subcriptions-form__wrapper__paddingTop"
            name="availableTypes"
            label="Категория(и) записей"
            rules={[{ required: true, message: 'Выберите категорию записей' }]}
          >
            <Select
              size="large"
              mode="multiple"
              placeholder="Выберите категорию записей"
              options={filteredOptions?.map(option => {
                let icon
                if (option.format === 'PERSONAL') {
                  icon = <UserOutlined style={{ color: '#000' }} />
                } else {
                  icon = <TeamOutlined style={{ fontSize: 16, color: '#000' }} />
                }
                return {
                  value: option.value,
                  label: (
                    <Flex align="center" gap={6}>
                      <div>{icon}</div>
                      {/* @ts-ignore */}
                      <Typography>{option.label}</Typography>
                    </Flex>
                  ),
                }
              })}
              labelInValue
              filterOption={(input, option) =>
                option?.label.props.children[1].props.children.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          {filteredDirectionsOptions.length > 0 && (
            <Form.Item
              className="products-subcriptions-form__wrapper__paddingTop"
              name="availableDirections"
              label="Направления"
            >
              <Select size="large" placeholder="Все" options={filteredDirectionsOptions} mode="multiple" labelInValue />
            </Form.Item>
          )}
        </Col>
        <Col style={{ paddingTop: 20 }}>
          <Typography.Title level={4}>Дополнительные настройки</Typography.Title>
          <Col className="products-subcriptions-form__wrapper__paddingTop">
            <Flex gap={8} align="center">
              <Switch disabled value={isFreeze} onChange={onChangeFreeze} size="small" />
              <Typography style={{ cursor: 'pointer' }} onClick={onChangeFreeze}>
                Включить заморозку
              </Typography>
              <QuestionTooltip title={questionsValue[4]} />
            </Flex>
            {isFreeze && (
              <Col className="products-subcriptions-form__wrapper-card__switch">
                <Form.Item
                  name="freezingDays"
                  rules={[
                    {
                      required: true,
                      validator: validateProductsSubcriptionsNumberIntegerInput,
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} size="large" placeholder="Введите дни" suffix="дн." />
                </Form.Item>
              </Col>
            )}
          </Col>
          <Col className="products-subcriptions-form__wrapper__paddingTop">
            <Flex gap={8} align="center">
              <Switch disabled value={isAutoActivate} onChange={onChangeAutoActivate} size="small" />
              <Typography style={{ cursor: 'pointer' }} onClick={onChangeAutoActivate}>
                Автоматическая активация
              </Typography>
              <QuestionTooltip title={questionsValue[5]} />
            </Flex>
            {isAutoActivate && (
              <Col className="products-subcriptions-form__wrapper-card__switch">
                <Form.Item
                  name="activationDays"
                  rules={[
                    {
                      required: true,
                      validator: validateProductsSubcriptionsNumberIntegerInput,
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} size="large" placeholder="Введите дни" suffix="дн." />
                </Form.Item>
              </Col>
            )}
          </Col>
          <Flex gap={8} style={{ paddingTop: 10 }} align="center">
            <Form.Item name="hideFromUser">
              <Switch onChange={onChangeHideFromUser} value={hideFromUserSwitch} defaultChecked size="small" />
            </Form.Item>
            <Typography style={{ cursor: 'pointer' }} onClick={onChangeHideFromUser}>
              Только для внутренней продажи
            </Typography>
            <QuestionTooltip title={questionsValue[6]} />
          </Flex>
          <Flex gap={8} style={{ paddingTop: 10 }} align="flex-start">
            <Form.Item name="timeLimitation">
              <Switch
                checked={timeLimitationSwitch === TimeLimitation.UNTIL_1645}
                onChange={onChangeTimeLimitation}
                size="small"
              />
            </Form.Item>
            <Typography style={{ cursor: 'pointer' }} onClick={onChangeTimeLimitation}>
              Ограничение по времени посещения до <br></br> <span style={{ paddingRight: 8 }}>16:45</span>
              <QuestionTooltip title={questionsValue[7]} />
            </Typography>
          </Flex>
        </Col>
        <Button className="products-subcriptions-form__wrapper-button" type="primary" htmlType="submit">
          {submitText}
        </Button>
      </div>
    </Form>
  )
}
