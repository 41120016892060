import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { ContractsApi } from '../../../../api/types/contracts-api.types'
import { Nullable } from '../../../../types/lang.types'

export interface ContractsInfoPageContractsState {
  contracts: Nullable<Pagination<ContractsApi.Contracts[]>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ContractsInfoPageContractsState = {
  contracts: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: contractsInfoPageContractsActions, reducer: contractsInfoPageContractsReducer } = createSlice({
  name: '@@contracts-info-page-contracts',
  initialState,
  reducers: {
    createContract: (state: Draft<ContractsInfoPageContractsState>, _: PayloadAction<ContractsApi.ContractsDTO>) => {
      state.isLoaded = true
    },
    createContractSuccess: (state: Draft<ContractsInfoPageContractsState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    createContractError: (state: Draft<ContractsInfoPageContractsState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    paidContract: (
      state: Draft<ContractsInfoPageContractsState>,
      _: PayloadAction<{ id: string; data: ContractsApi.ContractPaidParams; counterpartyId: string }>
    ) => {
      state.isLoaded = true
    },
    paidContractSuccess: (state: Draft<ContractsInfoPageContractsState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    paidContractError: (state: Draft<ContractsInfoPageContractsState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    deleteContract: (
      state: Draft<ContractsInfoPageContractsState>,
      _: PayloadAction<{ id: string; counterpartyId: string }>
    ) => {
      state.isLoaded = true
    },
    deleteContractSuccess: (state: Draft<ContractsInfoPageContractsState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    deleteContractError: (state: Draft<ContractsInfoPageContractsState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
