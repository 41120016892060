import { useLocation, useRouteMatch } from 'react-router-dom'

import { genContractsInfoPageQueryParams, genContractsInfoPageUrlParams } from '../contracts-info-page.utils'
import {
  ContractsInfoPageParams,
  ContractsInfoPageQueryParams,
  ContractsInfoPageUrlParams,
} from '../contracts-info-page.types'

export function useContractsInfoPageParams(): ContractsInfoPageParams {
  const { params } = useRouteMatch<ContractsInfoPageUrlParams>()
  const { search } = useLocation<ContractsInfoPageQueryParams>()

  return { ...genContractsInfoPageUrlParams(params), ...genContractsInfoPageQueryParams(search) }
}
