import { all, spawn } from 'redux-saga/effects'

import { contractsInfoPageDescriptionSagas } from './contracts-info-page-description/contracts-info-page-description.sagas'
import { contractsInfoPageClientsSagas } from './contracts-info-page-clients/contracts-info-page-clients.sagas'
import { contractsInfoPageContractsSagas } from './contracts-info-page-contracts/contracts-info-page-contracts.sagas'

export function* contractsInfoPageSagas() {
  yield all(
    [contractsInfoPageDescriptionSagas, contractsInfoPageClientsSagas, contractsInfoPageContractsSagas].map(saga =>
      spawn(saga)
    )
  )
}
