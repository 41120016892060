import { Tab } from 'rc-tabs/lib/interface'

import { getStrEnumValues } from '../../../utils/enum.utils'
import { formatClientsEditPageSection } from '../../../format/text.format'
import { ClientsEditPageSection } from '../clients-edit-page.types'

export function genClientsEditPageMenuTabs(clientBookingReadPermission: boolean): Tab[] {
  return getStrEnumValues<ClientsEditPageSection>(ClientsEditPageSection)
    .filter((section: ClientsEditPageSection) => {
      if (!clientBookingReadPermission) {
        return section !== ClientsEditPageSection.BOOKINGS_ACTIVE && section !== ClientsEditPageSection.BOOKINGS_HISTORY
      }
      return true
    })
    .map(
      (section: ClientsEditPageSection): Tab => ({
        key: section,
        label: formatClientsEditPageSection(section),
      })
    )
}
