import { CloseOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import { SpaceSize } from 'antd/es/space'
import * as React from 'react'

interface Props {
  forbidRemove?: boolean
  space?: SpaceSize | [SpaceSize, SpaceSize]
  onEdit: () => void
  onRemove: () => void
  onHistory?: () => void
}

export const TableCellActions: React.FC<Props> = props => {
  const { forbidRemove, space = 'middle', onEdit, onRemove, onHistory } = props

  return (
    <Space size={space}>
      {onHistory && (
        <Tooltip title="Посмотреть историю изменений">
          <Button icon={<HistoryOutlined />} size="middle" onClick={onHistory} />
        </Tooltip>
      )}
      <Tooltip title="Редактировать">
        <Button icon={<EditOutlined />} size="middle" onClick={onEdit} />
      </Tooltip>
      <Tooltip title={!forbidRemove ? 'Удалить' : 'У вас нет разрешения на это действие'}>
        <Button icon={<CloseOutlined />} size="middle" danger onClick={onRemove} disabled={forbidRemove} />
      </Tooltip>
    </Space>
  )
}
