import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import * as H from 'history'

import { fileReducer } from './common/files/files.slice'
import { contractsInfoPageReducer } from './pages/contracts-info-page/contracts-info-page.reducer'
import { contractsPageReducer } from './pages/contracts-page/contracts-page.slice'
import { customFieldsSettingsReducer } from './common/custom-fields-settings/custom-fields-settings.slice'
import { studioEntityReducer } from './common/entity/studio/studio.slice'
import { terminalEntityReducer } from './common/entity/terminal/terminal.slice'
import { tillEntityReducer } from './common/entity/till/till.slice'
import { layoutReducer } from './common/layout/layout.slice'
import { modalReducer } from './common/modal/modal.slice'
import { peekReducer } from './common/peek/peek.slice'
import { clientsCreatePageReducer } from './pages/clients-create-page/clients-create-page.slice'
import { clientsEditPageReducer } from './pages/clients-edit-page/clients-edit-page.reducer'
import { clientsPageReducer } from './pages/clients-page/clients-page.slice'
import { customFieldsPageReducer } from './pages/custom-fields-page/custom-fields-page.slice'
import { directionsCreatePageReducer } from './pages/directions-create-page/directions-create-page.slice'
import { directionsEditPageReducer } from './pages/directions-edit-page/directions-edit-page.slice'
import { directionsPageReducer } from './pages/directions-page/directions-page.slice'
import { employeesCreatePageReducer } from './pages/employees-create-page/employees-create-page.slice'
import { employeesEditPageReducer } from './pages/employees-edit-page/employees-edit-page.slice'
import { employeesPageReducer } from './pages/employees-page/employees-page.reducer'
import { employeesScheduleCreateReducer } from './pages/employees/employees-schedule-create/employees-schedule-create.slice'
import { employeesScheduleEditReducer } from './pages/employees/employees-schedule-edit/employees-schedule-edit.slice'
import { employeesSchedulePeekHistoryReducer } from './pages/employees/employees-schedule-peek-history/employees-schedule-peek-history.slice'
import { employeesScheduleReducer } from './pages/employees/employees-schedule/employees-schedule.slice'
import { exercisesTypesCreatePageReducer } from './pages/exercises-types-create-page/exercises-types-create-page.slice'
import { exercisesTypesEditPageReducer } from './pages/exercises-types-edit-page/exercises-types-edit-page.slice'
import { exercisesTypesPageReducer } from './pages/exercises-types-page/exercises-types-page.slice'
import { franchisesCreatePageReducer } from './pages/franchises-create-page/franchises-create-page.slice'
import { franchisesEditPageReducer } from './pages/franchises-edit-page/franchises-edit-page.slice'
import { franchisesPageReducer } from './pages/franchises-page/franchises-page.slice'
import { goodsPageReducer } from './pages/goods-page/goods-page.reducer'
import { masterServicesCreatePageReducer } from './pages/master-services-create-page/master-services-create-page.slice'
import { masterServicesEditPageReducer } from './pages/master-services-edit-page/master-services-edit-page.slice'
import { masterServicesPageReducer } from './pages/master-services-page/master-services-page.reducer'
import { masterServicesWidgetCreatePageReducer } from './pages/master-services-widget-create-page/master-services-widget-create-page.slice'
import { positionsCreatePageReducer } from './pages/positions-create-page/positions-create-page.slice'
import { positionsEditPageReducer } from './pages/positions-edit-page/positions-edit-page.slice'
import { positionsPageReducer } from './pages/positions-page/positions.slice'
import { productsPageReducer } from './pages/products-page/products-page.reducer'
import { productsServicesCreatePageReducer } from './pages/products-services-create-page/products-services-create-page.slice'
import { productServicesEditPageReducer } from './pages/products-services-edit-page/products-services-edit-page.slice'
import { productsSubscriptionsEditPageReducer } from './pages/products-subscriptions-edit-page/products-subscriptions-edit-page.slice'
import { productsSubscriptionsCreatePageReducer } from './pages/products-subscriptions-сreate-page/products-subscriptions-сreate-page.slice'
import { salariesPageReducer } from './pages/salaries-page/salaries-page.slice'
import { scheduleLongtermPageReducer } from './pages/schedule-longterm-page/schedule-longterm-page.reducer'
import { scheduleGroupPageReducer } from './pages/schedule-group-page/schedule-group-page.reducer'
import { scheduleManagementPageReducer } from './pages/schedule-management-page/schedule-management-page.reducer'
import { schedulePageReducer } from './pages/schedule-page/schedule-page.reducer'
import { studiosCreatePageReducer } from './pages/studios-create-page/studios-create-page.slice'
import { studioEditPageReducer } from './pages/studios-edit-page/studios-edit-page.slice'
import { studiosPageReducer } from './pages/studios-page/studios-page.reducer'
import { transactionsCreatePageReducer } from './pages/transactions-create-page/transactions-create-page.reducer'
import { transactionsPageReducer } from './pages/transactions-page/transactions-page.reducer'
import { websocketReducer } from './common/websocket/websocket.reducer'
import { permissionsReducer } from './common/permissions/permissions.slice'

export const createAppReducer = (history: H.History) => {
  return combineReducers({
    // Environment
    router: connectRouter(history),
    modal: modalReducer,
    peek: peekReducer,
    layout: layoutReducer,
    websocket: websocketReducer,
    permissions: permissionsReducer,

    // Common
    studioEntity: studioEntityReducer,
    terminalEntity: terminalEntityReducer,
    tillEntity: tillEntityReducer,

    // Franchises
    franchisesPage: franchisesPageReducer,
    franchisesCreatePage: franchisesCreatePageReducer,
    franchisesEditPage: franchisesEditPageReducer,

    // Directions
    directionsPage: directionsPageReducer,
    directionsCreatePage: directionsCreatePageReducer,
    directionsEditPage: directionsEditPageReducer,

    // Exercises
    exercisesTypesPage: exercisesTypesPageReducer,
    exercisesTypesCreatePage: exercisesTypesCreatePageReducer,
    exercisesTypesEditPage: exercisesTypesEditPageReducer,

    // Employees
    employeesPage: employeesPageReducer,
    employeesSchedule: employeesScheduleReducer,
    employeesScheduleCreate: employeesScheduleCreateReducer,
    employeesScheduleEdit: employeesScheduleEditReducer,
    employeesSchedulePeekHistory: employeesSchedulePeekHistoryReducer,
    employeesCreatePage: employeesCreatePageReducer,
    employeesEditPage: employeesEditPageReducer,

    // Studios
    studiosPage: studiosPageReducer,
    studiosCreatePage: studiosCreatePageReducer,
    studioEditPage: studioEditPageReducer,

    // Salaries
    salariesPage: salariesPageReducer,

    // Contracts
    contractsPage: contractsPageReducer,
    contractsInfoPage: contractsInfoPageReducer,

    // Products
    productsPageReducer: productsPageReducer,
    productsCreatePageReducer: productsServicesCreatePageReducer,
    productsEditPageReducer: productServicesEditPageReducer,
    productsSubscriptionsCreatePage: productsSubscriptionsCreatePageReducer,
    productsSubscriptionsEditPage: productsSubscriptionsEditPageReducer,

    // Goods
    goodsPageReducer: goodsPageReducer,

    // Schedule
    schedulePage: schedulePageReducer,
    scheduleManagementPage: scheduleManagementPageReducer,
    scheduleLongtermPage: scheduleLongtermPageReducer,
    scheduleGroupPage: scheduleGroupPageReducer,

    // Clients
    clientsPage: clientsPageReducer,
    clientsCreatePage: clientsCreatePageReducer,
    clientsEditPage: clientsEditPageReducer,

    // Transactions
    transactionsPage: transactionsPageReducer,
    transactionsCreatePage: transactionsCreatePageReducer,

    // Master Services
    masterServicesPage: masterServicesPageReducer,
    masterServicesCreatePage: masterServicesCreatePageReducer,
    masterServicesEditPage: masterServicesEditPageReducer,
    masterServicesWidgetCreatePage: masterServicesWidgetCreatePageReducer,

    // Positions
    positionsPage: positionsPageReducer,
    positionsCreatePage: positionsCreatePageReducer,
    positionsEditPage: positionsEditPageReducer,

    // Custom Fields
    customFieldsPage: customFieldsPageReducer,
    customFieldsSettings: customFieldsSettingsReducer,

    // Files
    files: fileReducer,
  })
}
