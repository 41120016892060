import React from 'react'
import { useParams } from 'react-router-dom'

import { ContractsPageInfoParams } from './contracts-info-page.types'
import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { useContractsInfoPage } from './contracts-info-page.hook'
import { ContractsInfoHeader } from './contracts-info-page-header/contracts-info-page-header.component'
import { ContractsInfoTabs } from './contracts-info-page-tabs/contracts-info-page-tabs.component'

export const ContractsPageInfo = () => {
  const { id } = useParams<ContractsPageInfoParams>()
  const { isLoading } = useContractsInfoPage(id)

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <ContractsInfoHeader />
      <ContractsInfoTabs />
    </>
  )
}
