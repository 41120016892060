import React from 'react'
import { Flex } from 'antd'

import style from './contracts-tabs-container.module.scss'

interface ContractsTabsContainerProps {
  children: React.ReactNode
}

export const ContractsTabsContainer = ({ children }: ContractsTabsContainerProps) => {
  return (
    <Flex vertical gap={'large'} className={style.tabsContainer}>
      {children}
    </Flex>
  )
}
