import * as React from 'react'
import { Table, TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { genScheduleTableColumns } from './schedule-table.utils'
import { CustomCard } from '../../custom-card/custom-card.component'
import { ScheduleTableDataItem, ScheduleTableEvents } from './schedule-table.types'
import './schedule-table.styles.less'

interface Props extends ScheduleTableEvents {
  data: ScheduleTableDataItem[]
  loading?: boolean
  timetableDeletePermission: boolean
  pagination?: TablePaginationConfig
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
}

export const ScheduleTable: React.FC<Props> = props => {
  const { data, loading, timetableDeletePermission, pagination } = props
  const { onEdit, onRemove, onHistory, onChangePage, onChangePageSize } = props

  const columns: ColumnsType<ScheduleTableDataItem> = React.useMemo(
    () => genScheduleTableColumns(timetableDeletePermission, { onEdit, onRemove, onHistory }),
    [timetableDeletePermission, onEdit, onRemove, onHistory]
  )
  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      position: ['bottomCenter'],
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  return (
    <CustomCard>
      <Table
        className="table schedule-table"
        rowKey="id"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={paginationConfig}
      />
    </CustomCard>
  )
}
