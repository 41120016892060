export function genMasterServicesWidgetInitialValues(masterServiceId: string) {
  return {
    general: {
      masterServiceId: masterServiceId,
      bookingDays: 14,
      countryCode: '7',
      language: 'ru',
      currency: 'RUB',
      staticWidgetMode: true,
      userContacts: {
        requestFirstName: true,
        firstNameRequired: false,
        requestLastName: false,
        lastNameRequired: false,
      },
      vocabulary: {
        master: 'Мастер',
        place: 'Локация',
        service: 'Услуга',
      },
    },
    design: {
      accentColor: '#F8873D',
      secondColor: '#F5F5F7',
      loaderColor: '#F8873D',
      formStyles: {
        desktop: {
          width: '600px',
          height: 'auto',
          backgroundColor: '#FFF',
          borderRadius: {
            topLeft: 20,
            topRight: 20,
            bottomRight: 20,
            bottomLeft: 20,
          },
        },
        mobile: {
          backgroundColor: '#FFF',
          borderRadius: {
            topLeft: 15,
            topRight: 15,
            bottomRight: 0,
            bottomLeft: 0,
          },
        },
      },
      stepsStyles: {
        borderRadius: {
          topLeft: 20,
          topRight: 20,
          bottomRight: 20,
          bottomLeft: 20,
        },
        backgroundColor: '#FFF',
        default: {
          iconColor: '#A9AAAD',
          backgroundColor: '#F5F5F7',
        },
        hover: {
          backgroundColor: '#E6E6E7',
          iconColor: '#A9AAAD',
        },
        active: {
          backgroundColor: '#F8873D',
        },
      },
      buttonStyles: {
        buttonBack: {
          default: {
            backgroundColor: '#F5F5F7',
            iconColor: '#1D1D1F',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#F5F5F7',
            },
            borderRadius: {
              topLeft: 10,
              topRight: 10,
              bottomRight: 10,
              bottomLeft: 10,
            },
          },
          hover: {
            backgroundColor: '#E6E6E7',
            iconColor: '#1D1D1F',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#E6E6E7',
            },
            borderRadius: {
              topLeft: 10,
              topRight: 10,
              bottomRight: 10,
              bottomLeft: 10,
            },
          },
        },
        buttonFillColor: {
          default: {
            backgroundColor: '#03010D',
            buttonBackgroundColor: '#FFF',
            iconColor: '#FFF',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#03010D',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
          hover: {
            fontColor: '#03010D',
            backgroundColor: 'transparent',
            iconColor: '#03010D',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#03010D',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
        },
        buttonOutline: {
          default: {
            backgroundColor: 'transparent',
            iconColor: '#333333',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#03010D',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
          hover: {
            backgroundColor: '#03010D',
            iconColor: '#FFFFFF',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#03010D',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
        },
        buttonAddService: {
          default: {
            iconColor: '#333333',
            backgroundColor: '#FFF',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#FFF',
            },
          },
          hover: {
            iconColor: '#333333',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: 'rgba(255, 255, 255, 0.5)',
            },
          },
        },
        buttonContinue: {
          default: {
            backgroundColor: '#03010D',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#03010D',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
          hover: {
            backgroundColor: '#03010D',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#03010D',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
        },
        buttonSelectStudio: {
          default: {
            backgroundColor: '#FFFFFF',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#FFFFFF',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
          hover: {
            backgroundColor: 'transparent',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#FFFFFF',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
        },
        buttonAboutStudio: {
          default: {
            backgroundColor: 'transparent',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#FFFFFF',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
          hover: {
            backgroundColor: '#FFF',
            border: {
              top: 1,
              right: 1,
              bottom: 1,
              left: 1,
              color: '#FFFFFF',
            },
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomRight: 5,
              bottomLeft: 5,
            },
          },
        },
      },
      inputStyles: {
        default: {
          backgroundColor: '#FFF',
          iconColor: '#C6C6CB',
          errorColor: '#EF4444',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#FFF',
          },
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomRight: 5,
            bottomLeft: 5,
          },
        },
        hover: {
          backgroundColor: '#FFF',
          iconColor: '#C6C6CB',
          errorColor: '#EF4444',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#F8873D',
          },
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomRight: 5,
            bottomLeft: 5,
          },
        },
      },
      searchInputStyles: {
        default: {
          backgroundColor: '#F5F5F7',
          iconColor: '#A9AAAD',
          errorColor: '#EF4444',
          placeholderColor: '#787779',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#F5F5F7',
          },
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomRight: 5,
            bottomLeft: 5,
          },
        },
        hover: {
          backgroundColor: '#F5F5F7',
          iconColor: '#A9AAAD',
          errorColor: '#EF4444',
          placeholderColor: '#787779',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#F8873D',
          },
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomRight: 5,
            bottomLeft: 5,
          },
        },
      },
      confirmInputStyles: {
        default: {
          backgroundColor: '#FFF',
          iconColor: '#C6C6CB',
          errorColor: '#EF4444',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#FFF',
          },
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomRight: 5,
            bottomLeft: 5,
          },
        },
        hover: {
          backgroundColor: '#FFF',
          iconColor: '#C6C6CB',
          errorColor: '#EF4444',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#F8873D',
          },
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomRight: 5,
            bottomLeft: 5,
          },
        },
      },
      serviceStyles: {
        default: {
          backgroundColor: '#F5F5F7',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#F5F5F7',
          },
          borderRadius: {
            topLeft: 10,
            topRight: 10,
            bottomRight: 10,
            bottomLeft: 10,
          },
        },
        hover: {
          backgroundColor: '#F5F5F7',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#F5F5F7',
          },
          borderRadius: {
            topLeft: 10,
            topRight: 10,
            bottomRight: 10,
            bottomLeft: 10,
          },
        },
        active: {
          backgroundColor: '#F8873D',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#F8873D',
          },
          borderRadius: {
            topLeft: 10,
            topRight: 10,
            bottomRight: 10,
            bottomLeft: 10,
          },
        },
      },
      serviceCategoriesStyles: {
        default: {
          backgroundColor: '#F5F5F7',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#F5F5F7',
          },
          borderRadius: {
            topLeft: 120,
            topRight: 120,
            bottomRight: 120,
            bottomLeft: 120,
          },
        },
        hover: {
          backgroundColor: '#03010D',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#03010D',
          },
          borderRadius: {
            topLeft: 120,
            topRight: 120,
            bottomRight: 120,
            bottomLeft: 120,
          },
        },
        active: {
          backgroundColor: '#03010D',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#03010D',
          },
          borderRadius: {
            topLeft: 120,
            topRight: 120,
            bottomRight: 120,
            bottomLeft: 120,
          },
        },
      },
      switcherStyles: {
        backgroundColor: '#F5F5F7',
        active: {
          buttonBackgroundColor: '#03010D',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#03010D',
          },
          borderRadius: {
            topLeft: 30,
            topRight: 30,
            bottomRight: 30,
            bottomLeft: 30,
          },
        },
        hover: {
          buttonBackgroundColor: '#FFF',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: '#FFF',
          },
          borderRadius: {
            topLeft: 30,
            topRight: 30,
            bottomRight: 30,
            bottomLeft: 30,
          },
        },
      },
      studioCardStyles: {
        default: {
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: 'transparent',
          },
          borderRadius: {
            topLeft: 10,
            topRight: 10,
            bottomRight: 10,
            bottomLeft: 10,
          },
        },
        hover: {
          shadeColor: 'rgba(29, 29, 31, 0.80)',
          border: {
            top: 1,
            right: 1,
            bottom: 1,
            left: 1,
            color: 'transparent',
          },
          borderRadius: {
            topLeft: 10,
            topRight: 10,
            bottomRight: 10,
            bottomLeft: 10,
          },
        },
      },
      datePickerStyles: {
        arrowColor: '#03010D',
        default: {
          backgroundColor: 'transparent',
          border: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            color: 'transparent',
          },
          borderRadius: {
            topLeft: 50,
            topRight: 50,
            bottomRight: 50,
            bottomLeft: 50,
          },
        },
        hover: {
          backgroundColor: '#F5F5F7',
          border: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            color: '#F5F5F7',
          },
          borderRadius: {
            topLeft: 50,
            topRight: 50,
            bottomRight: 50,
            bottomLeft: 50,
          },
        },
        active: {
          backgroundColor: '#03010D',
          border: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            color: '#03010D',
          },
          borderRadius: {
            topLeft: 50,
            topRight: 50,
            bottomRight: 50,
            bottomLeft: 50,
          },
        },
      },
      timeSlotStyles: {
        default: {
          backgroundColor: '#F5F5F7',
          border: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            color: '#F5F5F7',
          },
          borderRadius: {
            topLeft: 40,
            topRight: 40,
            bottomRight: 40,
            bottomLeft: 40,
          },
        },
        hover: {
          backgroundColor: '#F8873D',
          border: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            color: '#F8873D',
          },
          borderRadius: {
            topLeft: 40,
            topRight: 40,
            bottomRight: 40,
            bottomLeft: 40,
          },
        },
        active: {
          backgroundColor: '#F8873D',
          border: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            color: '#F8873D',
          },
          borderRadius: {
            topLeft: 40,
            topRight: 40,
            bottomRight: 40,
            bottomLeft: 40,
          },
        },
      },
      executorBlockStyles: {
        backgroundColor: '#F5F5F7',
        iconsColor: '#F8873D',
        borderRadius: {
          topLeft: 10,
          topRight: 10,
          bottomRight: 10,
          bottomLeft: 10,
        },
        border: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          color: '#F5F5F7',
        },
        timeSlot: {
          default: {
            backgroundColor: '#FFF',
            border: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              color: '#FFF',
            },
            borderRadius: {
              topLeft: 120,
              topRight: 120,
              bottomRight: 120,
              bottomLeft: 120,
            },
          },
          hover: {
            backgroundColor: '#F8873D',
            border: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              color: '#F8873D',
            },
            borderRadius: {
              topLeft: 120,
              topRight: 120,
              bottomRight: 120,
              bottomLeft: 120,
            },
          },
          active: {
            backgroundColor: '#F8873D',
            border: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              color: '#F8873D',
            },
            borderRadius: {
              topLeft: 120,
              topRight: 120,
              bottomRight: 120,
              bottomLeft: 120,
            },
          },
        },
      },
      aboutExecutorStyles: {
        backgroundColor: '#F5F5F7',
        tagBackgroundColor: '#FFF',
        border: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          color: '#F5F5F7',
        },
        borderRadius: {
          topLeft: 10,
          topRight: 10,
          bottomRight: 10,
          bottomLeft: 10,
        },
      },
    },
  }
}
