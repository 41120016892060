import React from 'react'
import { useParams } from 'react-router-dom'

import { ContractsClientsTable } from '../../../../components/contracts/contracts-clients-table/contracts-clients-table.component'
import { ContractsClientsHeader } from './contracts-info-clients-header/contracts-info-clients-header.component'
import { ContractsPageInfoParams } from '../../contracts-info-page.types'
import { useClientsTabs } from './contracts-info-page-clients.hook'
import { ContractsTabsContainer } from '../../../../components/contracts/contracts-tabs-container/contracts-tabs-container.component'

export const ClientsTabs = () => {
  const { id } = useParams<ContractsPageInfoParams>()
  const {
    clients,
    isLoading,
    onInfoHandler,
    pagination,
    onChangePageSizeHandler,
    onChangePageHandler,
    onRemoveHandler,
  } = useClientsTabs(id)

  return (
    <ContractsTabsContainer>
      <ContractsClientsHeader id={id} />
      <ContractsClientsTable
        isLoading={isLoading}
        clients={clients}
        pagination={pagination}
        onChangePageSize={onChangePageSizeHandler}
        onChangePage={onChangePageHandler}
        onInfo={onInfoHandler}
        onRemove={onRemoveHandler}
      />
    </ContractsTabsContainer>
  )
}
