import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import { getContractPaymentMethod } from '../../../../../mapping/contracts.mapping'
import { getFileLink } from '../../../../../store/common/files/files.selectors'
import { getManagersCardsList } from '../../../../../store/pages/contracts-page/contracts-page.selectors'
import { modalActions } from '../../../../../store/common/modal/modal.slice'
import { AppModal } from '../../../../../types/modal.types'
import { contractsInfoPageContractsActions } from '../../../../../store/pages/contracts-info-page/contracts-info-page-contracts/contracts-info-page-contracts.slice'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'

export const useContractsInfoContract = (id: string) => {
  const dispatch = useDispatch()
  const managers = useSelector(getManagersCardsList)
  const link = useSelector(getFileLink)

  const onCreateContract = React.useCallback(
    (value: ContractsApi.ContractsDTO) => {
      const paymentModel = getContractPaymentMethod(value)
      dispatch(contractsInfoPageContractsActions.createContract({ ...value, link, paymentModel, counterpartyId: id }))
    },
    [dispatch, id, link]
  )

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onCreateContractHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.CONTRACTS_INFO_PAGE_CONTRACT_MODAL_CREATE,
        props: {
          handleOk: onCreateContract,
          handleCancel: onCloseHandler,
          managers: managers,
        },
      })
    )
  }, [dispatch, managers, onCloseHandler, onCreateContract])

  return {
    onCreateContractHandler,
  }
}
