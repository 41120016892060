import { put, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { RouterState } from 'connected-react-router'

import { modalActions } from '../../../common/modal/modal.slice'
import { getRouterState } from '../../../common/router/router.selectors'
import { genContractsInfoPageQueryParams } from '../../../../pages/contracts-info-page/contracts-info-page.utils'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { callApi } from '../../../../utils/sagas.utils'
import { contractsInfoPageClientsActions } from './contracts-info-page-clients.slice'
import { api } from '../../../../api/api'

export function* fetchAllClients(action: ReturnType<typeof contractsInfoPageClientsActions.fetchAllClients>) {
  try {
    const { id, size, page } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.contractsService.fetchAllClients>> = yield callApi(
      api.contractsService.fetchAllClients,
      id,
      params
    )

    yield put(contractsInfoPageClientsActions.fetchAllClientsSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageClientsActions.fetchAllClientsError(new Error()))
  }
}

export function* removeClient(action: ReturnType<typeof contractsInfoPageClientsActions.removeClient>) {
  try {
    const { counterpartyId, clientId } = action.payload

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genContractsInfoPageQueryParams(search)

    yield callApi(api.contractsService.removeClient, counterpartyId, clientId)
    yield put(contractsInfoPageClientsActions.removeClientSuccess())
    yield put(contractsInfoPageClientsActions.fetchAllClients({ id: counterpartyId, ...params }))
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageClientsActions.removeClientError(new Error()))
  }
}

export function* createClientCounterparty(
  action: ReturnType<typeof contractsInfoPageClientsActions.createClientCounterparty>
) {
  try {
    const { counterpartyId, clientPhone } = action.payload

    const { location }: RouterState = yield select(getRouterState)
    const { search } = location
    const params = genContractsInfoPageQueryParams(search)

    yield callApi(api.contractsService.createClientCounterparty, counterpartyId, clientPhone)
    yield put(contractsInfoPageClientsActions.createClientCounterpartySuccess())
    yield put(contractsInfoPageClientsActions.fetchAllClients({ id: counterpartyId, ...params }))
    yield put(modalActions.closeLast())

    yield notification.success({
      message: 'Клиент добавлен',
    })
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageClientsActions.createClientCounterpartyError(new Error()))
  }
}

export function* contractsInfoPageClientsSagas() {
  yield takeLatest(contractsInfoPageClientsActions.fetchAllClients, fetchAllClients)
  yield takeLatest(contractsInfoPageClientsActions.createClientCounterparty, createClientCounterparty)
  yield takeLatest(contractsInfoPageClientsActions.removeClient, removeClient)
}
