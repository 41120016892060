import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { contractsInfoPageClientsActions } from '../../../../../store/pages/contracts-info-page/contracts-info-page-clients/contracts-info-page-clients.slice'
import { modalActions } from '../../../../../store/common/modal/modal.slice'
import { AppModal } from '../../../../../types/modal.types'
import { getClientsIsLoading } from '../../../../../store/pages/contracts-info-page/contracts-info-page-clients/contracts-info-page-clients.selector'

export const useContractsClientsHeader = (id: string) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getClientsIsLoading)

  const onCreateClient = React.useCallback(
    (value: { clientPhone: string }) => {
      dispatch(contractsInfoPageClientsActions.createClientCounterparty({ counterpartyId: id, clientPhone: value }))
    },
    [dispatch, id]
  )

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onCreateClientHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.CONTRACTS_INFO_PAGE_CLIENT_MODAL_CREATE,
        props: {
          isLoading: isLoading,
          handleOk: onCreateClient,
          handleCancel: onCloseHandler,
        },
      })
    )
  }, [dispatch, isLoading, onCloseHandler, onCreateClient])

  return {
    onCreateClientHandler,
  }
}
