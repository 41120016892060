import * as React from 'react'
import { Divider, Flex, Modal } from 'antd'
import clsx from 'clsx'

import { ScheduleModalProps } from './schedule-modal.types'
import './schedule-modal.styles.less'

export const ScheduleModal: React.FC<React.PropsWithChildren<ScheduleModalProps>> = props => {
  const { footer, title, width = 704, customClassName, maskClosable = true, onCancel } = props
  const { children } = props

  return (
    <Modal
      className={clsx('schedule-modal', customClassName)}
      title={title}
      onCancel={onCancel}
      open={true}
      width={width}
      footer={footer}
      maskClosable={maskClosable}
    >
      <Flex vertical gap={16}>
        <Divider className="schedule-modal__divider" />
        {children}
      </Flex>
    </Modal>
  )
}
