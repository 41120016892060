import { notification } from 'antd'
import { put, select, take, takeLatest } from 'redux-saga/effects'

import { apiV2 } from '../../../../api/api-v2'
import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { ScheduleModalConflictsAction } from '../../../../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts.types'
import { ScheduleOverview } from '../../../../components/schedule/schedule-overview/schedule-overview.types'
import { genScheduleExercisesAddDTO, getTotalExercises } from '../../../../mapping/exercises-timetable.mapping'
import { isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'
import { AppModal } from '../../../../types/modal.types'
import { callApi } from '../../../../utils/sagas.utils'
import { modalActions } from '../../../common/modal/modal.slice'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import {
  genScheduleLongtermPagePeekAboutBookingClients,
  genScheduleLongtermPagePeekAboutSchedule,
} from '../schedule-longterm-page-peek-about/schedule-longterm-page-peek-about.selectors'
import { scheduleLongtermPageModalAddActions } from './schedule-longterm-page-modal-add.slice'

function* addExercises(action: ReturnType<typeof scheduleLongtermPageModalAddActions.addExercises>) {
  const { studioId, scheduleId, scheduleFormValues } = action.payload

  const schedule: Nullable<ScheduleOverview> = yield select(genScheduleLongtermPagePeekAboutSchedule)
  const bookingClients: ExercisesTimetableApi.ExercisesTimetableBookingClient[] = yield select(
    genScheduleLongtermPagePeekAboutBookingClients
  )

  const exercisesDTO = genScheduleExercisesAddDTO(scheduleFormValues)
  const { timeslots, dateFrom, dateTo } = exercisesDTO

  const totalExercises = getTotalExercises(dateFrom, dateTo, exercisesDTO.timeslots)

  if (schedule && totalExercises > 0) {
    yield put(websocketTimetableActions.connect(scheduleId))

    try {
      yield take(websocketTimetableActions.connectionSuccess.type)

      const { direction, type, client } = schedule

      if (direction && type) {
        yield callApi(apiV2.exercisesTimetableService.addExercisesByTimetableId, scheduleId, exercisesDTO)
        yield put(scheduleLongtermPageModalAddActions.addExercisesSuccess())
        yield put(modalActions.closeLast())
        yield put(
          modalActions.show({
            modal: AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_PROGRESS,
            props: {
              studioId,
              scheduleId,
              action: ScheduleModalConflictsAction.ADD,
              totalExercises,
              totalBookingClients: isDefAndNotEmpty(bookingClients) ? bookingClients.length : 0,
              createdExercises: {
                directionId: direction.id,
                typeId: type.id,
                timeslots,
                phone: client?.phone,
              },
            },
          })
        )
      } else {
        yield put(scheduleLongtermPageModalAddActions.addExercisesError(new Error()))
      }
    } catch (e) {
      console.error(e)
      yield put(scheduleLongtermPageModalAddActions.addExercisesError(new Error()))
    }
  } else {
    yield put(scheduleLongtermPageModalAddActions.addExercisesError(new Error()))
    notification.error({
      message: 'Не будет создано ни одного занятия',
    })
  }
}

export function* scheduleLongtermPageModalAddSagas() {
  yield takeLatest(scheduleLongtermPageModalAddActions.addExercises, addExercises)
}
