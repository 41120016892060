import { HistoryOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons'
import { Tooltip, Button } from 'antd'
import * as React from 'react'

import { ScheduleTableActionsEvents } from './schedule-table-actions.types'
import './schedule-table-actions.styles.less'

interface Props extends ScheduleTableActionsEvents {
  id: string
  timetableDeletePermission: boolean
}

export const ScheduleTableActions: React.FC<Props> = props => {
  const { id, timetableDeletePermission } = props
  const { onEdit, onRemove, onHistory } = props

  const onEditHandler = React.useCallback(() => onEdit(id), [id, onEdit])

  const onRemoveHandler = React.useCallback(() => {
    onRemove(id)
  }, [id, onRemove])

  const onHistoryHandler = React.useCallback(() => onHistory(id), [id, onHistory])

  return (
    <div className="schedule-table-actions">
      <Tooltip title="Посмотреть историю изменений">
        <Button icon={<HistoryOutlined />} size="middle" onClick={onHistoryHandler} />
      </Tooltip>
      <Tooltip title="Редактировать">
        <Button icon={<EditOutlined />} size="middle" onClick={onEditHandler} />
      </Tooltip>
      <Tooltip title={timetableDeletePermission ? 'Удалить' : 'У вас нет разрешения на это действие'}>
        <Button
          icon={<CloseOutlined />}
          size="middle"
          danger
          disabled={!timetableDeletePermission}
          onClick={onRemoveHandler}
        />
      </Tooltip>
    </div>
  )
}
