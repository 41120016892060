import { formatNameColorsToHex } from '@utils/colors.utils'

export enum TAG_TYPES {
  ALL_ROOMS = 'ALL_ROOMS',
  ALL_STUDIOS = 'ALL_STUDIOS',
  ONE_ROOM = 'ONE_ROOM',
}

export enum TAG_TEXTS {
  ALL_ROOMS = 'Все пространства',
  ALL_STUDIOS = 'Все локации',
}

export const TAG_COLORS = {
  ALL_STUDIOS: formatNameColorsToHex('orange', 3),
  ALL_STUDIOS_ACTIVE: formatNameColorsToHex('orange', 5),
  ALL_STUDIOS_DETAILS: formatNameColorsToHex('orange', 1),
  ALL_ROOMS: formatNameColorsToHex('red', 3),
  ALL_ROOMS_ACTIVE: formatNameColorsToHex('red', 5),
  ALL_ROOMS_DETAILS: formatNameColorsToHex('red', 1),
}
