import { DefaultOptionType } from 'antd/lib/select'

import { formatRublesToPennies } from '../format/number.format'
import { ContractsClientTableRow } from '../components/contracts/contracts-clients-table/contracts-clients-table.types'
import { DEFAULT_EMPTY_SYMBOL, formatFullName } from '../format/text.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { EmployeesApi } from '../api/types/employees-api.types'
import { ContractsApi } from '../api/types/contracts-api.types'

export function mapCounterpartiesToCardList(organizations: Nullable<ContractsApi.Counterparties[]>) {
  if (isDefAndNotEmpty(organizations)) {
    return organizations.map((counterparties: ContractsApi.Counterparties) => ({
      id: counterparties.id,
      bank: counterparties.bankName,
      directorName: counterparties.directorName,
      company: counterparties.companyName,
      telephone: counterparties.phoneNumber?.replace(/\D/g, ''),
      activeContracts: counterparties.activeContracts,
      email: counterparties.email,
      clientCount: counterparties.clientCount,
    }))
  }

  return null
}

export function mapManagersToDropdown(managers: Nullable<EmployeesApi.Employee[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(managers)) {
    return managers.map((manager: EmployeesApi.Employee): DefaultOptionType => {
      const { firstName, lastName, id } = manager

      const fullName = formatFullName(firstName, lastName)

      return {
        value: id,
        label: `Менеджер: ${fullName}`,
      }
    })
  }
}

export function mapClientsToClientsTable(
  clients: Nullable<ContractsApi.CounterpartiesClient[]>
): Nullable<ContractsClientTableRow[]> {
  if (isDefAndNotEmpty(clients)) {
    return clients.map(
      (client: ContractsApi.CounterpartiesClient): ContractsClientTableRow => ({
        id: client.id,
        phone: client.phone,
        name: client.firstName
          ? client.lastName
            ? `${client.firstName} ${client.lastName}`
            : client.firstName
          : DEFAULT_EMPTY_SYMBOL,
        photo: client.photo,
        lastVisit: client.lastVisit,
        category: client.category,
        turnover: client.turnover,
      })
    )
  }

  return null
}

export function getCounterpartiesDTO(data: ContractsApi.CounterpartiesDTO): Nullable<ContractsApi.CounterpartiesDTO> {
  if (isDef(data)) {
    return {
      companyName: data.companyName,
      displayName: data.companyName,
      ogrn: data.ogrn,
      inn: data.inn,
      kpp: data.kpp,
      snoType: data.snoType,
      address: data.address,
      directorName: data.directorName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      bankName: data.bankName,
      bic: data.bic,
      bankAccount: data.bankAccount,
      correspondentAccount: data.correspondentAccount,
    }
  }

  return null
}

export function getContractDTO(data: ContractsApi.ContractsDTO, link: string): Nullable<ContractsApi.ContractsDTO> {
  if (isDef(data)) {
    return {
      name: data.name,
      link,
      number: data.name,
      validSince: data.validSince,
      validUntil: data.validUntil,
      type: data.type,
      paymentModel: data.paymentModel,
      maxTotalAmount: data.maxTotalAmount,
      maxPeriodAmount: data.maxPeriodAmount || null,
      disablePaymentsOnMaxAmount: data.disablePaymentsOnMaxAmount,
      disablePaymentsAfterPaymentDue: data.disablePaymentsAfterPaymentDue || true,
      counterpartyId: data.counterpartyId,
      managerId: data.managerId,
      notifyDaysBeforeExpire: data.notifyDaysBeforeExpire || 0,
    }
  }

  return null
}

export function getContractPaymentMethod(data: any): any {
  const { paymentModel } = data

  if (paymentModel === 'ONE_TIME') {
    return {
      type: paymentModel,
    }
  } else if (paymentModel === 'PERIODIC') {
    return {
      type: paymentModel,
      months: data.months,
      day: data.day,
    }
  } else if (paymentModel === 'VALUE_SPENT') {
    return {
      type: paymentModel,
      value: formatRublesToPennies(data.value),
    }
  } else if (paymentModel === 'VALUE_LEFT') {
    return {
      type: paymentModel,
      value: formatRublesToPennies(data.value),
    }
  }
}
