import React from 'react'
import { Breadcrumb, Flex, Typography } from 'antd'
import { Link } from 'react-router-dom'

import { useCalculateContractBalance } from '../../../pages/contracts-page/contracts-card/contracts-card.utils'
import { formatDateToHumanize } from '../../../format/date.format'
import { useContractsInfoHeader } from './contracts-info-page-header.hook'
import { formatRubleCurrency } from '../../../format/number.format'
import { CardStatistic } from '../../../components/contracts/contracts-card-statistic/contracts-card-statistic.component'
import style from './contracts-info-page-header.module.scss'

export const ContractsInfoHeader = () => {
  const { counterparty } = useContractsInfoHeader()
  const expiresDate = formatDateToHumanize(counterparty?.activeContracts?.[0]?.validUntil || '2024-12-31')
  const turnover = formatRubleCurrency(counterparty?.activeContracts?.[0]?.currentTurnover || 0)
  const { balance } = useCalculateContractBalance(
    counterparty?.activeContracts?.[0]?.maxTotalAmount || 0,
    counterparty?.activeContracts?.[0]?.type || 'PREPAY',
    counterparty?.activeContracts?.[0]?.currentTurnover || 0
  )

  return (
    <Flex vertical gap={'large'}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/contracts">Контрагенты</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>карточка {counterparty?.companyName}</Breadcrumb.Item>
      </Breadcrumb>
      <Flex>
        <Typography.Title level={2} className={style.title}>
          {counterparty?.companyName}
        </Typography.Title>
      </Flex>
      <Flex gap={5}>
        <CardStatistic value={counterparty?.clientCount || 0} title={'Сотрудники'} classNames={style.infoStatistic} />
        {counterparty?.activeContracts.length !== 0 && (
          <>
            <CardStatistic value={balance} title={'Баланс'} classNames={style.infoStatistic} />
            <CardStatistic value={turnover} title={'Оборот'} classNames={style.infoStatistic} />
            {/*<CardStatistic value={expiresDate} title={'Договор до'} classNames={style.infoStatistic} />*/}
          </>
        )}
      </Flex>
    </Flex>
  )
}
