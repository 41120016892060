import { CloseOutlined, FilterOutlined, ProfileOutlined } from '@ant-design/icons'
import { Flex, Button, Tooltip, Form } from 'antd'

import { StudiosRoomsTags } from '../../../components/studios-rooms-tags/studios-rooms-tags.component'
import { ClientsAutocompleteContainer } from '../../../containers/clients-autocomplete/clients-autocomplete.container'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { useScheduleLongtermPageFilter } from './schedule-longterm-page-filter.hook'

export const ScheduleLongtermPageFilter: React.FC = () => {
  const {
    clientsSearchForm,
    roomId,
    longtermClientPhone,
    isLoading,
    studiosRoomsTags,
    isFilterOpen,
    timetableCreatePermission,
    onCreateHandler,
    onFilterHandler,
    onSelectRoomHandler,
    onResetRoomHandler,
    onSelectClientHandler,
    onResetClientHandler,
  } = useScheduleLongtermPageFilter()

  return (
    <Flex style={{ paddingBottom: '24px' }} vertical gap={16}>
      <Flex justify="space-between">
        <Button icon={<FilterOutlined style={{ color: '#0958D9' }} />} onClick={onFilterHandler}>
          Фильтры
        </Button>
        <Tooltip title={!timetableCreatePermission ? 'У вас нет разрешения на это действие' : ''}>
          <Button
            type="primary"
            icon={<ProfileOutlined />}
            disabled={!timetableCreatePermission}
            onClick={onCreateHandler}
          >
            Создать длинную запись
          </Button>
        </Tooltip>
      </Flex>
      {isFilterOpen && (
        <Flex vertical gap={16} align="baseline">
          {isDefAndNotEmpty(studiosRoomsTags) && (
            <div>
              <StudiosRoomsTags
                tags={studiosRoomsTags}
                selectedId={roomId}
                onSelect={onSelectRoomHandler}
                onReset={onResetRoomHandler}
              />
            </div>
          )}
          <Form style={{ width: '280px' }} form={clientsSearchForm}>
            <Form.Item name="phone">
              <ClientsAutocompleteContainer
                placeholder="Введите ФИО или номер клиента"
                name="phone"
                form={clientsSearchForm}
                showButtonAddClient={false}
                disabled={isLoading}
                setClientId={onSelectClientHandler}
                suffix={
                  longtermClientPhone ? (
                    <CloseOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} onClick={onResetClientHandler} />
                  ) : null
                }
              />
            </Form.Item>
          </Form>
        </Flex>
      )}
    </Flex>
  )
}
