import { DictionaryItem } from '../../../../api/types/api.types'
import { NString, Nullable } from '../../../../types/lang.types'

export enum WebsocketTimetableMessageExerciseAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  CANCEL = 'CANCEL',
  CREATE_BOOKING = 'CREATE_BOOKING',
  PAY_BOOKING = 'PAY_BOOKING',
}

export interface WebsocketTimetableMessageExerciseData {
  id: string
  timeFrom: string
  timeTo: string
  roomId: string
  trainers?: Nullable<DictionaryItem[]>
  timetableId: string
  studio: DictionaryItem
  isCanceled?: boolean
}

interface ExerciseConflict {
  conflictingExerciseId: string
  timeFrom?: string
  timeTo?: string
  room?: DictionaryItem
  trainers?: DictionaryItem[]
}

export interface WebsocketTimetableMessage {
  action: WebsocketTimetableMessageExerciseAction
  exercise: WebsocketTimetableMessageExerciseData
  conflicts: Nullable<ExerciseConflict[]>
  error: NString
}

export interface WebsocketTimetableUpdateMessagePayload {
  id: string
  trainers?: Nullable<DictionaryItem[]>
  roomId: string
}

export interface WebsocketTimetableUpdateExerciseStatusPayload {
  exerciseId: string
  action: WebsocketTimetableMessageExerciseAction
}
