import { NNumber } from '../../types/lang.types'
import { DEFAULT_PAGE_SIZE } from '../../utils/pagination.utils'

export interface ContractsPageInfoParams {
  id: string
}

export interface ContractsInfoPageUrlParams {
  section?: string
}

export type ContractsInfoUrlParams = ContractsPageInfoParams & ContractsInfoPageUrlParams

export interface ContractsInfoPageQueryParams {
  page?: NNumber
  size?: NNumber
}

export interface ContractsInfoPageParams {
  section: string
  page?: NNumber
  size?: NNumber
}

export enum ContractsInfoPageSection {
  DESCRIPTION = 'description',
  CLIENTS = 'clients',
  BOOKING_AND_PRODUCTS = 'booking-and-products',
  CONTRACTS = 'contracts',
}

export const CONTRACTS_INFO_PAGE_SIZE: number = DEFAULT_PAGE_SIZE
