import { DeleteOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Col, Form, FormInstance, FormListFieldData, Row, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import React from 'react'

import { isDefAndNotEmpty } from '../../../../../../../types/lang.types'
import { MasterServicesFormValues } from '../../../../master-services-form.types'
import { useMasterServicesFormSubservicesAvailableStudioRoom } from './master-services-form-subservices-available-studio-room.hook'
import './master-services-form-subservices-available-studio-room.styles.less'

const { Option } = Select

interface Props {
  form: FormInstance<MasterServicesFormValues>
  field: FormListFieldData
  subField: FormListFieldData
  studiosOptions?: DefaultOptionType[]
  studiosRoomsOptions?: DefaultOptionType[]
  onRemove: (index: number | number[]) => void
}

export const MasterServicesFormSubservicesAvailableStudioRoom: React.FC<Props> = props => {
  const { form, field, subField, studiosOptions, studiosRoomsOptions, onRemove } = props

  const { selectedStudio, onChangeStudioHandler } = useMasterServicesFormSubservicesAvailableStudioRoom({
    field,
    subField,
    form,
  })

  return (
    <Row gutter={16}>
      <Col flex="1 1 0px">
        <Form.Item
          name={[subField.name, 'studioId']}
          className="master-services-form-subservices-available-studio-room__select"
          rules={[{ required: true, message: 'Выберите локацию' }]}
        >
          <Select
            size="large"
            placeholder="Выбрать"
            options={studiosOptions}
            onChange={value => onChangeStudioHandler(value)}
          />
        </Form.Item>
      </Col>
      <Col flex="1 1 0px">
        <Form.Item
          name={[subField.name, 'roomIds']}
          className="master-services-form-subservices-available-studio-room__select"
          rules={[{ required: true, message: 'Выберите пространства' }]}
        >
          <Select size="large" placeholder="Выбрать" mode="multiple" suffixIcon={<DownOutlined />}>
            {isDefAndNotEmpty(studiosRoomsOptions) &&
              studiosRoomsOptions
                .filter(studiosRoomsOption => studiosRoomsOption.studioId === selectedStudio)
                .filter(studiosRoomsOption => studiosRoomsOption.label)
                .sort((a, b) => {
                  if (typeof a.label === 'string' && typeof b.label === 'string') {
                    return a.label.localeCompare(b.label)
                  }
                  return 0
                })
                .map(studiosRoomsOption => (
                  <Option key={studiosRoomsOption.value} value={studiosRoomsOption.value}>
                    {studiosRoomsOption.label}
                  </Option>
                ))}
          </Select>
        </Form.Item>
      </Col>
      <Col flex="0 1 32px">
        <Button danger icon={<DeleteOutlined />} onClick={() => onRemove(subField.name)} />
      </Col>
    </Row>
  )
}
