import { DefaultOptionType } from 'antd/es/select'

import { ExercisesTimetableApi } from '../api/types/exercises-timetable-api.types'
import { formatPaymentType } from '../format/text.format'
import { isDefAndNotEmpty, Nullable } from '../types/lang.types'

export function mapExercisesTimetablePaymentTypesToOptions(
  paymentTypes: Nullable<ExercisesTimetableApi.ExercisesTimetablePaymentType[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(paymentTypes)) {
    return paymentTypes.map(
      (paymentType: ExercisesTimetableApi.ExercisesTimetablePaymentType): DefaultOptionType => ({
        value: paymentType.clientSubscriptionId || paymentType.paymentType,
        label: paymentType.subscriptionName || formatPaymentType(paymentType.paymentType),
        affordableExercisesCount: paymentType.affordableExercises?.length || 0,
      })
    )
  }
}
