import * as React from 'react'
import { Button, FormInstance, Typography } from 'antd'

import { TransactionsFormValues } from '../../../components/transactions/transactions-form/transactions-form.types'
import { useTransactionsCreatePageSubmit } from './transactions-create-page-submit.hook'
import './transactions-create-page-submit.styles.less'

interface Props {
  form: FormInstance<TransactionsFormValues>
}

export const TransactionsCreatePageSubmit: React.FC<Props> = props => {
  const { form } = props

  const {
    isLoadingTransactionsCreatePage,
    isLoadingTransactionsCreatePageTable,
    isValid,
    isProductsTransactionAvailable,
  } = useTransactionsCreatePageSubmit(form)

  return (
    <div className="transactions-create-page-submit">
      <Button
        className="transactions-create-page-submit__button"
        type="primary"
        htmlType="submit"
        disabled={!isValid || isLoadingTransactionsCreatePage || isLoadingTransactionsCreatePageTable}
      >
        Оплатить
      </Button>
      {!isProductsTransactionAvailable && (
        <Typography.Text type="danger">
          Невозможно осуществить продажу выбранных товаров в данной локации
        </Typography.Text>
      )}
    </div>
  )
}
