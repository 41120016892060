import { Button, Card, Flex, Progress, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { useMemo, useCallback } from 'react'

import { ScheduleModalProgressProps } from './schedule-modal-progress.types'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { ScheduleModal } from '../schedule-modal/schedule-modal.component'
import './schedule-modal-progress.styles.less'
import { formatDate } from '../../../format/date.format'

export const ScheduleModalProgress: React.FC<ScheduleModalProgressProps> = props => {
  const { title, type, messages, totalExercises, totalBookingClients, studioOffset, onCancel, onConflictResolution } =
    props

  const progress = useMemo(() => {
    const failedExercisesCount = messages.filter(
      message =>
        message.action === 'CREATE' &&
        (message.error !== null || message.conflicts !== null || message.exercise.isCanceled)
    )?.length

    return totalExercises
      ? Math.round(
          (messages.length * 100) /
            (totalExercises + (totalBookingClients ? totalBookingClients * (totalExercises - failedExercisesCount) : 0))
        )
      : 0
  }, [messages, totalExercises, totalBookingClients])

  const filteredMessages = [...messages].filter(
    message => (message.conflicts !== null || message.error !== null) && !message.exercise.isCanceled
  )

  const onCancelHandler = useCallback(() => {
    onCancel(progress === 100)
  }, [onCancel, progress])

  return (
    <ScheduleModal
      title={title}
      width={560}
      customClassName="schedule-modal-progress"
      maskClosable={false}
      onCancel={onCancelHandler}
      footer={
        progress === 100 ? (
          <Flex justify="center">
            {isDefAndNotEmpty(filteredMessages) ? (
              <Button type="primary" onClick={onConflictResolution}>
                Решение конфликтов
              </Button>
            ) : (
              <Button type="primary" onClick={onCancelHandler}>
                Завершить {type === 'create' ? 'создание' : type === 'pay' ? 'оплату' : 'редактирование'}
              </Button>
            )}
          </Flex>
        ) : null
      }
    >
      <Flex vertical gap={16}>
        {totalExercises && <Progress className="schedule-modal-progress__progress" type="circle" percent={progress} />}
        <Flex vertical gap={5}>
          {filteredMessages.map((message, index) => {
            const { exercise } = message

            const offset = studioOffset || 0
            const timeFrom = dayjs(exercise.timeFrom).utcOffset(offset).format('HH:mm')
            const timeTo = dayjs(exercise.timeTo).utcOffset(offset).format('HH:mm')

            const trainerNames = isDefAndNotEmpty(exercise.trainers)
              ? exercise.trainers.map(trainer => trainer.name).join(', ')
              : 'Без исполнителя'
            const success = message.error === null && message.conflicts === null

            return (
              <Card className="schedule-modal-progress__card" key={index}>
                <Flex justify="space-between" align="center">
                  <div>
                    <Typography.Title style={{ margin: 0 }} level={5}>
                      {formatDate(exercise.timeFrom, 'D MMMM')}, с {timeFrom} до {timeTo}
                    </Typography.Title>
                    <Typography.Text>{trainerNames}</Typography.Text>
                  </div>
                  <Tag color={success && message.action !== 'CANCEL' ? 'green' : 'red'}>
                    {!success ? 'Конфликт' : message.action === 'CANCEL' ? 'Отменена' : 'Успех'}
                  </Tag>
                </Flex>
              </Card>
            )
          })}
        </Flex>
      </Flex>
    </ScheduleModal>
  )
}
