import { CloseOutlined, EditOutlined, EyeOutlined, HistoryOutlined } from '@ant-design/icons'
import { formatRublesPenniesCurrency } from '@format/number.format'
import { Button, Tooltip } from 'antd'
import { Link } from 'react-router-dom'

export const genGoodsTableColumns = (onOpenModal: any, onHistory: (id: string, name: string) => void) => {
  return [
    {
      title: 'Название',
      key: 'name',
      render: (_: any, row: any) => {
        return row.name
      },
    },
    {
      title: 'Категория',
      key: 'category',
      render: (_: any, row: any) => {
        return row.category.name
      },
    },
    {
      title: 'Цена закупки',
      key: 'purchasePriceRange',
      render: (_: any, row: any) => {
        return row.purchasePriceRange.from && row.purchasePriceRange.to
          ? formatRublesPenniesCurrency(row.purchasePriceRange.from) +
              ' - ' +
              formatRublesPenniesCurrency(row.purchasePriceRange.to)
          : null
      },
    },
    {
      title: 'Цена продажи',
      key: 'cost',
      render: (_: any, row: any) => {
        return formatRublesPenniesCurrency(row.cost)
      },
    },
    {
      title: 'Маркировочный товар',
      key: 'hasMarkCode',
      render: (_: any, row: any) => {
        return row.hasMarkCode ? (
          <Button type="primary" ghost style={{ backgroundColor: '#F0F5FF', borderColor: '#ADC6FF' }}>
            Да
          </Button>
        ) : (
          <Button type="primary" ghost style={{ backgroundColor: '#F0F5FF', borderColor: '#ADC6FF' }}>
            Нет
          </Button>
        )
      },
    },
    {
      title: 'Кол-во на складе',
      key: 'countLeft',
      render: (_: any, row: any) => {
        return row.countLeft
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 70,
      render: (_: any, row: any) => {
        const { id, name } = row
        return (
          <div style={{ display: 'flex', gap: '4px' }}>
            <Tooltip title="Посмотреть историю изменений">
              <Button icon={<HistoryOutlined />} size="middle" onClick={() => onHistory(id, name)} />
            </Tooltip>
            <Button icon={<EditOutlined />} onClick={() => onOpenModal(row, 'edit')}></Button>
            <Button icon={<CloseOutlined />} onClick={() => onOpenModal(row, 'delete')} danger></Button>
          </div>
        )
      },
    },
  ]
}

export const genCategoriesTableColumns = (onOpenModal: any) => {
  return [
    {
      title: 'Название',
      key: 'name',
      render: (_: any, row: any) => {
        return row.name
      },
    },
    {
      title: 'Маркировочный товар',
      key: 'hasMarkCode',
      render: (_: any, row: any) => {
        return row.hasMarkCode ? (
          <Button type="primary" ghost style={{ backgroundColor: '#F0F5FF', borderColor: '#ADC6FF' }}>
            Да
          </Button>
        ) : (
          <Button type="primary" ghost style={{ backgroundColor: '#F0F5FF', borderColor: '#ADC6FF' }}>
            Нет
          </Button>
        )
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 70,
      render: (_: any, row: any) => {
        return (
          <div style={{ display: 'flex', gap: '4px' }}>
            <Button icon={<EditOutlined />} onClick={() => onOpenModal(row, 'edit')}></Button>
            <Button icon={<CloseOutlined />} onClick={() => onOpenModal(row, 'delete')} danger></Button>
          </div>
        )
      },
    },
  ]
}

export const genProvidersTableColumns = (onOpenModal: any) => {
  return [
    {
      title: 'Поставщик',
      key: 'name',
      render: (_: any, row: any) => {
        return row.name
      },
    },
    {
      title: 'ИНН',
      key: 'inn',
      render: (_: any, row: any) => {
        return row.inn
      },
    },
    {
      title: 'Телефон',
      key: 'phone',
      render: (_: any, row: any) => {
        return row.phone
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 70,
      render: (_: any, row: any) => {
        return (
          <div style={{ display: 'flex', gap: '4px' }}>
            <Button icon={<EditOutlined />} onClick={() => onOpenModal(row, 'edit')}></Button>
            <Button icon={<CloseOutlined />} onClick={() => onOpenModal(row, 'delete')} danger></Button>
          </div>
        )
      },
    },
  ]
}

export const genConsignmentsTableColumns = (onDelete: any) => {
  return [
    {
      title: 'Дата накладной',
      key: 'consignmentNoteDate',
      render: (_: any, row: any) => {
        return row.consignmentNoteDate
      },
    },
    {
      title: 'Поставщик',
      key: 'provider',
      render: (_: any, row: any) => {
        return row.provider.name
      },
    },
    {
      title: 'Сумма накладной',
      key: 'sum',
      render: (_: any, row: any) => {
        return formatRublesPenniesCurrency(row.sum)
      },
    },
    {
      title: 'Локация',
      key: 'studio',
      render: (_: any, row: any) => {
        return row.studio.name
      },
    },
    {
      title: 'Компания',
      key: 'organization',
      render: (_: any, row: any) => {
        return row.organization.name
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 70,
      render: (_: any, row: any) => {
        return (
          <div style={{ display: 'flex', gap: '4px' }}>
            <Link to={`/consignment-edit/${row.id}`}>
              <Button icon={<EditOutlined />}></Button>
            </Link>
            <Button icon={<CloseOutlined />} onClick={() => onDelete(row)} danger></Button>
          </div>
        )
      },
    },
  ]
}

export const genConsignmentsGoodsColumns = (onOpenModal: any) => {
  return [
    {
      title: 'Дата накладной',
      key: 'consignmentNoteDate',
      render: (_: any, row: any) => {
        return row.consignmentNoteDate
      },
    },
    {
      title: 'Поставщик',
      key: 'provider',
      render: (_: any, row: any) => {
        return row.provider.name
      },
    },
    {
      title: 'Остаток',
      key: 'provider',
      render: (_: any, row: any) => {
        return typeof row.countLeft === 'number' && typeof row.count === 'number'
          ? `${row.countLeft} / ${row.count}`
          : '0/0'
      },
    },
    {
      title: 'Сумма накладной',
      key: 'sum',
      render: (_: any, row: any) => {
        return row.sum ? formatRublesPenniesCurrency(row.sum) : null
      },
    },
    {
      title: 'Цена продажи',
      key: 'cost',
      render: (_: any, row: any) => {
        return row?.cost ? formatRublesPenniesCurrency(row?.cost) : null
      },
    },
    {
      title: 'Локация',
      key: 'studio',
      render: (_: any, row: any) => {
        return row.studio.name
      },
    },
    {
      title: 'Компания',
      key: 'organization',
      render: (_: any, row: any) => {
        return row.organization.name
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_: any, row: any) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button onClick={() => onOpenModal(row, 'writeOff')}>Списать</Button>
            <Button onClick={() => onOpenModal(row, 'move')}>Переместить</Button>
            <Link to={`/consignment-edit/${row?.consignmentNoteId}`}>
              <Button type="primary" ghost icon={<EyeOutlined />}>
                Накладная
              </Button>
            </Link>
          </div>
        )
      },
    },
  ]
}
