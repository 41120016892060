import React from 'react'
import { clsx } from 'clsx'
import style from '@pages/contracts-info-page/contracts-info-page-tabs/contracts-info-page-contracts/contracts-info-contract-card/contracts-info-contract-card.module.scss'
import { Flex, Typography } from 'antd'
import { ContractsApi } from '@api/types/contracts-api.types'

import { formatDateToHumanize } from '../../../../../format/date.format'

interface ContractsInfoContractCardArchiveProps {
  contract: ContractsApi.Contracts
}

export const ContractsInfoContractCardArchive = ({ contract }: ContractsInfoContractCardArchiveProps) => {
  return (
    <Flex
      vertical
      gap={'large'}
      className={clsx(style.cardContainer, { [style.containerPostPay]: contract.type === 'POSTPAY' })}
      justify={'center'}
    >
      <Flex vertical>
        <Typography.Text>До {formatDateToHumanize(contract.validUntil)}</Typography.Text>
        <Flex justify={'space-between'} style={{ height: '64px' }}>
          <Flex vertical>
            <Typography.Title level={5} className={style.cardTitle}>
              Договор {contract.name}
            </Typography.Title>
            <Typography.Text type={'secondary'}>
              {contract.type === 'POSTPAY' ? 'Постоплата' : `Предоплата`}
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
