import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

const { confirm } = Modal

export const onRemoveRooms = (e: React.SyntheticEvent, onOk: (values: any) => void) => {
  e.stopPropagation()
  confirm({
    title: `Подтвердите удаление`,
    icon: <ExclamationCircleOutlined />,
    content: `Вы уверены что хотите удалить пространство`,
    onOk: onOk,
  })
}
