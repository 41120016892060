import { isDef, Nullable } from '../types/lang.types'
import { ContractsApi } from '../api/types/contracts-api.types'

export function genCounterpartiesFormValues(
  counterparty: Nullable<ContractsApi.CounterpartiesDTO>
): Nullable<ContractsApi.CounterpartiesDTO> {
  if (isDef(counterparty)) {
    return {
      companyName: counterparty.companyName,
      displayName: counterparty.companyName,
      ogrn: counterparty.ogrn,
      inn: counterparty.inn,
      kpp: counterparty.kpp,
      snoType: counterparty.snoType,
      address: counterparty.address,
      directorName: counterparty.directorName,
      phoneNumber: counterparty.phoneNumber,
      email: counterparty.email,
      bankName: counterparty.bankName,
      bic: counterparty.bic,
      bankAccount: counterparty.bankAccount,
      correspondentAccount: counterparty.correspondentAccount,
    }
  }

  return null
}
