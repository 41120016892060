import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'

const getPermissionsInternal = (state: AppState) => state.permissions.permissions

const getClientBookingPermissionsInternal = createSelector(getPermissionsInternal, permissions => {
  if (!permissions) return false
  return permissions.find(permission => permission.name === 'client_booking')
})

const getTimetablePermissionsInternal = createSelector(getPermissionsInternal, permissions => {
  if (!permissions) return false
  return permissions.find(permission => permission.name === 'timetable')
})

export const getPermissionsIsLoading = (state: AppState) => state.permissions.isLoading

export const getPermissionsIsLoaded = (state: AppState) => state.permissions.isLoaded

export const getStudiosWithViewPermissions = createSelector(getPermissionsInternal, permissions => {
  if (!permissions) return []

  const studioPermission = permissions.find(permission => permission.name === 'studio')

  if (!studioPermission) return []

  return studioPermission.permissions
    .filter(permission => permission.action === 'view' && permission.allowed)
    .map(permission => permission.resourceId)
})

export const getClientsViewPermission = createSelector(getPermissionsInternal, permissions => {
  if (!permissions) return false

  const clientsPermission = permissions.find(permission => permission.name === 'client')

  if (!clientsPermission) return false

  return clientsPermission.permissions.some(permission => permission.action === 'list' && permission.allowed)
})

export const getManagementPermission = createSelector(getPermissionsInternal, permissions => {
  if (!permissions) return false

  const employeesPermission = permissions.find(permission => permission.name === 'employee')

  if (!employeesPermission) return false

  return employeesPermission.permissions.some(
    permission => permission.action === 'access_management' && permission.allowed
  )
})

export const getTransactionAccessPermission = createSelector(getPermissionsInternal, permissions => {
  if (!permissions) return false

  const transactionPermission = permissions.find(permission => permission.name === 'transaction')

  if (!transactionPermission) return false

  return transactionPermission.permissions.some(permission => permission.action === 'access' && permission.allowed)
})

export const getClientBookingCreatePermission = createSelector(
  getClientBookingPermissionsInternal,
  clientBookingPermissions => {
    if (!clientBookingPermissions) return false

    return clientBookingPermissions.permissions.some(permission => permission.action === 'create' && permission.allowed)
  }
)

export const getClientBookingReadPermission = createSelector(
  getClientBookingPermissionsInternal,
  clientBookingPermissions => {
    if (!clientBookingPermissions) return false

    return clientBookingPermissions.permissions.some(permission => permission.action === 'read' && permission.allowed)
  }
)

export const getClientBookingUpdatePermission = createSelector(
  getClientBookingPermissionsInternal,
  clientBookingPermissions => {
    if (!clientBookingPermissions) return false

    return clientBookingPermissions.permissions.some(permission => permission.action === 'update' && permission.allowed)
  }
)

export const getClientBookingDeletePermission = createSelector(
  getClientBookingPermissionsInternal,
  clientBookingPermissions => {
    if (!clientBookingPermissions) return false

    return clientBookingPermissions.permissions.some(permission => permission.action === 'delete' && permission.allowed)
  }
)

export const getTimetableCreatePermission = createSelector(getTimetablePermissionsInternal, timetablePermissions => {
  if (!timetablePermissions) return false

  return timetablePermissions.permissions.some(permission => permission.action === 'create' && permission.allowed)
})

export const getTimetableReadPermission = createSelector(getTimetablePermissionsInternal, timetablePermissions => {
  if (!timetablePermissions) return false

  return timetablePermissions.permissions.some(permission => permission.action === 'read' && permission.allowed)
})

export const getTimetableUpdatePermission = createSelector(getTimetablePermissionsInternal, timetablePermissions => {
  if (!timetablePermissions) return false

  return timetablePermissions.permissions.some(permission => permission.action === 'update' && permission.allowed)
})

export const getTimetableDeletePermission = createSelector(getTimetablePermissionsInternal, timetablePermissions => {
  if (!timetablePermissions) return false

  return timetablePermissions.permissions.some(permission => permission.action === 'delete' && permission.allowed)
})
