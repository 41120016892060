import { DeleteOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { ScheduleLongtermPageModalConfirmProps } from './schedule-longterm-page-modal-confirm.types'

export const ScheduleLongtermPageModalConfirm: React.FC<ScheduleLongtermPageModalConfirmProps> = props => {
  const { title, text, onSubmit } = props

  const dispatch = useDispatch()

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.close(AppModal.SCHEDULE_LONGTERM_PAGE_MODAL_CONFIRM))
  }, [dispatch])

  const onSubmitHandler = React.useCallback((): void => {
    onSubmit()
    onCancelHandler()
  }, [onCancelHandler, onSubmit])

  return (
    <Modal
      title={title}
      open={true}
      onCancel={onCancelHandler}
      footer={[
        <Button key="back" onClick={onCancelHandler}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" danger onClick={onSubmitHandler} icon={<DeleteOutlined />}>
          Подтвердить
        </Button>,
      ]}
    >
      <p>{text}</p>
    </Modal>
  )
}
