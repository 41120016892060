import * as React from 'react'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { TransactionsFormValues } from '../../components/transactions/transactions-form/transactions-form.types'
import { transactionsCreatePageTableActions } from '../../store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.slice'
import { transactionsCreatePageModalProductsActions } from '../../store/pages/transactions-create-page/transactions-create-page-modal-products/transactions-create-page-modal-products.slice'
import { transactionsCreatePageSubmitActions } from '../../store/pages/transactions-create-page/transactions-create-page-submit/transactions-create-page-submit.slice'
import { getStudiosWithViewPermissions } from '../../store/common/permissions/permissions.selectors'
import { useTransactionsCreatePageParams } from './transactions-create-page-hooks/transactions-create-page-params.hook'

export function useTransactionsCreatePage() {
  const [form] = Form.useForm<TransactionsFormValues>()

  const dispatch = useDispatch()

  const params = useTransactionsCreatePageParams()
  const { studioId } = params

  const allowedStudios = useSelector(getStudiosWithViewPermissions)

  React.useEffect(() => {
    return () => {
      dispatch(transactionsCreatePageTableActions.reset())
      dispatch(transactionsCreatePageModalProductsActions.reset())
      dispatch(transactionsCreatePageSubmitActions.reset())
    }
  }, [dispatch])

  return {
    form,
    studioId,
    allowedStudios,
  }
}
