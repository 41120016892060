import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { mapClientsToClientsTable } from '../../../../mapping/contracts.mapping'

export const getClientsInternal = (state: AppState) => state.contractsInfoPage.contractsInfoPageClients.clients

export const getClientsIsLoading = (state: AppState) => state.contractsInfoPage.contractsInfoPageClients.isLoading
export const getClientsIsLoaded = (state: AppState) => state.contractsInfoPage.contractsInfoPageClients.isLoaded

export const getCounterpartyFormValue = createSelector(getClientsInternal, clients =>
  mapClientsToClientsTable(clients?.content)
)

export const getCounterpartyClientsTotalElements = createSelector(getClientsInternal, clients => clients?.totalElements)
