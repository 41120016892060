import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'
import { PermissionsApi } from '../types/permissions.types'

export class PermissionsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchPermissionsByPositionId = (positionId: string): Promise<AxiosResponse<PermissionsApi.Permissions[]>> => {
    return this.httpConnector.get<PermissionsApi.Permissions[]>(`/permissions/positions/${positionId}`)
  }

  public fetchPermissionsByEmployeeId = (employeeId: string): Promise<AxiosResponse<PermissionsApi.Permissions[]>> => {
    return this.httpConnector.get<PermissionsApi.Permissions[]>(`/permissions/employees/${employeeId}`)
  }

  public updatePositionPermission = (
    positionId: string,
    resourceName: string,
    action: string,
    data: PermissionsApi.PermissionUpdateDTO
  ): Promise<AxiosResponse<{}>> => {
    return this.httpConnector.put(`/permissions/positions/${positionId}/${resourceName}/${action}`, data)
  }

  public copyPositionPermissions = (
    positionId: string,
    data: PermissionsApi.PermissionsCopyDTO
  ): Promise<AxiosResponse<{}>> => {
    return this.httpConnector.post(`/permissions/positions/${positionId}/copy`, data)
  }
}
