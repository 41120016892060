import { Form, FormInstance } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTheme } from '../../../hooks/use-theme.hook'
import { Nullable } from '../../../types/lang.types'
import { ScheduleEditExercisesFormValues } from './schedule-edit-exercises-form.types'

interface Props {
  form: FormInstance<ScheduleEditExercisesFormValues>
  initialValues: ScheduleEditExercisesFormValues
  longterm?: boolean
  paymentTypesOptions?: DefaultOptionType[]
  preSelectedPaymentType?: string
  onEditExercises: () => void
  onEditExercisesComment: () => void
  onPayExercises?: () => void
}

export function useScheduleEditExercisesForm(props: Props) {
  const { form, paymentTypesOptions, initialValues, longterm, preSelectedPaymentType } = props
  const { onEditExercises, onEditExercisesComment, onPayExercises } = props

  const { theme } = useTheme()

  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const [paymentType, setPaymentType] = useState<Nullable<DefaultOptionType>>(null)

  const [visiblePanels, setVisiblePanels] = useState<string>()

  const menuItems = useMemo(() => {
    const allItems = [
      { key: '1', label: 'Отменить записи' },
      ...(Object.values(initialValues).some(value => value !== undefined)
        ? [{ key: '2', label: 'Измененить записи: день недели, время и другое' }]
        : []),
      ...(longterm ? [{ key: '3', label: 'Оплатить записи' }] : []),
      { key: '4', label: 'Изменить комментарий' },
    ]

    return allItems.filter(item => item.key !== visiblePanels)
  }, [initialValues, longterm, visiblePanels])

  // const studioId = Form.useWatch(['studio', 'newValue'], form)
  const selectedTrainers = Form.useWatch(['trainers', 'newValue'], form) || []

  const onCollapseChangeHandler = useCallback((keys: string | string[]) => {
    setActiveKeys(Array.isArray(keys) ? keys : [keys])
  }, [])

  const onMenuClick = useCallback(
    ({ key }: { key: string }) => {
      setVisiblePanels(key)
      onCollapseChangeHandler([key])
    },
    [onCollapseChangeHandler]
  )

  const isTrainerOptionDisabled = (optionValue: string) => {
    return selectedTrainers.includes('noTrainer') && optionValue !== 'noTrainer'
  }

  const onChangeTrainersHandler = useCallback(
    (value: string[]) => {
      if (value.includes('noTrainer')) {
        form.setFieldsValue({ trainers: { newValue: ['noTrainer'] } })
      }
    },
    [form]
  )

  const onChangeStudioHandler = useCallback(
    (value: string): void => {
      const values = form.getFieldsValue()

      form.setFieldsValue({
        ...values,
        studio: {
          ...values.studio,
          newValue: value,
        },
        room: {
          ...values.room,
          newValue: undefined,
        },
      })
    },
    [form]
  )

  const onChangePaymentTypeHandler = useCallback(
    (value: string) => {
      const selected = paymentTypesOptions?.find(option => option.value === value)
      setPaymentType(selected)
    },
    [paymentTypesOptions]
  )

  const onEditHandler = useCallback(async () => {
    try {
      await form.validateFields([
        ['room', 'newValue'],
        ['trainers', 'newValue'],
        ['dayOfWeek', 'newValue'],
        ['timeFrom', 'newValue'],
        ['timeTo', 'newValue'],
      ])
      onEditExercises()
    } catch (errorInfo) {}
  }, [form, onEditExercises])

  const onEditCommentHandler = useCallback(async () => {
    try {
      await form.validateFields(['comment'])
      onEditExercisesComment()
    } catch (errorInfo) {}
  }, [form, onEditExercisesComment])

  const onPayHandler = useCallback(async () => {
    try {
      await form.validateFields(['paymentType'])
      onPayExercises && onPayExercises()
    } catch (errorInfo) {}
  }, [form, onPayExercises])

  // If payment type was previously selected, set the visibility of the panels and activate key
  useEffect(() => {
    if (preSelectedPaymentType) {
      setVisiblePanels('3')
      setActiveKeys(['3'])
      onChangePaymentTypeHandler(preSelectedPaymentType)
    }
  }, [onChangePaymentTypeHandler, paymentType, preSelectedPaymentType])

  return {
    theme,
    activeKeys,
    visiblePanels,
    // studioId,
    paymentType,
    menuItems,
    isTrainerOptionDisabled,
    onMenuClick,
    onChangeTrainersHandler,
    onCollapseChangeHandler,
    onChangeStudioHandler,
    onChangePaymentTypeHandler,
    onEditHandler,
    onEditCommentHandler,
    onPayHandler,
  }
}
