import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useKeycloak } from '@react-keycloak/web'
import Tracker from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'

import { PageLoader } from '../components/page/page-loader/page-loader.component'
import { AuthLayout } from '../components/layouts/auth-layout/auth-layout.component'
import { AppLayoutContainer } from '../containers/app-layout/app-layout.container'
import { layoutActions } from '../store/common/layout/layout.slice'
import { getCurrentEmployee, getLayoutIsLoaded, getLayoutIsLoading } from '../store/common/layout/layout.selectors'
import { isDef } from '../types/lang.types'
import { useTheme } from '../hooks/use-theme.hook'
import { AppRouting } from './app-routing.component'
import { AppThemeSwitcherContainer } from '../containers/app-theme-switcher/app-theme-switcher.container'
import { useTransactionsStatuses } from '../hooks/use-transactions-statuses.hook'
import './app-root.styles.less'
import { permissionsActions } from '../store/common/permissions/permissions.slice'
import { getPermissionsIsLoaded, getPermissionsIsLoading } from '../store/common/permissions/permissions.selectors'

export const AppRoot: React.FC = () => {
  useTheme()

  const { keycloak } = useKeycloak()
  const { authenticated } = keycloak

  const dispatch = useDispatch()

  const layoutIsLoading = useSelector(getLayoutIsLoading)
  const layoutIsLoaded = useSelector(getLayoutIsLoaded)

  const authIsLoaded = isDef(authenticated)
  const authIsLoading = !isDef(authenticated)

  const permissionsIsLoading = useSelector(getPermissionsIsLoading)
  const permissionsIsLoaded = useSelector(getPermissionsIsLoaded)

  useTransactionsStatuses()

  const isLoaded = layoutIsLoaded && authIsLoaded && authenticated && permissionsIsLoaded
  const isLoading = layoutIsLoading || authIsLoading || permissionsIsLoading

  const currentEmployee = useSelector(getCurrentEmployee)

  React.useEffect(() => {
    if (authIsLoaded && authenticated) {
      dispatch(layoutActions.fetchAllDictionaries())
    }
  }, [authIsLoaded, authenticated, dispatch])

  React.useEffect(() => {
    if (currentEmployee) {
      dispatch(permissionsActions.fetchPermissions(currentEmployee.id))
    }
  }, [currentEmployee, dispatch])

  React.useEffect(() => {
    if (currentEmployee) {
      try {
        const tracker = new Tracker({
          projectKey: 'OSWd7lg25eDtfMCEy1Cn',
          ingestPoint: 'https://openreplay.vivacrm.ru/ingest',
          network: {
            sessionTokenHeader: false,
            failuresOnly: true,
            ignoreHeaders: ['Cookie', 'Set-Cookie', 'Authorization'],
            capturePayload: true,
            captureInIframes: true,
          },
        })
        tracker.use(trackerAssist())
        if (!tracker.isActive()) {
          tracker.start({
            userID: currentEmployee.id,
            metadata: {
              tenantKey: currentEmployee.tenantKey,
              lastName: currentEmployee.lastName,
            },
          })
        }
      } catch (error) {
        console.error('OpenReplay error:', error)
      }
    }
  }, [currentEmployee])

  React.useEffect(() => {
    return () => {
      dispatch(layoutActions.reset())
    }
  }, [dispatch])

  const onLoginHandler = React.useCallback(() => keycloak.login(), [keycloak])

  if (isLoading) {
    return <PageLoader className="app-layout-loader" />
  }

  if (isLoaded) {
    return (
      <AppLayoutContainer>
        <AppRouting />
        <AppThemeSwitcherContainer />
      </AppLayoutContainer>
    )
  }

  return <AuthLayout onLogin={onLoginHandler} />
}
