import { Rule } from 'antd/lib/form'
import { DefaultOptionType } from 'antd/lib/select'
import { isValidPhoneNumber } from 'libphonenumber-js'

import { formatDayjsToDate } from '../../../format/date.format'
import { formatClientSex } from '../../../format/text.format'
import { ClientSex } from '../../../types/clients.types'
import { isDef } from '../../../types/lang.types'
import { ClientsFormValues, ClientsFormValuesDTO } from './clients-form.types'

export function genClientSexOptions(): DefaultOptionType[] {
  return [
    {
      label: formatClientSex(ClientSex.U),
      value: ClientSex.U,
    },
    {
      label: formatClientSex(ClientSex.M),
      value: ClientSex.M,
    },
    {
      label: formatClientSex(ClientSex.F),
      value: ClientSex.F,
    },
  ]
}

export function genClientsFormValuesDTO(values: ClientsFormValues): ClientsFormValuesDTO {
  const { birthDate, ...restValues } = values

  return {
    ...restValues,
    birthDate: isDef(birthDate) ? formatDayjsToDate(birthDate) : null,
  }
}

export const ClientsFormValidateDeposit = (_: any, value: number) => {
  if (isNaN(value)) {
    return Promise.reject('Депозит должен быть числом')
  }
  if (value <= -1) {
    return Promise.reject('Депозит должен быть больше нуля')
  }
  return Promise.resolve()
}

export function genClientsFormPhoneValidationRules(): Rule[] {
  return [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value.length && !isValidPhoneNumber(value, 'RU')) {
          if (value.includes('+') || value.substring(0, 1) === '8') {
            return Promise.reject('Недопустимое значение')
          }
        } else {
          return Promise.resolve()
        }
      },
    }),
    { required: true, message: 'Введите номер телефона' },
  ]
}
