import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { ContractsApi } from '../../../api/types/contracts-api.types'
import { contractsPageActions } from '../../../store/pages/contracts-page/contracts-page.slice'
import { getCounterpartiesIsCreating } from '../../../store/pages/contracts-page/contracts-page.selectors'

export const useContractsHeader = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getCounterpartiesIsCreating)

  const onCreateClient = React.useCallback(
    (clientsFormValues: ContractsApi.CounterpartiesDTO) => {
      dispatch(contractsPageActions.createCounterparties(clientsFormValues))
    },
    [dispatch]
  )

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onCreateCounterpartiesHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.CONTRACTS_PAGE_COUNTERPARTY_MODAL_CREATE,
        props: {
          isLoading: isLoading,
          handleOk: onCreateClient,
          handleCancel: onCloseHandler,
        },
      })
    )
  }, [dispatch, isLoading, onCloseHandler, onCreateClient])

  return {
    onCreateCounterpartiesHandler,
  }
}
