import { createSelector } from '@reduxjs/toolkit'

import { mapExercisesTimetableToScheduleListDataItems } from '../../../../mapping/exercises-timetable.mapping'
import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { Nullable } from '../../../../types/lang.types'
import { AppState } from '../../../app.store'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import { mapStudiosRoomsToOptions, mapStudiosRoomsToTags } from '../../../../mapping/studios-rooms.mapping'
import { Pagination } from '../../../../api/types/api.types'

const genExercisesTimetableInternal = (
  state: AppState
): Nullable<Pagination<ExercisesTimetableApi.ExercisesTimetable>> =>
  state.scheduleLongtermPage.scheduleLongtermPageList.exercisesTimetable

const genStudiosRoomsInternal = (state: AppState): Nullable<StudiosRoomsApi.StudioRoom[]> =>
  state.scheduleLongtermPage.scheduleLongtermPageList.studiosRooms

export const genScheduleLongtermPageListIsLoading = (state: AppState): boolean =>
  state.scheduleLongtermPage.scheduleLongtermPageList.isLoading

export const genScheduleLongtermPageListIsLoaded = (state: AppState): boolean =>
  state.scheduleLongtermPage.scheduleLongtermPageList.isLoaded

export const genScheduleLongtermPageListTableDataItems = createSelector(genExercisesTimetableInternal, timetable =>
  mapExercisesTimetableToScheduleListDataItems(timetable?.content)
)

export const genScheduleLongtermPageListTableTotalElements = createSelector(
  genExercisesTimetableInternal,
  timetable => timetable?.totalElements
)

export const genScheduleLongtermPageListStudiosRoomsTags = createSelector(
  genStudiosRoomsInternal,
  mapStudiosRoomsToTags
)

export const genScheduleLongtermPageListStudiosRoomsOptions = createSelector(
  genStudiosRoomsInternal,
  mapStudiosRoomsToOptions
)
