import { put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { ScheduleOverview } from '../../../../components/schedule/schedule-overview/schedule-overview.types'
import {
  mapConflictsFormValuesToExerciseCreateDTO,
  mapConflictsFormValuesToExerciseUpdateDTO,
} from '../../../../mapping/exercises.mapping'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'
import { callApi } from '../../../../utils/sagas.utils'
import { websocketTimetableActions } from '../../../common/websocket/websocket-timetable/websocket-timetable.slice'
import { WebsocketTimetableMessageExerciseAction } from '../../../common/websocket/websocket-timetable/websocket-timetable.types'
import { genScheduleManagementPagePeekAboutSchedule } from '../schedule-management-page-peek-about/schedule-management-page-peek-about.selectors'
import { scheduleManagementPageModalConflictsActions } from './schedule-management-page-modal-conflicts.slice'

function* fetchModalData(_action: ReturnType<typeof scheduleManagementPageModalConflictsActions.fetchModalData>) {
  try {
    const response: Awaited<ReturnType<typeof api.trainersService.fetchAll>> = yield callApi(
      api.trainersService.fetchAll,
      { size: 300 }
    )

    yield put(scheduleManagementPageModalConflictsActions.fetchModalDataSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(scheduleManagementPageModalConflictsActions.fetchModalDataError(new Error()))
  }
}

export function* createExercise(action: ReturnType<typeof scheduleManagementPageModalConflictsActions.createExercise>) {
  try {
    const { scheduleId, exerciseId, values, createdExercises, timeFrom, timeTo, studioOffset } = action.payload
    const { directionId, typeId, timeslots, bookingClients } = createdExercises

    if (values) {
      const exercisesCreateDTO = mapConflictsFormValuesToExerciseCreateDTO(
        scheduleId,
        values,
        timeFrom,
        directionId,
        typeId,
        timeslots,
        studioOffset
      )

      if (isDef(exercisesCreateDTO)) {
        const response: Awaited<ReturnType<typeof api.exercisesService.create>> = yield callApi(
          api.exercisesService.create,
          exercisesCreateDTO
        )
        if (response) {
          const { id, room, trainers, studio } = response.data

          if (isDefAndNotEmpty(bookingClients)) {
            for (const bookingClient of bookingClients) {
              yield callApi(api.exercisesService.createBooking, response.data.id, bookingClient)

              yield put(
                websocketTimetableActions.addMessage({
                  action: WebsocketTimetableMessageExerciseAction.CREATE_BOOKING,
                  exercise: {
                    id,
                    timeFrom,
                    timeTo,
                    roomId: room.id,
                    trainers,
                    timetableId: scheduleId,
                    studio,
                  },
                  conflicts: null,
                  error: null,
                })
              )
            }
          }
          yield put(
            websocketTimetableActions.updateMessage({
              id: exerciseId,
              trainers: values.trainers,
              roomId: values.roomId,
            })
          )
          yield put(scheduleManagementPageModalConflictsActions.createExerciseSuccess())
        } else {
          yield put(scheduleManagementPageModalConflictsActions.createExerciseError(new Error()))
        }
      } else {
        yield put(scheduleManagementPageModalConflictsActions.createExerciseError(new Error()))
      }
    } else {
      yield put(scheduleManagementPageModalConflictsActions.createExerciseError(new Error()))
    }
  } catch (e) {
    yield put(scheduleManagementPageModalConflictsActions.createExerciseError(new Error()))
  }
}

export function* editExercise(action: ReturnType<typeof scheduleManagementPageModalConflictsActions.editExercise>) {
  try {
    const { exerciseId, studioId, values } = action.payload

    const schedule: Nullable<ScheduleOverview> = yield select(genScheduleManagementPagePeekAboutSchedule)

    if (values) {
      const exercisesUpdateDTO = mapConflictsFormValuesToExerciseUpdateDTO(values, studioId, schedule?.direction?.id)

      if (isDef(exercisesUpdateDTO)) {
        yield callApi(api.exercisesService.update, exerciseId, exercisesUpdateDTO)
        yield put(
          websocketTimetableActions.updateMessage({ id: exerciseId, trainers: values.trainers, roomId: values.roomId })
        )
        yield put(scheduleManagementPageModalConflictsActions.editExerciseSuccess())
      } else {
        yield put(scheduleManagementPageModalConflictsActions.editExerciseError(new Error()))
      }
    } else {
      yield put(scheduleManagementPageModalConflictsActions.editExerciseError(new Error()))
    }
  } catch (e) {
    yield put(scheduleManagementPageModalConflictsActions.editExerciseError(new Error()))
  }
}

export function* scheduleManagementPageModalConflictsSagas() {
  yield takeLatest(scheduleManagementPageModalConflictsActions.fetchModalData.type, fetchModalData)
  yield takeLatest(scheduleManagementPageModalConflictsActions.createExercise.type, createExercise)
  yield takeLatest(scheduleManagementPageModalConflictsActions.editExercise.type, editExercise)
}
