import { useSelector } from 'react-redux'

import { getCounterpartyByIdInternal } from '../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.selectors'

export function useContractsInfoHeader() {
  const counterparty = useSelector(getCounterpartyByIdInternal)

  return {
    counterparty,
  }
}
