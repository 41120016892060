import { DefaultOptionType } from 'antd/es/select'
import { useState, useCallback } from 'react'

import { apiV2 } from '../../../../api/api-v2'
import { mapExercisesTimetablePaymentTypesToOptions } from '../../../../mapping/payment-types.mapping'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { ScheduleListDataItem } from '../schedule-list.types'

interface Props {
  scheduleItem: ScheduleListDataItem
  onCreateTransaction: (phone: string, clientId: string) => void
}

export function useScheduleListCard(props: Props) {
  const { scheduleItem, onCreateTransaction } = props

  const [isLoading, setIsLoading] = useState(false)
  const [paymentTypes, setPaymentTypes] = useState<DefaultOptionType[] | undefined>([])

  const fetchPaymentTypes = useCallback(async () => {
    setIsLoading(true)
    try {
      const res = await apiV2.exercisesTimetableService.fetchPaymentTypesByTimetableId(scheduleItem.id, {
        phone: scheduleItem.client.phone,
      })
      const options = mapExercisesTimetablePaymentTypesToOptions(res.data)
      setPaymentTypes(options)
      return options
    } catch (error) {
      return []
    } finally {
      setIsLoading(false)
    }
  }, [scheduleItem])

  const onPayHandler = useCallback(async () => {
    const options = await fetchPaymentTypes()
    if (isDefAndNotEmpty(options)) {
      return options
    }
    onCreateTransaction(scheduleItem.client.phone, scheduleItem.client.id)
  }, [fetchPaymentTypes, onCreateTransaction, scheduleItem])

  return {
    paymentTypes,
    isLoading,
    fetchPaymentTypes,
    onPayHandler,
  }
}
