import {
  WalletOutlined,
  ClockCircleOutlined,
  GiftOutlined,
  SettingOutlined,
  EditTwoTone,
  StarOutlined,
  UserOutlined,
  FolderOpenOutlined,
  ApartmentOutlined,
} from '@ant-design/icons'

import {
  AppLayoutTopBarMenu,
  AppLayoutTopBarMenuItem,
} from '../../components/layouts/app-layout/app-layout-top-bar/app-layout-top-bar.types'
import { AppLayoutSidebarMenuItem } from '../../components/layouts/app-layout/app-layout-sidebar/app-layout-sidebar.types'
import {
  genClientsPagePath,
  genDirectionsPagePath,
  genExercisesTypesPagePath,
  genDiscountsPagePath,
  genEmployeesPagePath,
  genFranchisesPagePath,
  genProductsPagePath,
  genSchedulePagePath,
  genSettingsPagePath,
  genStudiosPagePath,
  genTransactionsPagePath,
  genSalariesPagePath,
  genScheduleManagementPagePath,
  genMasterServicesPagePath,
  genPositionsPagePath,
  genEmployeesSchedulePagePath,
  genCustomFieldsPagePath,
  genGoodsPagePath,
  genReportsPagePath,
  genScheduleLongtermPagePath,
  genContractsPagePath,
} from '../../format/path.format'
import { formatPathName, formatUserType } from '../../format/text.format'
import { AppPath } from '../../types/path.types'
import { UserType } from '../../types/users.types'
import { ProductsPageSection } from '../../pages/products-page/products-page.types'
import { isDef, NString } from '../../types/lang.types'

export function genAppLayoutSidebarMenu(
  studioId: NString,
  transactionPermission: boolean,
  timetableReadPermission: boolean
): AppLayoutSidebarMenuItem[] {
  return [
    isDef(studioId)
      ? {
          Icon: ClockCircleOutlined,
          title: formatPathName(AppPath.SCHEDULE),
          path: genSchedulePagePath(studioId),
          ...(timetableReadPermission && {
            items: {
              ItemIcon: EditTwoTone,
              path: genScheduleManagementPagePath(studioId),
            },
          }),
        }
      : null,
    isDef(studioId) && timetableReadPermission
      ? {
          Icon: ApartmentOutlined,
          title: formatPathName(AppPath.SCHEDULE_LONGTERM),
          path: genScheduleLongtermPagePath(studioId),
        }
      : null,
    // {
    //   Icon: BarChartOutlined,
    //   title: 'Аналитика',
    //   path: '/analytics',
    // },
    isDef(studioId) && transactionPermission
      ? {
          Icon: WalletOutlined,
          title: formatPathName(AppPath.TRANSACTIONS),
          path: genTransactionsPagePath(studioId),
        }
      : null,
    // {
    //   Icon: AlertOutlined,
    //   title: 'Оповещения',
    //   path: '/notifications',
    // },
    {
      Icon: GiftOutlined,
      title: formatPathName(AppPath.DISCOUNTS),
      path: genDiscountsPagePath(),
    },
    {
      Icon: SettingOutlined,
      title: formatPathName(AppPath.SETTINGS),
      path: genSettingsPagePath(),
    },
  ].reduce<AppLayoutSidebarMenuItem[]>((acc, it) => {
    if (isDef(it)) {
      acc.push(it)
    }

    return acc
  }, [])
}

export function genAppLayoutTopBarMenu(managementPermission: boolean): AppLayoutTopBarMenu[] {
  const menu = [
    {
      title: 'Клиенты',
      icon: StarOutlined,
      items: [
        {
          title: 'Все клиенты',
          path: genClientsPagePath(),
        },
      ],
    },
    {
      title: 'Сотрудники',
      icon: UserOutlined,
      items: [
        {
          title: formatUserType(UserType.Trainers),
          path: genEmployeesPagePath({ type: UserType.Trainers }),
        },
        {
          title: formatUserType(UserType.Administrators),
          path: genEmployeesPagePath({ type: UserType.Administrators }),
        },
        {
          title: formatUserType(UserType.Managers),
          path: genEmployeesPagePath({ type: UserType.Managers }),
        },
        {
          title: formatUserType(UserType.All),
          path: genEmployeesPagePath(),
        },
      ],
    },
    // {
    //   title: 'Аналитика',
    //   items: [
    //     {
    //       title: 'Вся аналитика',
    //       path: '/analytics',
    //     },
    //   ],
    // },
  ]
  if (managementPermission) {
    menu.push({
      title: 'Управление',
      icon: FolderOpenOutlined,
      items: [
        {
          title: formatPathName(AppPath.MASTER_SERVICES),
          path: genMasterServicesPagePath(),
        },
        {
          title: formatPathName(AppPath.DIRECTIONS),
          path: genDirectionsPagePath(),
        },
        {
          title: formatPathName(AppPath.GOODS),
          path: genGoodsPagePath(),
        },
        {
          title: formatPathName(AppPath.PRODUCTS),
          path: genProductsPagePath(ProductsPageSection.GROUP_SUBSCRIPTIONS),
        },
        {
          title: formatPathName(AppPath.EXERCISES_TYPES),
          path: genExercisesTypesPagePath(),
        },
        {
          title: formatPathName(AppPath.STUDIOS),
          path: genStudiosPagePath(),
        },
        {
          title: formatPathName(AppPath.SALARIES),
          path: genSalariesPagePath(),
        },
        {
          title: formatPathName(AppPath.POSITIONS),
          path: genPositionsPagePath(),
        },
        {
          title: formatPathName(AppPath.CUSTOM_FIELDS),
          path: genCustomFieldsPagePath(),
        },
        {
          title: formatPathName(AppPath.FRANCHISES),
          path: genFranchisesPagePath(),
        },
        {
          title: formatPathName(AppPath.EMPLOYEES_SCHEDULE),
          path: genEmployeesSchedulePagePath(),
        },
        // {
        //   title: formatPathName(AppPath.REPORTS),
        //   path: genReportsPagePath(),
        // },
        {
          title: formatPathName(AppPath.CONTRACTS),
          path: genContractsPagePath(),
        },
      ],
    })
  }
  return menu
}

export function genAppLayoutUserMenu(logout: () => void): AppLayoutTopBarMenuItem[] {
  return [
    {
      title: 'Выход',
      onClick: logout,
    },
  ]
}
