import { SearchOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { ProductsSubscriptionsTableColumnActions } from './products-subscriptions-table-column-actions/products-subscriptions-table-column-actions.component'
import { ProductsSubscriptionsTableColumnFormats } from './products-subscriptions-table-column-formats/products-subscriptions-table-column-formats.component'
import { ProductsSubscriptionsTableColumnStudios } from './products-subscriptions-table-column-studios/products-subscriptions-table-column-studios.component'
import { ProductsSubscriptionsTableColumnTypes } from './products-subscriptions-table-column-types/products-subscriptions-table-column-types.component'
import { ProductsSubscriptionsTableColumnVariant } from './products-subscriptions-table-column-variant/products-subscriptions-table-column-variant.component'
import { ProductsSubscriptionsTableActions, ProductsSubscriptionsTableRow } from './products-subscriptions-table.types'

export function genProductsSubscriptionsTableColumns(
  params: ProductsSubscriptionsTableActions
): ColumnsType<ProductsSubscriptionsTableRow> {
  const { onEdit, onRemove, onHistory, data } = params
  const subscriptionVariants = [
    { text: 'По часам', value: 'BY_TIME' },
    { text: 'По длительности', value: 'BY_DURATION' },
    { text: 'По записям', value: 'BY_VISITS' },
  ]
  const uniqueStudios: string[] = data?.reduce((names: string[], record: ProductsSubscriptionsTableRow) => {
    ;(record.availableStudios || []).forEach(studio => {
      if (!names.includes(studio.name)) {
        names.push(studio.name)
      }
    })
    return names
  }, [])
  const uniqueTypes: string[] = data?.reduce((types: string[], record: ProductsSubscriptionsTableRow) => {
    ;(record.availableTypes || []).forEach(type => {
      if (!types.includes(type.name)) {
        types.push(type.name)
      }
    })
    return types
  }, [])

  const filtersTypesOptions = uniqueTypes.length > 0 ? uniqueTypes.map(name => ({ text: name, value: name })) : []

  const filtersStudiosOptions = uniqueStudios.length > 0 ? uniqueStudios.map(name => ({ text: name, value: name })) : []
  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: name => name || '',
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Поиск абонемента"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: '100%', marginBottom: 8 }}
          >
            Поиск
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: '100%' }}>
            Сбросить
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        if (!record.name) return false
        const filterField = record.name.toLowerCase()
        const filterValue = String(value).toLowerCase()
        return filterField.includes(filterValue)
      },
    },
    {
      title: 'Формат абонемента',
      dataIndex: 'variant',
      key: 'variant',
      filters: subscriptionVariants,
      onFilter: (value, record) => record.variant === value,
      render: (_, subscription) => <ProductsSubscriptionsTableColumnVariant variant={subscription.variant} />,
    },
    {
      title: 'Условие формата',
      dataIndex: 'formats',
      key: 'formats',
      render: (_, subscription) => (
        <ProductsSubscriptionsTableColumnFormats
          minutes={subscription.minutes}
          variant={subscription.variant}
          visits={subscription.visits}
          duration={subscription.duration}
        />
      ),
    },
    {
      title: 'Локации',
      dataIndex: 'availableStudios',
      key: 'availableStudios',
      render: (_, subscription) => <ProductsSubscriptionsTableColumnStudios studios={subscription.availableStudios} />,
      filters: filtersStudiosOptions,
      onFilter: (value, record) => {
        const studios = record.availableStudios?.map(studio => studio.name) || []
        return studios.includes(String(value))
      },
    },
    {
      title: 'Категории записей',
      dataIndex: 'availableTypes',
      key: 'availableTypes',
      render: (_, subscription) => (
        <ProductsSubscriptionsTableColumnTypes availableTypes={subscription.availableTypes} />
      ),
      filters: filtersTypesOptions,
      onFilter: (value, record) => {
        const types = record.availableTypes?.map(type => type.name) || []
        return types.includes(String(value))
      },
    },

    {
      title: 'По времени',
      dataIndex: 'timeLimitation',
      key: 'timeLimitation',
      render: text => (text === 'NONE' ? 'Нет' : `Да` || '-'),
    },
    {
      title: 'Стоимость',
      dataIndex: 'cost',
      key: 'cost',
      render: text => `${text} ₽` || '-',
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: 'Действия',
      key: 'action',
      render: (_, { id, name, type }) => (
        <ProductsSubscriptionsTableColumnActions
          data={data}
          id={id}
          name={name}
          type={type}
          onEdit={onEdit}
          onRemove={onRemove}
          onHistory={onHistory}
        />
      ),
    },
  ]
}
