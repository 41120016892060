import { put, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { getFileLink } from '../../../common/files/files.selectors'
import { getContractDTO } from '../../../../mapping/contracts.mapping'
import { isDef } from '../../../../types/lang.types'
import { modalActions } from '../../../common/modal/modal.slice'
import { contractsInfoPageContractsActions } from './contracts-info-page-contracts.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { api } from '../../../../api/api'
import { contractsInfoPageDescriptionActions } from '../contracts-info-page-description/contracts-info-page-description.slice'

export function* createContract(action: ReturnType<typeof contractsInfoPageContractsActions.createContract>) {
  try {
    const link: string = yield select(getFileLink)
    const contractDTO = getContractDTO(action.payload, link)

    if (isDef(contractDTO)) {
      const { counterpartyId } = contractDTO
      yield callApi(api.contractsService.createContract, contractDTO)
      yield notification.success({
        message: 'Договор успешно создан',
      })
      yield put(modalActions.closeLast())
      yield put(contractsInfoPageDescriptionActions.fetchCounterpartiesById({ id: counterpartyId }))
    } else {
      yield put(contractsInfoPageContractsActions.createContractError(new Error()))
    }
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageContractsActions.createContractError(new Error()))
  }
}

export function* paidContract(action: ReturnType<typeof contractsInfoPageContractsActions.paidContract>) {
  try {
    const { id, data, counterpartyId } = action.payload

    yield callApi(api.contractsService.paidContract, id, { paymentAmount: data.paymentAmount })
    yield put(contractsInfoPageContractsActions.paidContractSuccess())
    yield notification.success({
      message: 'Договор успешно оплачен',
    })
    yield put(contractsInfoPageDescriptionActions.fetchCounterpartiesById({ id: counterpartyId }))
    yield put(modalActions.closeLast())
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageContractsActions.paidContractError(new Error()))
  }
}

export function* deleteContract(action: ReturnType<typeof contractsInfoPageContractsActions.deleteContract>) {
  try {
    const { id, counterpartyId } = action.payload

    yield callApi(api.contractsService.deleteContract, id)
    yield put(contractsInfoPageContractsActions.deleteContractSuccess())
    yield put(contractsInfoPageDescriptionActions.fetchCounterpartiesById({ id: counterpartyId }))
    yield put(modalActions.closeLast())
  } catch (e) {
    console.error(e)
    yield put(contractsInfoPageContractsActions.paidContractError(new Error()))
  }
}

export function* contractsInfoPageContractsSagas() {
  yield takeLatest(contractsInfoPageContractsActions.createContract, createContract)
  yield takeLatest(contractsInfoPageContractsActions.paidContract, paidContract)
  yield takeLatest(contractsInfoPageContractsActions.deleteContract, deleteContract)
}
