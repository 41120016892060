import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleManagementPageModalEditRemoveExercisesPayload,
  ScheduleManagementPageModalEditUpdateExercisesCommentPayload,
  ScheduleManagementPageModalEditUpdateExercisesPayload,
} from './schedule-management-page-modal-edit.types'

export interface ScheduleManagementPageModalEditState {
  exercisesTimetable: Nullable<ExercisesTimetableApi.ExercisesTimetable>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleManagementPageModalEditState = {
  exercisesTimetable: null,
  isLoading: false,
  error: null,
}

export const { actions: scheduleManagementPageModalEditActions, reducer: scheduleManagementPageModalEditReducer } =
  createSlice({
    name: '@@schedule-management-page-modal-edit',
    initialState,
    reducers: {
      updateExercises: (
        state: Draft<ScheduleManagementPageModalEditState>,
        _action: PayloadAction<ScheduleManagementPageModalEditUpdateExercisesPayload>
      ) => {
        state.isLoading = true
      },
      updateExercisesSuccess: (state: Draft<ScheduleManagementPageModalEditState>): void => {
        state.isLoading = false
      },
      updateExercisesError: (
        state: Draft<ScheduleManagementPageModalEditState>,
        action: PayloadAction<Error>
      ): void => {
        state.error = action.payload
        state.isLoading = false
      },
      updateExercisesComment: (
        state: Draft<ScheduleManagementPageModalEditState>,
        _action: PayloadAction<ScheduleManagementPageModalEditUpdateExercisesCommentPayload>
      ) => {
        state.isLoading = true
      },
      updateExercisesCommentSuccess: (state: Draft<ScheduleManagementPageModalEditState>): void => {
        state.isLoading = false
      },
      updateExercisesCommentError: (
        state: Draft<ScheduleManagementPageModalEditState>,
        action: PayloadAction<Error>
      ): void => {
        state.error = action.payload
        state.isLoading = false
      },
      removeExercises: (
        state: Draft<ScheduleManagementPageModalEditState>,
        _action: PayloadAction<ScheduleManagementPageModalEditRemoveExercisesPayload>
      ) => {
        state.isLoading = true
      },
      removeExercisesSuccess: (state: Draft<ScheduleManagementPageModalEditState>): void => {
        state.isLoading = false
      },
      removeExercisesError: (
        state: Draft<ScheduleManagementPageModalEditState>,
        action: PayloadAction<Error>
      ): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })
