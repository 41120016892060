import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { contractsInfoPageDescriptionActions } from '../../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.slice'
import { ContractsApi } from '../../../../api/types/contracts-api.types'

export const useDescriptionTabs = () => {
  const dispatch = useDispatch()

  const onEditCounterpartyHandler = useCallback(
    (id: string, clientsFormValues: ContractsApi.CounterpartiesDTO): void => {
      dispatch(
        contractsInfoPageDescriptionActions.updateCounterpartiesById({
          id,
          data: clientsFormValues,
        })
      )
    },
    [dispatch]
  )

  return {
    onEditCounterpartyHandler,
  }
}
