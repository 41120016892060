import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { notification } from 'antd'

import { AppPath } from '../../types/path.types'
import { genHomePagePath } from '../../format/path.format'

interface Props extends RouteProps<AppPath> {
  hasPermission?: boolean
}

export const PrivateRoute: React.FC<React.PropsWithChildren<Props>> = props => {
  const { hasPermission = true, ...rest } = props

  const { keycloak } = useKeycloak()

  const isLoggedIn = keycloak.authenticated

  if (!hasPermission) {
    notification.error({
      message: 'У вас нет разрешения на это действие',
    })

    return <Redirect to={genHomePagePath()} />
  }

  if (isLoggedIn) {
    return <Route {...rest} />
  }

  return null
}
