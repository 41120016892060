import { combineReducers } from '@reduxjs/toolkit'

import { contractsInfoPageContractsReducer } from './contracts-info-page-contracts/contracts-info-page-contracts.slice'
import { contractsInfoPageClientsReducer } from './contracts-info-page-clients/contracts-info-page-clients.slice'
import { contractsInfoPageDescriptionReducer } from './contracts-info-page-description/contracts-info-page-description.slice'

export const contractsInfoPageReducer = combineReducers({
  contractsInfoPageClients: contractsInfoPageClientsReducer,
  contractsInfoPageDescription: contractsInfoPageDescriptionReducer,
  contractsInfoPageContracts: contractsInfoPageContractsReducer,
})
