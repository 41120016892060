import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { StudiosApi } from '../../../../api/types/studios-api.types'
import { TrainersApi } from '../../../../api/types/trainers-api.types'
import { ScheduleExercisesFilter } from '../../../../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleManagementPagePeekAboutFetchExercisesPayload,
  ScheduleManagementPagePeekAboutFetchPeekDataSuccessPayload,
  ScheduleManagementPagePeekAboutRemoveExercisePayload,
  ScheduleManagementPagePeekAboutUpdateCommentPayload,
} from './schedule-management-page-peek-about.types'

export interface ScheduleManagementPagePeekAbout {
  timetable: Nullable<ExercisesTimetableApi.ExercisesTimetable>
  exercises: Nullable<Pagination<ExercisesTimetableApi.ExercisesTimetableExercise>>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  exercisesFilter: ScheduleExercisesFilter
  isTimetableLoading: boolean
  isExercisesLoading: boolean
  isCommentLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleManagementPagePeekAbout = {
  timetable: null,
  exercises: null,
  studios: null,
  trainers: null,
  exercisesFilter: {},
  isTimetableLoading: false,
  isExercisesLoading: false,
  isCommentLoading: false,
  error: null,
}

export const { actions: scheduleManagementPagePeekAboutActions, reducer: scheduleManagementPagePeekAboutReducer } =
  createSlice({
    name: '@@schedule-management-page-peek-about',
    initialState,
    reducers: {
      fetchPeekData: (state: Draft<ScheduleManagementPagePeekAbout>, _: PayloadAction<string>): void => {
        state.isTimetableLoading = true
      },
      fetchPeekDataSuccess: (
        state: Draft<ScheduleManagementPagePeekAbout>,
        action: PayloadAction<ScheduleManagementPagePeekAboutFetchPeekDataSuccessPayload>
      ): void => {
        state.timetable = action.payload.timetable
        state.studios = action.payload.studios
        state.trainers = action.payload.trainers
        state.isTimetableLoading = false
      },
      fetchPeekDataError: (state: Draft<ScheduleManagementPagePeekAbout>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isTimetableLoading = false
      },
      fetchTimetable: (state: Draft<ScheduleManagementPagePeekAbout>, _: PayloadAction<string>): void => {
        state.isTimetableLoading = true
      },
      fetchTimetableSuccess: (
        state: Draft<ScheduleManagementPagePeekAbout>,
        action: PayloadAction<Nullable<ExercisesTimetableApi.ExercisesTimetable>>
      ): void => {
        state.timetable = action.payload
        state.isTimetableLoading = false
      },
      fetchTimetableError: (state: Draft<ScheduleManagementPagePeekAbout>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isTimetableLoading = false
      },
      fetchExercises: (
        state: Draft<ScheduleManagementPagePeekAbout>,
        _: PayloadAction<ScheduleManagementPagePeekAboutFetchExercisesPayload>
      ): void => {
        state.isExercisesLoading = true
      },
      fetchExercisesSuccess: (
        state: Draft<ScheduleManagementPagePeekAbout>,
        action: PayloadAction<Nullable<Pagination<ExercisesTimetableApi.ExercisesTimetableExercise>>>
      ): void => {
        state.exercises = action.payload
        state.isExercisesLoading = false
      },
      fetchExercisesError: (state: Draft<ScheduleManagementPagePeekAbout>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isExercisesLoading = false
      },
      setExercisesFilter: (
        state: Draft<ScheduleManagementPagePeekAbout>,
        action: PayloadAction<{ name: string; value?: string | string[] }>
      ): void => {
        state.exercisesFilter = {
          ...state.exercisesFilter,
          [action.payload.name]: action.payload.value,
        }
      },
      setDefaultFilter: (state: Draft<ScheduleManagementPagePeekAbout>): void => {
        state.exercisesFilter = {}
      },
      resetExercisesFilter: (state: Draft<ScheduleManagementPagePeekAbout>): void => {
        state.exercisesFilter = {
          timeFrom: undefined,
          timeTo: undefined,
          dayOfWeek: undefined,
          roomId: undefined,
          trainerIds: undefined,
          timeScope: undefined,
          bookingPaymentStatus: undefined,
        }
      },
      updateComment: (
        state: Draft<ScheduleManagementPagePeekAbout>,
        _: PayloadAction<ScheduleManagementPagePeekAboutUpdateCommentPayload>
      ): void => {
        state.isCommentLoading = true
      },
      updateCommentSuccess: (state: Draft<ScheduleManagementPagePeekAbout>): void => {
        state.isCommentLoading = false
      },
      updateCommentError: (state: Draft<ScheduleManagementPagePeekAbout>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isCommentLoading = false
      },
      removeExercise: (
        state: Draft<ScheduleManagementPagePeekAbout>,
        _: PayloadAction<ScheduleManagementPagePeekAboutRemoveExercisePayload>
      ) => {
        state.isExercisesLoading = true
      },
      removeExerciseSuccess: (state: Draft<ScheduleManagementPagePeekAbout>) => {
        state.isExercisesLoading = false
      },
      removeExerciseError: (state: Draft<ScheduleManagementPagePeekAbout>, action: PayloadAction<Error>) => {
        state.isExercisesLoading = false
        state.error = action.payload
      },
      reset: () => initialState,
    },
  })
