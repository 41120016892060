import * as React from 'react'

import { useSchedulePageFilter } from './schedule-page-filter.hook'
import { ExercisesFilters } from '../../../components/exercises/exercises-filters/exercises-filters.component'

export const SchedulePageFilter: React.FC = () => {
  const {
    form,
    trainerOptions,
    directionOptions,
    recordOptions,
    roomOptions,
    paymentTypesOptions,
    clientBookingCreatePermission,
  } = useSchedulePageFilter()

  return (
    <ExercisesFilters
      form={form}
      trainerOptions={trainerOptions}
      directionOptions={directionOptions}
      roomOptions={roomOptions}
      recordOptions={recordOptions}
      paymentTypesOptions={paymentTypesOptions}
      clientBookingCreatePermission={clientBookingCreatePermission}
    />
  )
}
