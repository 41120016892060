import { put, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { modalActions } from '../../common/modal/modal.slice'
import { getCounterpartiesDTO } from '../../../mapping/contracts.mapping'
import { isDef } from '../../../types/lang.types'
import { UserType } from '../../../types/users.types'
import { contractsPageActions } from './contracts-page.slice'
import { api } from '../../../api/api'
import { callApi } from '../../../utils/sagas.utils'

export function* fetchAllCounterparties(_: ReturnType<typeof contractsPageActions.fetchAllCounterparties>) {
  try {
    const response: Awaited<ReturnType<typeof api.contractsService.fetchAll>> = yield callApi(
      api.contractsService.fetchAll,
      { size: 300 }
    )

    yield put(contractsPageActions.fetchAllCounterpartiesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(contractsPageActions.fetchAllCounterpartiesError(new Error()))
  }
}

export function* fetchAllManagers(_: ReturnType<typeof contractsPageActions.fetchAllManagers>) {
  try {
    const managers: Awaited<ReturnType<typeof api.employeesService.fetchAll>> = yield callApi(
      api.employeesService.fetchAll,
      { size: 100 },
      UserType.Managers
    )

    yield put(contractsPageActions.fetchAllManagersSuccess(managers.data))
  } catch (e) {
    yield put(contractsPageActions.fetchAllManagersError(new Error()))
  }
}

export function* updateManager(action: ReturnType<typeof contractsPageActions.updateManager>) {
  try {
    const { contractId, managerId, successCallback } = action.payload

    yield callApi(api.contractsService.updateManager, contractId, managerId)
    yield put(contractsPageActions.updateManagerSuccess(managerId))

    yield notification.success({
      message: 'Менеджер успешно обновлен',
    })
    if (successCallback) successCallback()
  } catch {
    yield put(contractsPageActions.updateManagerError(new Error()))
  }
}

export function* createCounterparties(action: ReturnType<typeof contractsPageActions.createCounterparties>) {
  try {
    const counterpartiesDTO = getCounterpartiesDTO(action.payload)

    if (isDef(counterpartiesDTO)) {
      yield callApi(api.contractsService.createCounterparties, counterpartiesDTO)
      yield put(contractsPageActions.createCounterpartiesSuccess())
      yield put(contractsPageActions.fetchAllCounterparties())
      yield put(modalActions.closeLast())

      yield notification.success({
        message: 'Контрагент успешно создан',
      })
    } else {
      yield put(contractsPageActions.createCounterpartiesError(new Error()))
    }
  } catch (e) {
    yield put(contractsPageActions.createCounterpartiesError(new Error()))
  }
}

export function* contractsPageSagas() {
  yield takeLatest(contractsPageActions.fetchAllCounterparties, fetchAllCounterparties)
  yield takeLatest(contractsPageActions.fetchAllManagers, fetchAllManagers)
  yield takeLatest(contractsPageActions.updateManager, updateManager)
  yield takeLatest(contractsPageActions.createCounterparties, createCounterparties)
}
