import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { ContractsInfoPageSection } from '../../contracts-info-page.types'
import { useContractsInfoPageParams } from '../../contracts-info-page-hooks/contracts-info-page.hook'
import { genPaginationConfig } from '../../../../utils/pagination.utils'
import { genClientsEditPagePath, genContractsInfoPagePath } from '../../../../format/path.format'
import {
  getClientsIsLoaded,
  getClientsIsLoading,
  getCounterpartyClientsTotalElements,
  getCounterpartyFormValue,
} from '../../../../store/pages/contracts-info-page/contracts-info-page-clients/contracts-info-page-clients.selector'
import { contractsInfoPageClientsActions } from '../../../../store/pages/contracts-info-page/contracts-info-page-clients/contracts-info-page-clients.slice'

export const useClientsTabs = (id: string) => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { page, size } = useContractsInfoPageParams()

  const clients = useSelector(getCounterpartyFormValue)
  const totalElements = useSelector(getCounterpartyClientsTotalElements)
  const isLoading = useSelector(getClientsIsLoading)
  const isLoaded = useSelector(getClientsIsLoaded)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])

  const onInfoHandler = React.useCallback(
    (id: string): void => {
      push(genClientsEditPagePath({ id }))
    },
    [push]
  )

  const onRemoveHandler = React.useCallback(
    (clientId: string): void => {
      dispatch(contractsInfoPageClientsActions.removeClient({ counterpartyId: id, clientId }))
    },
    [dispatch, id]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genContractsInfoPagePath({ section: ContractsInfoPageSection.CLIENTS, id }, { page: page, size: pageSize }))
    },
    [id, push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genContractsInfoPagePath({ section: ContractsInfoPageSection.CLIENTS, id }, { page, size: pageSize }))
    },
    [id, page, push]
  )

  React.useEffect(() => {
    dispatch(contractsInfoPageClientsActions.fetchAllClients({ id, page, size }))
  }, [dispatch, id, page, size])

  React.useEffect(() => {
    return () => {
      dispatch(contractsInfoPageClientsActions.reset())
    }
  }, [dispatch])

  return {
    clients,
    pagination,
    isLoading,
    isLoaded,
    onInfoHandler,
    onRemoveHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}
