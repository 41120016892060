import React, { useEffect } from 'react'
import { Button, Form, InputNumber, Select, Table } from 'antd'
import { FieldData } from 'rc-field-form/lib/interface'
import { DefaultOptionType } from 'antd/lib/select'
import { PlusCircleOutlined } from '@ant-design/icons'

import { SalariesTableActions } from './salaries-table-actions/salaries-table-actions.component'
import './salaries-table.styles.less'
import { useSalariesTable } from './salaries-table-hook'
import { keysOfSalariesFormValues } from '../salaries-form/salaries-form-types'
import { CustomCard } from '../../custom-card/custom-card.component'

interface Props {
  rates: FieldData[]
  add: () => void
  onRemove: (index: number) => void
  remove: (index: number) => void
  gradesOptions?: DefaultOptionType[]
  studiosOptions?: DefaultOptionType[]
  directionsOptions?: DefaultOptionType[]
  exercisesTypeOptions?: DefaultOptionType[]
  loading: boolean
  editingIndex: number | undefined
  setEditingIndex: (value: undefined | number) => void
  onDeselectHandler: (value: string | number, index: number, key: keysOfSalariesFormValues) => void
}

export const SalariesTable: React.FC<Props> = props => {
  const {
    rates,
    gradesOptions,
    studiosOptions,
    directionsOptions,
    exercisesTypeOptions,
    loading,
    editingIndex,
    add,
    onRemove,
    onDeselectHandler,
    remove,
    setEditingIndex,
  } = props

  const {
    gradesValidationRules,
    studiosValidationRules,
    directionsValidationRules,
    exerciseTypesValidationRules,
    rateValidationRules,
  } = useSalariesTable()

  const addRates = () => {
    add()
    setEditingIndex(rates.length)
  }

  const onCancel = (index: number) => {
    remove(index)
    setEditingIndex(undefined)
  }

  return (
    <CustomCard>
      <Table
        scroll={{ x: 1300 }}
        dataSource={rates}
        pagination={false}
        className="table"
        loading={loading}
        footer={() => (
          <Button type="primary" size="middle" icon={<PlusCircleOutlined />} onClick={addRates} ghost>
            Добавить условие
          </Button>
        )}
      >
        <Table.Column
          dataIndex={'gradeId'}
          title={'Грейд'}
          render={(value, row, index) => {
            return (
              <Form.Item
                name={[index, 'gradeIds']}
                className={'ant-form-item-no-bottom-margin'}
                rules={gradesValidationRules}
              >
                <Select
                  placeholder="Выбери"
                  options={gradesOptions}
                  mode="multiple"
                  onDeselect={(value: string) => onDeselectHandler(value, index, 'gradeIds')}
                />
              </Form.Item>
            )
          }}
        />
        <Table.Column
          dataIndex={'studioIds'}
          title={'Локации'}
          render={(value, row, index) => {
            return (
              <Form.Item name={[index, 'studioIds']} rules={studiosValidationRules}>
                <Select
                  placeholder="Выбери"
                  options={studiosOptions}
                  mode="multiple"
                  className={'ant-form-item-no-bottom-margin'}
                  onDeselect={(value: string) => onDeselectHandler(value, index, 'studioIds')}
                />
              </Form.Item>
            )
          }}
        />
        <Table.Column
          dataIndex={'directionIds'}
          title={'Направления'}
          render={(value, row, index) => {
            return (
              <Form.Item name={[index, 'directionIds']} rules={directionsValidationRules}>
                <Select
                  placeholder="Выбери"
                  options={directionsOptions}
                  mode="multiple"
                  className={'ant-form-item-no-bottom-margin'}
                  onDeselect={(value: number) => onDeselectHandler(value, index, 'directionIds')}
                />
              </Form.Item>
            )
          }}
        />
        <Table.Column
          dataIndex={'exerciseTypeIds'}
          title={'Категории записей'}
          render={(value, row, index) => {
            return (
              <Form.Item name={[index, 'exerciseTypeIds']} rules={exerciseTypesValidationRules}>
                <Select
                  placeholder="Выбери"
                  options={exercisesTypeOptions}
                  mode="multiple"
                  className={'ant-form-item-no-bottom-margin'}
                  onDeselect={(value: number) => onDeselectHandler(value, index, 'exerciseTypeIds')}
                />
              </Form.Item>
            )
          }}
        />
        <Table.Column
          dataIndex={'rate'}
          title={'Ставка'}
          render={(value, row, index) => {
            return (
              <>
                <Form.Item
                  name={[index, 'rate']}
                  className={'ant-form-item-no-bottom-margin'}
                  rules={rateValidationRules}
                >
                  <InputNumber placeholder="Ставка" />
                </Form.Item>
              </>
            )
          }}
        />
        <Table.Column
          dataIndex={'actions'}
          title={'Операции'}
          render={(value, row, index) => {
            return (
              <SalariesTableActions
                index={index}
                onRemove={index => onRemove(index)}
                onCancel={onCancel}
                editingIndex={editingIndex}
              />
            )
          }}
        />
      </Table>
    </CustomCard>
  )
}
