import React, { useState } from 'react'
import { Button, Col, Flex, Form, Select, Typography } from 'antd'
import { FilterOutlined, PlusOutlined } from '@ant-design/icons'

import { useContractsHeader } from './contracts-page-header.hook'
import style from './contracts-page-header.module.scss'

export const ContractsHeader = () => {
  const [isOpenFilters, setIsOpenFilters] = useState(false)
  const { onCreateCounterpartiesHandler } = useContractsHeader()

  function handleOpenFilters() {
    setIsOpenFilters(prev => !prev)
  }

  return (
    <Col className={style.container}>
      <Flex justify={'space-between'} align={'center'}>
        <Flex gap={'middle'} justify={'center'} align={'center'}>
          <Typography.Title level={2} className={style.title}>
            Контрагенты
          </Typography.Title>
          {/*<Button icon={<FilterOutlined style={{ color: '#0958D9' }} />} onClick={handleOpenFilters}>*/}
          {/*  Фильтры*/}
          {/*</Button>*/}
        </Flex>
        <Button icon={<PlusOutlined color={'white'} />} type={'primary'} onClick={onCreateCounterpartiesHandler}>
          Добавить контрагента
        </Button>
      </Flex>
      {isOpenFilters && (
        <Form.Item name="counterparties">
          <Select
            mode="multiple"
            placeholder="Фильтр контрагентов"
            onChange={() => console.log('placeholder')}
            value={'test'}
          />
        </Form.Item>
      )}
    </Col>
  )
}
