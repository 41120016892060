import { LOCAL_STORAGE_SELECTED_DATE_KEY } from '@constants/local-storage'
import qs from 'qs'

import {
  ContractsInfoPageQueryParams,
  ContractsInfoUrlParams,
} from '../pages/contracts-info-page/contracts-info-page.types'
import {
  ClientsEditPageQueryParams,
  ClientsEditPageUrlParams,
} from '../pages/clients-edit-page/clients-edit-page.types'
import { ClientsPageParams } from '../pages/clients-page/clients-page.types'
import { CustomFieldsPageParams } from '../pages/custom-fields-page/studios-feilds-page.types'
import { DirectionsEditPageParams } from '../pages/directions-edit-page/directions-edit-page.types'
import { DirectionsPageParams } from '../pages/directions-page/directions-page.types'
import { EmployeesEditPageParams } from '../pages/employees-edit-page/employees-edit-page.types'
import { EmployeesPageParams } from '../pages/employees-page/employees-page.types'
import { ExercisesTypesEditPageParams } from '../pages/exercises-types-edit-page/exercises-types-edit-page.types'
import { ExercisesTypesPageParams } from '../pages/exercises-types-page/exercises-types-page.types'
import { FranchisesEditPageParams } from '../pages/franchises-edit-page/franchises-edit-page.types'
import { FranchisesPageParams } from '../pages/franchises-page/franchises-page.types'
import { MasterServicesPageParams } from '../pages/master-services-page/master-services-page.types'
import { PositionsPageParams } from '../pages/positions-page/positions-page.types'
import { ProductsPageQueryParams, ProductsPageSection } from '../pages/products-page/products-page.types'
import { ServicesEditPageParams } from '../pages/products-services-edit-page/products-services-edit-page.types'
import { ProductEditPageParams } from '../pages/products-subscriptions-edit-page/products-subscriptions-edit-page.types'
import {
  ScheduleGroupPageQueryParams,
  ScheduleGroupPageUrlParams,
} from '../pages/schedule-group-page/schedule-group-page.types'
import { ScheduleLongtermPageQueryParams } from '../pages/schedule-longterm-page/schedule-longterm-page.types'
import { ScheduleManagementPageQueryParams } from '../pages/schedule-management-page/schedule-management-page.types'
import { SchedulePageQueryParams } from '../pages/schedule-page/schedule-page.types'
import { StudiosPageParams } from '../pages/studios-page/studios-page.types'
import { TransactionsPageQueryParams } from '../pages/transactions-page/transactions-page.types'
import { isDef } from '../types/lang.types'
import { AppPath } from '../types/path.types'
import { buildPath } from '../utils/utls.utils'

export function genHomePagePath(): string {
  return buildPath(AppPath.HOME)
}

export function genSchedulePagePath(studioId: string, queryParams?: SchedulePageQueryParams): string {
  const storedDate = localStorage.getItem(LOCAL_STORAGE_SELECTED_DATE_KEY)
  const dateParam = storedDate ? { date: storedDate } : {}

  const mergedQueryParams = { ...queryParams, ...dateParam }

  const search = isDef(mergedQueryParams) ? qs.stringify(mergedQueryParams, { skipNulls: true }) : null
  const path = buildPath(AppPath.SCHEDULE, { studioId })

  return isDef(search) ? `${path}?${search}` : path
}

export function genScheduleGroupPagePath(
  urlParams: ScheduleGroupPageUrlParams,
  queryParams?: ScheduleGroupPageQueryParams
): string {
  const { studioId, scheduleId, section } = urlParams
  const search = isDef(queryParams) ? qs.stringify(queryParams, { skipNulls: true }) : null
  const path = buildPath(AppPath.SCHEDULE_GROUP, { studioId, scheduleId, section })

  return isDef(search) ? `${path}?${search}` : path
}

export function genScheduleManagementPagePath(
  studioId: string,
  queryParams?: ScheduleManagementPageQueryParams
): string {
  const search = isDef(queryParams) ? qs.stringify(queryParams, { skipNulls: true }) : null
  const path = buildPath(AppPath.SCHEDULE_MANAGEMENT, { studioId })

  return isDef(search) ? `${path}?${search}` : path
}

export function genScheduleManagementEditPagePath(studioId: string, scheduleId: string): string {
  return buildPath(AppPath.SCHEDULE_MANAGEMENT_EDIT, { studioId, scheduleId })
}

export function genScheduleLongtermPagePath(studioId: string, queryParams?: ScheduleLongtermPageQueryParams): string {
  const search = isDef(queryParams) ? qs.stringify(queryParams, { skipNulls: true }) : null
  const path = buildPath(AppPath.SCHEDULE_LONGTERM, { studioId })

  return isDef(search) ? `${path}?${search}` : path
}

export function genScheduleLongtermEditPagePath(studioId: string, scheduleId: string): string {
  return buildPath(AppPath.SCHEDULE_LONGTERM_EDIT, { studioId, scheduleId })
}

export function genProductsPagePath(section: ProductsPageSection, params?: ProductsPageQueryParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.PRODUCTS, { section })

  return isDef(search) ? `${path}?${search}` : path
}

export function genProductSubscriptionCreatePagePath({ type }: ProductEditPageParams): string {
  return buildPath(AppPath.PRODUCTS_CREATE_SUBSCRIPTION, { type })
}

export function genProductSubscriptionEditPagePath({ id, type }: ProductEditPageParams): string {
  return buildPath(AppPath.PRODUCTS_EDIT_SUBSCRIPTION, { id, type })
}

export function genProductServicesCreatePagePath(): string {
  return buildPath(AppPath.PRODUCTS_CREATE_SERVICE)
}

export function genProductServicesEditPagePath({ id }: ServicesEditPageParams): string {
  return buildPath(AppPath.PRODUCTS_EDIT_SERVICE, { id })
}

export function genDiscountsPagePath(): string {
  return buildPath(AppPath.DISCOUNTS)
}

export function genTransactionsPagePath(studioId: string, queryParams?: TransactionsPageQueryParams): string {
  const search = isDef(queryParams) ? qs.stringify(queryParams) : null
  const path = buildPath(AppPath.TRANSACTIONS, { studioId })

  return isDef(search) ? `${path}?${search}` : path
}

export function genTransactionsCreatePagePath(
  studioId: string,
  queryParams?: {
    view?: boolean
    transactionId?: string
  }
): string {
  const search = isDef(queryParams) ? qs.stringify(queryParams) : null
  const path = buildPath(AppPath.TRANSACTIONS_CREATE, { studioId })

  return isDef(search) ? `${path}?${search}` : path
}

export function genStudiosPagePath(params?: StudiosPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.STUDIOS)

  return isDef(search) ? `${path}?${search}` : path
}

export function genStudiosCreatePagePath(): string {
  return buildPath(AppPath.STUDIOS_CREATE)
}

export function genStudiosEditPagePath(id: string): string {
  return buildPath(AppPath.STUDIOS_EDIT, { id })
}

export function genSalariesPagePath(): string {
  return buildPath(AppPath.SALARIES)
}

export function genSettingsPagePath(): string {
  return buildPath(AppPath.SETTINGS)
}

export function genFranchisesPagePath(params?: FranchisesPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.FRANCHISES)

  return isDef(search) ? `${path}?${search}` : path
}

export function genFranchisesCreatePagePath(): string {
  return buildPath(AppPath.FRANCHISES_CREATE)
}

export function genFranchisesEditPagePath({ id }: FranchisesEditPageParams): string {
  return buildPath(AppPath.FRANCHISES_EDIT, { id })
}

export function genCustomFieldsPagePath(params?: CustomFieldsPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.CUSTOM_FIELDS)

  return isDef(search) ? `${path}?${search}` : path
}

export function genEmployeesPagePath(params?: EmployeesPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.EMPLOYEES)

  return isDef(search) ? `${path}?${search}` : path
}

export function genEmployeesSchedulePagePath(params?: FranchisesPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.EMPLOYEES_SCHEDULE)

  return isDef(search) ? `${path}?${search}` : path
}

export function genEmployeesCreatePagePath(): string {
  return buildPath(AppPath.EMPLOYEES_CREATE)
}

export function genEmployeesEditPagePath({ id }: EmployeesEditPageParams): string {
  return buildPath(AppPath.EMPLOYEES_EDIT, { id })
}

export function genDirectionsPagePath(params?: DirectionsPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.DIRECTIONS)

  return isDef(search) ? `${path}?${search}` : path
}

export function genGoodsPagePath(): string {
  return buildPath(AppPath.GOODS)
}

export function genDirectionsCreatePagePath(): string {
  return buildPath(AppPath.DIRECTIONS_CREATE)
}

export function genDirectionsEditPagePath({ id }: DirectionsEditPageParams): string {
  return buildPath(AppPath.DIRECTIONS_EDIT, { id })
}

export function genExercisesTypesPagePath(params?: ExercisesTypesPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.EXERCISES_TYPES)

  return isDef(search) ? `${path}?${search}` : path
}

export function genExercisesTypesCreatePagePath(): string {
  return buildPath(AppPath.EXERCISES_TYPES_CREATE)
}

export function genExercisesTypesEditPagePath({ id }: ExercisesTypesEditPageParams): string {
  return buildPath(AppPath.EXERCISES_TYPES_EDIT, { id })
}

export function genClientsPagePath(params?: ClientsPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.CLIENTS)

  return isDef(search) ? `${path}?${search}` : path
}

export function genClientsCreatePagePath(): string {
  return buildPath(AppPath.CLIENTS_CREATE)
}

export function genClientsEditPagePath(
  urlParams: ClientsEditPageUrlParams,
  queryParams?: ClientsEditPageQueryParams
): string {
  const { id, section } = urlParams

  const search = isDef(queryParams) ? qs.stringify(queryParams) : null

  const path = buildPath(AppPath.CLIENTS_EDIT, { id, section })

  return isDef(search) ? `${path}?${search}` : path
}

export function genMasterServicesPagePath(params?: MasterServicesPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.MASTER_SERVICES)

  return isDef(search) ? `${path}?${search}` : path
}

export function genMasterServicesCreatePagePath(): string {
  return buildPath(AppPath.MASTER_SERVICES_CREATE)
}

export function genMasterServicesEditPagePath(id: string): string {
  return buildPath(AppPath.MASTER_SERVICES_EDIT, { id })
}

export function genMasterServicesWidgetCreatePagePath(): string {
  return buildPath(AppPath.MASTER_SERVICES_WIDGET_CREATE)
}

export function genPositionsPagePath(params?: PositionsPageParams): string {
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.POSITIONS)

  return isDef(search) ? `${path}?${search}` : path
}

export function genPositionsCreatePagePath(): string {
  return buildPath(AppPath.POSITIONS_CREATE)
}

export function genPositionsEditPagePath(id: string): string {
  return buildPath(AppPath.POSITIONS_EDIT, { id })
}

export function genReportsPagePath(): string {
  return buildPath(AppPath.REPORTS)
}

export function genContractsPagePath(): string {
  return buildPath(AppPath.CONTRACTS)
}

export function genContractsInfoPagePath(
  urlParams: ContractsInfoUrlParams,
  params?: ContractsInfoPageQueryParams
): string {
  const { id, section } = urlParams
  const search = isDef(params) ? qs.stringify(params) : null
  const path = buildPath(AppPath.CONTRACTS_INFO, { id, section })

  return isDef(search) ? `${path}?${search}` : path
}
