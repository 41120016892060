import React from 'react'
import { Flex } from 'antd'

import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { useContractsPage } from './contracts-page.hook'
import { ContractsHeader } from './contracts-page-header/contracts-page-header.component'
import { ContractsCardList } from './contracts-card-list/contracts-card-list.component'

export const ContractsPage = () => {
  const { isLoading } = useContractsPage()

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Flex vertical gap={'large'} style={{ height: '100%' }}>
      <ContractsHeader />
      <ContractsCardList />
    </Flex>
  )
}
