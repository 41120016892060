import { Button, Modal } from 'antd'

import { AppModalBaseProps } from '../../../../types/modal.types'

export interface ContractModalConfirmProps extends AppModalBaseProps {
  handleOk: () => void
  handleCancel: () => void
}

export const ContractModalConfirm = ({ handleOk, handleCancel }: ContractModalConfirmProps) => {
  return (
    <Modal
      title="Удалить договор"
      visible={true}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" danger onClick={handleOk}>
          Удалить
        </Button>,
      ]}
    >
      <p>Вы уверены что хотите удалить договор?</p>
    </Modal>
  )
}
