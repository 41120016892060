import qs from 'qs'

import {
  CONTRACTS_INFO_PAGE_SIZE,
  ContractsInfoPageQueryParams,
  ContractsInfoPageSection,
  ContractsInfoPageUrlParams,
} from './contracts-info-page.types'
import { isDef, isString, NString } from '../../types/lang.types'
import { validateStrEnumValue } from '../../utils/enum.utils'

export function genContractsInfoPageUrlParams(params: ContractsInfoPageUrlParams) {
  const section =
    validateStrEnumValue<ContractsInfoPageSection>(ContractsInfoPageSection, params.section) ||
    ContractsInfoPageSection.CONTRACTS

  return { section }
}

export function genContractsInfoPageQueryParams(search: NString): ContractsInfoPageQueryParams {
  const queryParams = isDef(search) ? qs.parse(search, { ignoreQueryPrefix: true }) : null

  const page = isString(queryParams?.page) ? Number(queryParams?.page) : null
  const size = isString(queryParams?.size) ? Number(queryParams?.size) : CONTRACTS_INFO_PAGE_SIZE

  return {
    size,
    page,
  }
}
