import { AxiosResponse } from 'axios'

import { Nullable } from '../../types/lang.types'
import { ContractsApi } from '../../api/types/contracts-api.types'
import { HttpConnector } from '../connectors/http.connector'
import { Pagination, PaginationParamsDTO } from '../../api/types/api.types'

export class ContractsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (params: { size: number }): Promise<AxiosResponse<Pagination<ContractsApi.Counterparties>>> => {
    return this.httpConnector.get<Pagination<ContractsApi.Counterparties>>('/contracts/counterparties', { params })
  }

  public fetchCounterpartiesById = (id: string): Promise<AxiosResponse<ContractsApi.Counterparties>> => {
    return this.httpConnector.get<ContractsApi.Counterparties>(`/contracts/counterparties/${id}`)
  }

  public updateCounterpartiesById = (
    id: string,
    data: ContractsApi.CounterpartiesDTO
  ): Promise<AxiosResponse<ContractsApi.CounterpartiesDTO>> => {
    return this.httpConnector.patch<ContractsApi.Counterparties>(`/contracts/counterparties/${id}`, data)
  }

  public fetchAllClients = (
    id: string,
    params?: Nullable<Partial<PaginationParamsDTO>>
  ): Promise<AxiosResponse<Pagination<ContractsApi.CounterpartiesClient>>> => {
    return this.httpConnector.get<Pagination<ContractsApi.CounterpartiesClient>>(
      `/contracts/counterparties/${id}/clients`,
      { params }
    )
  }

  public removeClient = (counterpartyId: string, clientId: string): Promise<void> => {
    return this.httpConnector.delete(`contracts/counterparties/${counterpartyId}/clients/${clientId}`)
  }

  public createClientCounterparty = (counterpartyId: string, clientPhone: { clientPhone: string }): Promise<void> => {
    return this.httpConnector.post(`contracts/counterparties/${counterpartyId}/clients`, clientPhone)
  }

  public createContract = (data: ContractsApi.ContractsDTO): Promise<void> => {
    return this.httpConnector.post(`/contracts`, data)
  }

  public fetchAllClientsContracts = (id: string): Promise<AxiosResponse<ContractsApi.Contracts[]>> => {
    return this.httpConnector.get<ContractsApi.Contracts[]>(`/contracts/clients/${id}`)
  }

  public updateManager = (id: string, data: ContractsApi.ManagerId): Promise<AxiosResponse<ContractsApi.Contracts>> => {
    return this.httpConnector.patch<ContractsApi.Contracts>(`/contracts/${id}`, data)
  }

  public createCounterparties = (
    data: ContractsApi.CounterpartiesDTO
  ): Promise<AxiosResponse<Pagination<ContractsApi.Counterparties>>> => {
    return this.httpConnector.post(`/contracts/counterparties`, data)
  }

  public paidContract = (id: string, data: ContractsApi.ContractPaidParams): Promise<void> => {
    return this.httpConnector.post(`/contracts/${id}/payed`, data)
  }

  public updateContract = (id: string, data: ContractsApi.ContractsDTO): Promise<void> => {
    return this.httpConnector.patch(`/contracts/${id}`, data)
  }

  public deleteContract = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/contracts/${id}`)
  }

  public deleteCounterpartiesById = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/contracts/counterparties/${id}`)
  }
}
