import { createSelector } from '@reduxjs/toolkit'

import { mapStudiosRoomsToOptions, mapStudiosRoomsToTags } from '../../../../mapping/studios-rooms.mapping'
import { mapExercisesTimetableToScheduleTableDataItems } from '../../../../mapping/exercises-timetable.mapping'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { Nullable } from '../../../../types/lang.types'
import { AppState } from '../../../app.store'
import { Pagination } from '../../../../api/types/api.types'

const genExercisesTimetableInternal = (
  state: AppState
): Nullable<Pagination<ExercisesTimetableApi.ExercisesTimetable>> =>
  state.scheduleManagementPage.scheduleManagementPageTable.exercisesTimetable

const genStudiosRoomsInternal = (state: AppState): Nullable<StudiosRoomsApi.StudioRoom[]> =>
  state.scheduleManagementPage.scheduleManagementPageTable.studiosRooms

export const genScheduleManagementPageTableIsLoading = (state: AppState): boolean =>
  state.scheduleManagementPage.scheduleManagementPageTable.isLoading

export const genScheduleManagementPageTableIsLoaded = (state: AppState): boolean =>
  state.scheduleManagementPage.scheduleManagementPageTable.isLoaded

export const genScheduleManagementPageTableIsCanceling = (state: AppState): boolean =>
  state.scheduleManagementPage.scheduleManagementPageTable.isCanceling

export const genScheduleManagementPageTableDataItems = createSelector(genExercisesTimetableInternal, timetable =>
  mapExercisesTimetableToScheduleTableDataItems(timetable?.content)
)

export const genScheduleManagementPageTableTotalElements = createSelector(
  genExercisesTimetableInternal,
  timetable => timetable?.totalElements
)

export const genScheduleManagementPageTableStudiosRoomsTags = createSelector(
  genStudiosRoomsInternal,
  mapStudiosRoomsToTags
)

export const genScheduleManagementPageTableStudiosRoomsOptions = createSelector(
  genStudiosRoomsInternal,
  mapStudiosRoomsToOptions
)
