import React, { useEffect, useState } from 'react'
import { Button, Flex, Form, Input, Select } from 'antd'
import { useParams } from 'react-router-dom'

import { ContractsPageInfoParams } from '../../../contracts-info-page.types'
import { useContractsInfoMainTabs } from './contracts-info-tabs-main.hook'
import { snoTypeOptionsObj } from '../../../../contracts-page/contracts-page-modal-create/contracts-modal-create.types'
import { genClientsFormPhoneValidationRules } from '../../../../../components/clients/clients-form/clients-form.utils'
import { InputPhoneNumber } from '../../../../../components/controls/input-phone-number/input-phone-number.component'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { DEFAULT_EMPTY_SYMBOL } from '../../../../../format/text.format'
import style from '../contracts-info-tabs-description.module.scss'

const snoTypeOptions: snoTypeOptionsObj[] = [
  {
    label: 'Общая система налогообложения (ОСНО)',
    value: 'osn',
  },
  {
    label: 'Патентная система налогообложения (ПСН)',
    value: 'patent',
  },
  {
    label: 'Упрощенная система налогообложения "Доходы" (УСН)',
    value: 'usn_income',
  },
  {
    label: 'Упрощенная система налогообложения "Доходы минус расходы" (УСН)',
    value: 'usn_income_outcome',
  },
  {
    label: 'Единый сельскохозяйственный налог (ЕСХН)',
    value: 'esn',
  },
  {
    label: 'Единый налог на вменённый доход (ЕНВД)',
    value: 'envd',
  },
]

interface MainTabsProps {
  onHandleEdit: (id: string, values: ContractsApi.CounterpartiesDTO) => void
}

export const MainTabs = ({ onHandleEdit }: MainTabsProps) => {
  const { id } = useParams<ContractsPageInfoParams>()
  const { form, counterpartyFormValues, onDeleteContractHandler } = useContractsInfoMainTabs(id)
  const [isModified, setIsModified] = useState(false)
  const [initialFormState, setInitialFormState] = useState<ContractsApi.CounterpartiesDTO>()

  function validateNumericInput(event: React.KeyboardEvent<HTMLInputElement>) {
    !/[0-9]/.test(event.key) && event.preventDefault()
  }

  useEffect(() => {
    setInitialFormState(form.getFieldsValue())
  }, [counterpartyFormValues, form])

  function handleOnChange() {
    const currentValue = form.getFieldsValue()
    setIsModified(JSON.stringify(initialFormState) !== JSON.stringify(currentValue))
  }

  function handleEditCounterparty(values: ContractsApi.CounterpartiesDTO) {
    if (isModified) {
      onHandleEdit(id, values)
      setInitialFormState(form.getFieldsValue())
      setIsModified(false)
    }
  }

  const onChangePhoneHandler = (value: string) => {
    form.setFieldValue('phoneNumber', value)
  }

  return (
    <Form
      autoComplete="off"
      form={form}
      style={{ marginTop: '24px' }}
      layout="vertical"
      name="form_in_modal"
      initialValues={{
        modifier: 'public',
      }}
      onFinish={handleEditCounterparty}
      onChange={handleOnChange}
    >
      <div className={style.mainTabsContainer}>
        <Flex vertical gap={'large'}>
          <Form.Item
            name="directorName"
            label="Генеральный директор/ответственный"
            rules={[
              {
                required: true,
                message: 'Введите Генерального директора компании!',
              },
            ]}
          >
            <Input type="textarea" />
          </Form.Item>
          <Form.Item
            name="companyName"
            label="Наименование компании"
            rules={[
              {
                required: true,
                message: 'Введите Наименование компании!',
              },
            ]}
          >
            <Input type="textarea" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Контактный Email"
            rules={[
              {
                required: true,
                message: 'Введите Контактный Email!',
              },
              {
                type: 'email',
                message: 'Невалидное значение',
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item name="phoneNumber" label="Контактный телефон" rules={genClientsFormPhoneValidationRules()}>
            <InputPhoneNumber
              value={form.getFieldValue('phoneNumber')}
              onKeyPress={validateNumericInput}
              onChange={onChangePhoneHandler}
              placeholder={''}
              onBlur={() => form.validateFields(['phoneNumber'])}
              onFocus={() =>
                form.setFields([
                  {
                    name: 'phoneNumber',
                    errors: [],
                  },
                ])
              }
            />
          </Form.Item>
          <Form.Item
            name="address"
            label="Юридический адрес"
            rules={[
              {
                required: true,
                message: 'Введите Юридический адрес!',
              },
            ]}
          >
            <Input type="textarea" />
          </Form.Item>
        </Flex>
        <Flex vertical gap={'large'}>
          <Form.Item
            name="inn"
            label="ИНН"
            rules={[
              {
                required: true,
                message: 'Введите ИНН!',
              },
              {
                min: 10,
                message: 'Длина ИНН - 10 или 12 символов (для физ. лиц)!',
              },
              {
                max: 12,
                message: 'Длина ИНН - 10 или 12 символов (для физ. лиц)!',
              },
            ]}
          >
            <Input type="textarea" onKeyPress={validateNumericInput} maxLength={12} />
          </Form.Item>
          <Form.Item
            name="kpp"
            label="КПП"
            rules={[
              {
                required: true,
                message: 'Введите КПП!',
              },
              {
                min: 9,
                message: 'Длина КПП - 9 символов!',
              },
              {
                max: 9,
                message: 'Длина КПП - 9 символов!',
              },
            ]}
          >
            <Input type="textarea" maxLength={9} onKeyPress={validateNumericInput} />
          </Form.Item>
          <Form.Item
            name="ogrn"
            label="ОГРН"
            rules={[
              {
                required: true,
                message: 'Введите ОГРН!',
              },
              {
                min: 13,
                message: 'Длина ОГРН - 13 или 15 символов (ИП)!',
              },
              {
                max: 15,
                message: 'Длина ОГРН - 13 или 15 символов (ИП)!',
              },
            ]}
          >
            <Input type="textarea" onKeyPress={validateNumericInput} maxLength={15} />
          </Form.Item>
          <Form.Item
            name="snoType"
            label="Система налогообложения"
            rules={[
              {
                required: true,
                message: 'Введите Систему налогообложения!',
              },
            ]}
          >
            <Select defaultValue={DEFAULT_EMPTY_SYMBOL} options={snoTypeOptions} onChange={handleOnChange} />
          </Form.Item>
        </Flex>
      </div>
      <Flex justify={'space-between'} style={{ marginTop: '24px' }}>
        <Button type={'primary'} danger onClick={onDeleteContractHandler}>
          Удалить контрагента
        </Button>
        <Button type={'primary'} htmlType="submit" disabled={!isModified}>
          Сохранить изменения
        </Button>
      </Flex>
    </Form>
  )
}
