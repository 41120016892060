import { Button, Flex } from 'antd'

import { ScheduleModalConflictsProps } from './schedule-modal-conflicts.types'
import { ScheduleModalConflictsExercise } from './schedule-modal-conflicts-exercise/schedule-modal-conflicts-exercise.component'
import { ScheduleModal } from '../schedule-modal/schedule-modal.component'

export const ScheduleModalConflicts: React.FC<ScheduleModalConflictsProps> = props => {
  const {
    longterm,
    studioId,
    action,
    isLoading,
    conflicts,
    trainersOptions,
    studiosRoomsOptions,
    studioOffset,
    onSave,
    onSkip,
    onCancel,
  } = props

  return (
    <ScheduleModal
      title="Решение конфликтов"
      width={560}
      onCancel={onCancel}
      footer={
        <Flex justify="center">
          <Button loading={isLoading} type="primary" onClick={onCancel}>
            Завершить {action === 'CREATE' ? 'создание' : action === 'PAY_BOOKING' ? 'оплату' : 'редактирование'}{' '}
            {longterm ? 'длинной записи' : 'расписания'}
          </Button>
        </Flex>
      }
    >
      {conflicts?.map((conflict, index) => (
        <ScheduleModalConflictsExercise
          key={index}
          isLoading={isLoading}
          studioId={studioId}
          action={action}
          conflict={conflict}
          trainersOptions={trainersOptions}
          studiosRoomsOptions={studiosRoomsOptions}
          studioOffset={studioOffset}
          onSaveHandler={onSave}
          onSkipHandler={onSkip}
        />
      ))}
    </ScheduleModal>
  )
}
