import * as React from 'react'
import { ColumnsType } from 'antd/lib/table'

import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { TableCellList } from '../../table-cells/table-cell-list/table-cell-list.component'
import { TableCellDays } from '../../table-cells/table-cell-days/table-cell-days.component'
import { TableCellStudioRoom } from '../../table-cells/table-cell-studio-room/table-cell-studio-room.component'
import { ScheduleTablePeriod } from './schedule-table-period/schedule-table-period.component'
import { ScheduleTableActions } from './schedule-table-actions/schedule-table-actions.component'
import { ScheduleTableDataItem, ScheduleTableEvents } from './schedule-table.types'

export function genScheduleTableColumns(
  timetableDeletePermission: boolean,
  events: ScheduleTableEvents
): ColumnsType<ScheduleTableDataItem> {
  const { onEdit, onRemove, onHistory } = events

  return [
    {
      title: 'Категория записи',
      dataIndex: 'type',
      key: 'type',
      render: (_, { type }) => <TableCellText text={type?.title} />,
    },
    {
      title: 'Направление',
      dataIndex: 'direction',
      key: 'direction',
      render: (_, { direction }) => <TableCellText text={direction?.title} />,
    },
    {
      title: 'Исполнитель',
      dataIndex: 'trainers',
      key: 'trainers',
      render: (_, { trainers }) => <TableCellList items={trainers || ''} />,
    },
    {
      title: 'Пространство',
      dataIndex: 'studioRooms',
      key: 'studioRooms',
      width: 120,
      render: (_, { studioRooms }) => <TableCellStudioRoom rooms={studioRooms} />,
    },
    {
      title: 'Начало',
      key: 'period',
      width: 120,
      render: (_, { dateFrom }) => <ScheduleTablePeriod date={dateFrom} />,
    },
    {
      title: 'Конец',
      key: 'period',
      width: 120,
      render: (_, { dateTo }) => <ScheduleTablePeriod date={dateTo} />,
    },
    {
      title: 'Дни недели',
      dataIndex: 'days',
      key: 'days',
      render: (_, { days }) => <TableCellDays days={days} />,
    },
    {
      title: 'Действие',
      key: 'action',
      render: (_, { id }) => (
        <ScheduleTableActions
          id={id}
          timetableDeletePermission={timetableDeletePermission}
          onEdit={onEdit}
          onRemove={onRemove}
          onHistory={onHistory}
        />
      ),
    },
  ]
}
