import { FC, useMemo } from 'react'
import { Button, Flex, Table, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import {
  getEmployeesSchedule,
  getEmployeesScheduleIsLoading,
} from '@store/pages/employees/employees-schedule/employees-schedule.selectors'
import { useHistory } from 'react-router-dom'

import { StudioCell } from './ui/studio-cell/studio-cell.component'
import { PeriodCell } from './ui/period-cell/period-cell.component'
import { ActionsCell } from './ui/actions-cell/actions-cell.component'
import { EmployeeCell } from './ui/employee-cell/employee-cell.component'
import { StatusCell } from './ui/status-cell/status-cell.component'

import './employees-schedule.styles.less'

export const EmployeesSchedulePage: FC = () => {
  // Hooks
  const isLoading = useSelector(getEmployeesScheduleIsLoading)
  const employeesSchedule = useSelector(getEmployeesSchedule)

  const history = useHistory()

  const dataSource = useMemo(() => {
    if (!employeesSchedule?.length) return []
    return employeesSchedule.map(schedule => ({
      key: schedule.id,
      employee: <EmployeeCell employee={schedule.employee} employeePosition={schedule.employeePosition} />,
      studio: <StudioCell />,
      status: <StatusCell status={schedule.status} />,
      period: <PeriodCell dateStart={schedule.validFrom} dateEnd={schedule.validTo} />,
      actions: <ActionsCell schedule={schedule} />,
    }))
  }, [employeesSchedule])

  const columns = [
    {
      title: 'Должность или сотрудник',
      dataIndex: 'employee',
      key: 'employee',
    },
    {
      title: 'Локация',
      dataIndex: 'studio',
      key: 'studio',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Период',
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
    },
  ]

  return (
    <Flex vertical gap="large">
      <Flex justify="space-between" align="center">
        <Typography.Title className="employees-schedule-page__title">График работы</Typography.Title>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push('/employees/schedule/create')}>
          Добавить график
        </Button>
      </Flex>
      <Table dataSource={dataSource} columns={columns} loading={isLoading} />
    </Flex>
  )
}
