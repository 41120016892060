import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { genLongtermScheduleOverview, genScheduleExercises } from '../../../../mapping/exercises-timetable.mapping'
import { mapStudiosToOptions, mapStudiosToRoomsOptions } from '../../../../mapping/studios.mapping'
import { mapTrainersToOptions } from '../../../../mapping/trainers.mapping'

const genScheduleLongtermPagePeekAboutScheduleInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.timetable

const genScheduleLongtermPagePeekAboutExercisesInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.exercises

const genScheduleLongtermPagePeekAboutTrainersInternal = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.trainers

export const genScheduleLongtermPagePeekAboutStudios = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.studios

export const genScheduleLongtermPagePeekAboutIsExercisesLoading = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.isExercisesLoading

export const genScheduleLongtermPagePeekAboutIsTimetableLoading = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.isTimetableLoading

export const genScheduleLongtermPagePeekAboutIsCommentLoading = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.isCommentLoading

export const genScheduleLongtermPagePeekAboutExercisesFilter = (state: AppState) =>
  state.scheduleLongtermPage.scheduleLongtermPagePeekAbout.exercisesFilter

export const genScheduleLongtermPagePeekAboutSchedule = createSelector(
  genScheduleLongtermPagePeekAboutScheduleInternal,
  genLongtermScheduleOverview
)

export const genScheduleLongtermPagePeekAboutBookingClients = createSelector(
  genScheduleLongtermPagePeekAboutScheduleInternal,
  schedule => schedule?.bookingClients
)

export const genScheduleLongtermPagePeekAboutExercises = createSelector(
  genScheduleLongtermPagePeekAboutExercisesInternal,
  exercises => genScheduleExercises(exercises?.content)
)

export const genScheduleLongtermPagePeekAboutExercisesTotalElement = createSelector(
  genScheduleLongtermPagePeekAboutExercisesInternal,
  exercises => exercises?.totalElements
)

export const genScheduleLongtermPagePeekAboutTrainersOptions = createSelector(
  genScheduleLongtermPagePeekAboutTrainersInternal,
  trainers => mapTrainersToOptions(trainers?.content)
)

export const genScheduleLongtermPagePeekAboutFilterTrainersOptions = createSelector(
  genScheduleLongtermPagePeekAboutScheduleInternal,
  schedule =>
    schedule?.trainers?.map(trainer => ({
      label: trainer.name,
      value: trainer.id,
    }))
)

export const genScheduleLongtermPagePeekAboutStudiosOptions = createSelector(
  genScheduleLongtermPagePeekAboutStudios,
  studios => mapStudiosToOptions(studios?.content)
)

export const genScheduleLongtermPagePeekAboutStudiosRoomsOptions = createSelector(
  genScheduleLongtermPagePeekAboutStudios,
  studios => mapStudiosToRoomsOptions(studios?.content)
)

export const genScheduleLongtermPagePeekAboutFilterStudiosRoomsOptions = createSelector(
  genScheduleLongtermPagePeekAboutScheduleInternal,
  schedule =>
    schedule?.rooms?.map(room => ({
      label: room.name,
      value: room.id,
    }))
)
