import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleLongtermPageModalEditFetchPaymentTypesPayload,
  ScheduleLongtermPageModalEditPayExercisesPayload,
  ScheduleLongtermPageModalEditRemoveExercisesPayload,
  ScheduleLongtermPageModalEditUpdateExercisesCommentPayload,
  ScheduleLongtermPageModalEditUpdateExercisesPayload,
} from './schedule-longterm-page-modal-edit.types'

export interface ScheduleLongtermPageModalEditState {
  paymentTypes: Nullable<ExercisesTimetableApi.ExercisesTimetablePaymentType[]>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ScheduleLongtermPageModalEditState = {
  paymentTypes: null,
  isLoading: false,
  error: null,
}

export const { actions: scheduleLongtermPageModalEditActions, reducer: scheduleLongtermPageModalEditReducer } =
  createSlice({
    name: '@@schedule-longterm-page-modal-edit',
    initialState,
    reducers: {
      fetchPaymentTypes: (
        state: Draft<ScheduleLongtermPageModalEditState>,
        _action: PayloadAction<ScheduleLongtermPageModalEditFetchPaymentTypesPayload>
      ) => {
        state.isLoading = true
      },
      fetchPaymentTypesSuccess: (
        state: Draft<ScheduleLongtermPageModalEditState>,
        action: PayloadAction<ExercisesTimetableApi.ExercisesTimetablePaymentType[]>
      ) => {
        state.isLoading = false
        state.paymentTypes = action.payload
      },
      fetchPaymentTypesError: (state: Draft<ScheduleLongtermPageModalEditState>, action: PayloadAction<Error>) => {
        state.error = action.payload
        state.isLoading = false
      },
      updateExercises: (
        state: Draft<ScheduleLongtermPageModalEditState>,
        _action: PayloadAction<ScheduleLongtermPageModalEditUpdateExercisesPayload>
      ) => {
        state.isLoading = true
      },
      updateExercisesSuccess: (state: Draft<ScheduleLongtermPageModalEditState>): void => {
        state.isLoading = false
      },
      updateExercisesError: (state: Draft<ScheduleLongtermPageModalEditState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      updateExercisesComment: (
        state: Draft<ScheduleLongtermPageModalEditState>,
        _action: PayloadAction<ScheduleLongtermPageModalEditUpdateExercisesCommentPayload>
      ) => {
        state.isLoading = true
      },
      updateExercisesCommentSuccess: (state: Draft<ScheduleLongtermPageModalEditState>): void => {
        state.isLoading = false
      },
      updateExercisesCommentError: (
        state: Draft<ScheduleLongtermPageModalEditState>,
        action: PayloadAction<Error>
      ): void => {
        state.error = action.payload
        state.isLoading = false
      },
      payExercises: (
        state: Draft<ScheduleLongtermPageModalEditState>,
        _action: PayloadAction<ScheduleLongtermPageModalEditPayExercisesPayload>
      ) => {
        state.isLoading = true
      },
      payExercisesSuccess: (state: Draft<ScheduleLongtermPageModalEditState>): void => {
        state.isLoading = false
      },
      payExercisesError: (state: Draft<ScheduleLongtermPageModalEditState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      removeExercises: (
        state: Draft<ScheduleLongtermPageModalEditState>,
        _action: PayloadAction<ScheduleLongtermPageModalEditRemoveExercisesPayload>
      ) => {
        state.isLoading = true
      },
      removeExercisesSuccess: (state: Draft<ScheduleLongtermPageModalEditState>): void => {
        state.isLoading = false
      },
      removeExercisesError: (state: Draft<ScheduleLongtermPageModalEditState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })
