export enum PaymentType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ON_PLACE = 'ON_PLACE',
  ONE_TIME = 'ONE_TIME',
  CREATE_TRANSACTION = 'CREATE_TRANSACTION',
  WAITING = 'WAITING',
  RESERVED = 'RESERVED',
  TRIAL = 'TRIAL',
}

export enum PaymentMethod {
  CARD = 'CARD',
  CASH = 'CASH',
  SMS = 'SMS',
  QR = 'QR',
  NO_PAYMENT = 'NO_PAYMENT',
  CONTRACT = 'CONTRACT',
}

export enum ClientPaymentMethod {
  CARD = 'CARD',
  CASH = 'CASH',
  SMS = 'SMS',
  QR = 'QR',
  APP = 'APP',
  APP_WEBVIEW = 'APP_WEBVIEW',
  WEB = 'WEB',
  WIDGET = 'WIDGET',
  NO_PAYMENT = 'NO_PAYMENT',
  CONTRACT = 'CONTRACT',
}
